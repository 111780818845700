import { StackDivider, useBreakpointValue, VStack } from '@chakra-ui/react';
import {
  ChartsIcon,
  Logout20Icon,
  ReportsIcon,
  Settings20Icon,
} from '@payler/ui-icons';
import React, { Suspense } from 'react';
import { BottomMenuLink, MenuLink } from './MenuLink';
import { MenuContracts } from './MenuContracts';
import { Logo } from '../Logo/Logo';
import { useTranslation } from 'react-i18next';
import { ThemeSwitcher } from './ThemeSwitcher';

export function Menu() {
  return (
    <Suspense fallback={null}>
      <VStack
        minH={{ base: '100%', sm: '100vh' }}
        h={{ base: '100%', sm: '100vh' }}
        maxH={{ base: '100%', sm: '100vh' }}
        border={{ base: 'none', sm: '0.5px solid' }}
        borderColor={{ base: 'transparent', sm: 'primary.100' }}
        bg="secondary.500"
        as="nav"
        alignItems="stretch"
        spacing={0}
        divider={<StackDivider bg="primary.100" h="0.5px" border="none" />}
      >
        <Logo />
        <Links />
        <MenuContracts />
        <BottomLinks />
      </VStack>
    </Suspense>
  );
}

export default Menu;

const Links = () => {
  const { t } = useTranslation(['menu']);
  return (
    <VStack alignItems="stretch">
      <MenuLink icon={ChartsIcon} route="/" title={t('menu:analytics')} />
      <MenuLink icon={ReportsIcon} route="/reports" title={t('menu:reports')} />
    </VStack>
  );
};

const BottomLinks = () => {
  const { t } = useTranslation(['menu']);
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <VStack alignItems="stretch" py={1}>
      {isMobile && <ThemeSwitcher />}
      <BottomMenuLink
        icon={Settings20Icon}
        route="/settings"
        title={t('menu:settings')}
      />
      <BottomMenuLink
        icon={Logout20Icon}
        route="/logout"
        title={t('menu:logout')}
      />
    </VStack>
  );
};
