import { chakra, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import dayjs from 'dayjs';
import React from 'react';
import { MayBe } from '../../../../../api/src';

export const PaymentDatesCell = ({
  bottomDate,
  topDate,
  format = 'HH:mm:ss DD.MM.YYYY',
}: {
  bottomDate?: MayBe<string>;
  topDate: string;
  format?: string;
}) => {
  return (
    <VStack alignItems="flex-start" spacing={0}>
      <chakra.span textStyle={TextStyles.tables}>
        {dayjs(topDate).format(format)}
      </chakra.span>
      {bottomDate && (
        <chakra.span
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
        >
          {dayjs(bottomDate).format(format)}
        </chakra.span>
      )}
    </VStack>
  );
};
