import { TooltipProps } from 'recharts';
import { Card, CardContent } from '@payler/ui-components';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CurrencyDisplay } from '../../CurrencyDisplay/CurrencyDisplay';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';
import { TTransactionStatusEnKeys } from '../../../i18n/namespaces/enums/transactionStatus';

export const TooltipTransactions = ({
  active,
  payload,
}: TooltipProps<number, string>) => {
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const { t } = useTranslation(['transactionStatus', 'analytics']);
  if (active && payload && payload.length) {
    const first = payload[0];
    if (!first) return null;
    const {
      payload: { totalTransactions, sum, transactions },
    } = first;
    return (
      <Card bg="bgTooltip" spacing={2} backdropFilter="blur(6px)">
        <CardContent>
          <VStack spacing={1} alignItems="flex-start">
            <HStack spacing={2}>
              {payload.map((x, i) => (
                <VStack alignItems="flex-start" spacing={0} key={i}>
                  <Text
                    textStyle={TextStyles.Caption12Regular}
                    fontSize="10px"
                    color="primary.350"
                  >
                    {t(
                      `transactionStatus:${x.name as TTransactionStatusEnKeys}`,
                    )}
                  </Text>
                  <Text
                    textStyle={TextStyles.Caption12Medium}
                    color="primary.500"
                  >
                    {t('analytics:transactions', { count: transactions })}
                    &nbsp;&mdash;&nbsp;
                    {Number((transactions / totalTransactions) * 100).toFixed(
                      0,
                    )}
                    %<br />
                    <CurrencyDisplay value={sum} currency={CurrencyCode} />
                  </Text>
                </VStack>
              ))}
            </HStack>
          </VStack>
        </CardContent>
      </Card>
    );
  }

  return null;
};
