import { stringToBuffer } from './utils';

/**
 * Механизм шифрования данных методом AES-CBC
 * https://developer.mozilla.org/en-US/docs/Web/API/AesCbcParams
 * @param data - в бинарном формате
 * @param publicRSAKeyString - строка публичного ключа без заголовка и подвала
 *
 */
export const encryptDataAESWithCBC = async (
  data: ArrayBuffer,
  publicRSAKeyString: string,
) => {
  /** Преобразуем строку публичного ключа в CryptoKey */
  const publicRSAKey = await importRSAKey(publicRSAKeyString);
  /** Генерируем ключ для AES-CBC шифрования */
  const key = await generateAESWithCBCKey();
  /** Для дальнейшего шифрования ключа AES-CBC публичным ключом RSA */
  const exportedKey = await exportKeyInRawFormat(key);
  /** Шифрование ключа AES-CBC публичным ключом RSA */
  const encryptedKey = await encryptDataWithRSA(exportedKey, publicRSAKey);
  /**
   * Генерация вектора инициализации https://en.wikipedia.org/wiki/Block_cipher_mode_of_operation#Initialization_vector_(IV)
   * Согласно документации вектор должен иметь размер 16 байт
   * https://developer.mozilla.org/en-US/docs/Web/API/AesCbcParams
   *
   * Его нужно тоже отправлять, чтобы можно было расшифровать
   */
  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    key,
    data,
  );

  /** Шифруем вектор инициализации */
  const encryptedIV = await encryptDataWithRSA(iv, publicRSAKey);
  return { encryptedKey, encryptedData, encryptedIV };
};

/**
 * Генерируем ключ шифрования для AES-CBC
 * в режиме шифрования и дешифрования и с возможностью экспортировать
 */
export const generateAESWithCBCKey = async () => {
  return await window.crypto.subtle.generateKey(
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
};

/**
 * Экспорт ключа в формате Raw
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#raw
 */
export const exportKeyInRawFormat = async (key: CryptoKey) => {
  return await window.crypto.subtle.exportKey('raw', key);
};

/**
 * Импорт ключа в формате spki только в режиме шифрования и не экспортируемый
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#subjectpublickeyinfo
 */
export const importRSAKey = async (publicRSAKey: string) => {
  return await window.crypto.subtle.importKey(
    'spki',
    stringToBuffer(window.atob(publicRSAKey)),
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
      length: 512,
    },
    false,
    ['encrypt'],
  );
};

export const encryptDataWithRSA = async (
  data: ArrayBuffer,
  publicRSAKey: CryptoKey,
) => {
  return await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP', length: 512 },
    publicRSAKey,
    data,
  );
};
