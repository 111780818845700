import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

type ERROR_TYPES =
  | 'contractNotFound'
  | 'invalidCredentials'
  | 'bulkPayoutsNotEnabled'
  | 'unexpectedError'
  | 'wrongFileFormat'
  | 'wrongBulkPayoutStatus'
  | 'loadedDataExpired'
  | 'fileIsEmpty'
  | 'moreThenOneCurrency'
  | 'maxRecordQuantityIsExceeded'
  | 'encodeKeyNotSet'
  | 'notFound'
  | 'maxTotalAmountIsExceeded'
  | 'wrongCharactersInFileName'
  | 'noValidRecordsToProcess'
  | 'maxSimultaneouslyProcessingFilesNumberIsExceeded'
  | 'default';

type TBulkPayoutError = { type?: ERROR_TYPES; error?: unknown };

type TBulkPayoutErrorContext = {
  error: TBulkPayoutError | undefined;
  errorMessage: string | null;
  setBulkPayoutError: (error: unknown | Error) => void;
  clearBulkPayoutError: () => void;
};

const BulkPayoutErrorContext = createContext<TBulkPayoutErrorContext>(
  {} as TBulkPayoutErrorContext,
);

export const BulkPayoutErrorContextProvider: FCC = ({ children }) => {
  const { t } = useTranslation(['withdrawals']);
  const [error, setError] = useState<TBulkPayoutError | undefined>();
  const errorMessages = useMemo<Record<ERROR_TYPES, string>>(
    () => ({
      contractNotFound: t('withdrawals:bulkPayoutError.contractNotFound'),
      invalidCredentials: t('withdrawals:bulkPayoutError.invalidCredentials'),
      bulkPayoutsNotEnabled: t(
        'withdrawals:bulkPayoutError.bulkPayoutsNotEnabled',
      ),
      unexpectedError: t('withdrawals:bulkPayoutError.unexpectedError'),
      wrongFileFormat: t('withdrawals:bulkPayoutError.wrongFileFormat'),
      wrongBulkPayoutStatus: t(
        'withdrawals:bulkPayoutError.wrongBulkPayoutStatus',
      ),
      loadedDataExpired: t('withdrawals:bulkPayoutError.loadedDataExpired'),
      fileIsEmpty: t('withdrawals:bulkPayoutError.fileIsEmpty'),
      moreThenOneCurrency: t('withdrawals:bulkPayoutError.moreThenOneCurrency'),
      maxRecordQuantityIsExceeded: t(
        'withdrawals:bulkPayoutError.maxRecordQuantityIsExceeded',
      ),
      encodeKeyNotSet: t('withdrawals:bulkPayoutError.encodeKeyNotSet'),
      notFound: t('withdrawals:bulkPayoutError.notFound'),
      maxTotalAmountIsExceeded: t(
        'withdrawals:bulkPayoutError.maxTotalAmountIsExceeded',
      ),
      wrongCharactersInFileName: t(
        'withdrawals:bulkPayoutError.wrongCharactersInFileName',
      ),
      noValidRecordsToProcess: t(
        'withdrawals:bulkPayoutError.noValidRecordsToProcess',
      ),
      maxSimultaneouslyProcessingFilesNumberIsExceeded: t(
        'withdrawals:bulkPayoutError.maxSimultaneouslyProcessingFilesNumberIsExceeded',
      ),
      default: t('bulkPayoutError.defaultError'),
    }),
    [t],
  );

  const clearBulkPayoutError = useCallback(() => {
    setError(undefined);
  }, []);

  const setBulkPayoutError = useCallback((err: unknown) => {
    if (err && typeof err === 'object') {
      if (err.hasOwnProperty('type')) {
        setError(err);
      } else if (err instanceof Error && 'type' in err) {
        setError({ type: err?.type as ERROR_TYPES, error: err });
      } else if (err instanceof AxiosError) {
        const error = err?.response?.data;
        setBulkPayoutError(error);
      } else {
        setError({ type: 'default', error: err });
      }
    } else {
      setError({ type: 'default' });
    }
  }, []);

  const ctx = useMemo<TBulkPayoutErrorContext>(() => {
    const defaultMessage = t('withdrawals:bulkPayoutError.defaultError');
    return {
      error,
      errorMessage: error
        ? error?.type
          ? (errorMessages[error.type] ?? defaultMessage)
          : defaultMessage
        : null,
      clearBulkPayoutError,
      setBulkPayoutError,
    };
  }, [error, t]);

  return (
    <BulkPayoutErrorContext.Provider value={ctx}>
      {children}
    </BulkPayoutErrorContext.Provider>
  );
};

export const useBulkPayoutErrorContext = () =>
  useContext(BulkPayoutErrorContext);
