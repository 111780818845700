import { TBulkPayout } from '../../../../../api/src';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import React from 'react';
import {
  ComponentStyleConfig,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import { MayBe } from '@payler/utils';
import { Status } from '../../tables/PaymentsTable/Status';

type Props = { data: TBulkPayout };

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: { base: '300px', xl: '800px' },
      maxW: { base: '100%', sm: 'unset' },
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '240px',
        w: '30%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const BulkPaymentDetails = ({ data }: Props) => {
  const { t } = useTranslation(['withdrawals', 'common']);

  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('withdrawals:bulkPayout')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Tbody>
                <TableRow label={t('withdrawals:initialAmount')}>
                  <FormattedAmount
                    value={data.amountTotal}
                    currency={data.currency}
                  />
                </TableRow>
                <TableRow label={t('withdrawals:actualAmount')}>
                  <FormattedAmount
                    value={data.amountSucceeded}
                    currency={data.currency}
                  />
                </TableRow>
                <TableRow
                  label={
                    <TableCellWithDivider
                      leftLabel={t('withdrawals:success')}
                      rightLabel={t('common:error')}
                    />
                  }
                >
                  <TableCellWithDivider
                    leftLabel={data.rowsSucceeded ?? '-'}
                    rightLabel={data.rowsFailed ?? '-'}
                    leftLabelColor={'green.500'}
                    rightLabelColor={'red.500'}
                    dividerColor={'primary.300'}
                  />
                </TableRow>
                <TableRow label={t('status')}>
                  <Status status={data.status ?? '-'} />
                </TableRow>
                <TableRow
                  label={t('bulkPaymentId')}
                  children={data.id ?? '-'}
                />
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

const TableRow = ({
  label,
  children,
}: {
  label: string | React.ReactNode;
  children: React.ReactNode;
}) => (
  <Tr>
    <Td>{label}</Td>
    <Td>{children}</Td>
  </Tr>
);

const FormattedAmount = ({
  value,
  currency,
}: {
  value: MayBe<number>;
  currency: MayBe<string>;
}) => {
  const { getCurrencyUnitRate, getCurrencyDecimalsCount, getCurrencySymbol } =
    useCurrencyCallbacks();

  return (
    <NumberFormat
      displayType="text"
      suffix={`\u00a0${getCurrencySymbol(currency)}`}
      decimalScale={getCurrencyDecimalsCount(currency)}
      fixedDecimalScale
      thousandSeparator={' '}
      value={value ? value / getCurrencyUnitRate(currency) : '-'}
    />
  );
};

const TableCellWithDivider = ({
  leftLabel,
  rightLabel,
  divider = '/',
  leftLabelColor,
  rightLabelColor,
  dividerColor,
}: {
  leftLabel: React.ReactNode;
  rightLabel: React.ReactNode;
  leftLabelColor?: string;
  rightLabelColor?: string;
  divider?: string;
  dividerColor?: string;
}) => (
  <span>
    <Text as="span" color={leftLabelColor}>
      {leftLabel}
    </Text>
    <Text as="span" color={dividerColor}>
      &nbsp;{divider}&nbsp;
    </Text>
    <Text as="span" color={rightLabelColor}>
      {rightLabel}
    </Text>
  </span>
);
