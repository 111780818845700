import { TranslationKeys } from './types/translationStructure';

export const fasterPayoutsEn = {
  previousPayments: 'Previous payouts',
  title: 'Payouts FPS',
  status: {
    Created: 'Created',
    Ready: 'Ready',
    Success: 'Success',
    Rejected: 'Rejected',
    Pending: 'Pending',
  },
  dataTitle: 'Payment details',
  name: 'Name',
  phone: 'Phone',
  bankName: 'Bank',
  orderId: 'Payout ID',
  processingName: 'Processing',
  history: 'Operations history',
  requisites: 'Requisites',
  tooltip: 'Faster payout',
} as const;

export const fasterPayoutsRu: TranslationKeys<typeof fasterPayoutsEn> = {
  previousPayments: 'Предыдущие выплаты',
  title: 'Выплаты СБП',
  status: {
    Created: 'Создано',
    Ready: 'Готово к выплате',
    Success: 'Успешно',
    Rejected: 'Отклонено',
    Pending: 'В обработке',
  },
  name: 'ФИО',
  dataTitle: 'Данные платежа',
  bankName: 'Банк',
  phone: 'Телефон',
  history: 'История операций',
  orderId: 'ID выплаты',
  processingName: 'Процессинг',
  requisites: 'Реквизиты',
  tooltip: 'Выплата СБП',
} as const;
