import React from 'react';
import SignInForm from '../forms/SignInForm/SignInForm';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation(['users']);
  return (
    <>
      <Helmet title={t('users:cancel')} />
      <SignInForm />
    </>
  );
};

export default LoginPage;
