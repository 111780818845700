import { MayBe, TUserParams } from '../../../../../api/src';
import createLogger from 'debug';

const log = createLogger('PaymentTransaction');
export const getAdditionalParams = (
  value: MayBe<TUserParams> = '{}',
): string => {
  const convert = (obj: { [key: string]: unknown }) =>
    Object.entries(obj)
      .map(([_, v]) => `${v}`)
      .join(', ');
  try {
    if (typeof value === 'number') {
      return String(value);
    } else if (typeof value === 'object') {
      return convert(value ?? {});
    } else {
      const parsed = JSON.parse(value);
      if (typeof parsed === 'number') {
        return String(parsed);
      }
      return convert(parsed);
    }
  } catch (e) {
    log('error parsing json: %O', e);
    return String(value) ?? '';
  }
};
