import {
  TBulkPayoutStatus,
  TTransactionStatus,
} from '../../../../api/src';

/**
 * Конвертация бинарных данных в формат base64
 * @param data
 */
export const byteArrayToBase64 = (data: ArrayBuffer) => {
  let res = '';
  const view = new Uint8Array(data);

  for (let i = 0; i < view.length; i++) {
    const ch = view[i];

    if (typeof ch === 'number') {
      res += String.fromCharCode(ch);
    }
  }

  return window.btoa(res);
};

/**
 * Конвертация строки в ArrayBuffer
 * @param str
 */
export const stringToBuffer = (str: string) => {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i);
  }
  return view.buffer;
};

export const getBulkPayoutBudgeColor = (status: TBulkPayoutStatus) => {
  switch (status) {
    case 'Loaded':
    case 'Processed':
      return 'green';
    case 'Processing':
    case 'Rejected':
      return 'yellow';
    case 'Expired':
      return 'red';
    default:
      return undefined;
  }
};

export function getTransactionStatusBadgeVariant(status: TTransactionStatus) {
  switch (status) {
    case 'PreAuthorized3DS':
    case 'Created':
    case 'Authorized':
    case 'Registered':
    case 'PendingOnlyDebit':
    case 'PendingOnlyCredit':
    case 'Pending':
      return 'yellow';
    case 'Reversed':
    case 'Charged':
    case 'Refunded':
    case 'Credited':
      return 'green';
    case 'Rejected':
    case 'RejectedOnlyDebit':
    case 'RejectedOnlyCredit':
      return 'red';
    default:
      return undefined;
  }
}
