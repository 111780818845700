export const menuEn = {
  alerts: 'Alerts',
  analytics: 'Analytics',
  antifraud: 'Antifraud',
  invoice: 'Invoice',
  list: 'List',
  logout: 'Log out',
  makecredit: 'Withdraw',
  payments: 'Payments',
  reports: 'Reports',
  sbpLink: 'Payouts',
  sbpTitle: 'FPS',
  sbpPaymentsLink: 'Payments',
  apmTitle: 'APM',
  apmLink: 'Payouts',
  apmPaymentsLink: 'Payments',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  account: 'Account',
  payment: 'Payment',
  notifications: 'Notifications',
  users: 'Users',
  cryptoPaymentsTitle: 'Crypto payments',
  cryptoPaymentsLink: 'Crypto payments',
} as const;

type TMenuEnKeys = keyof typeof menuEn;

export const menuRu: Record<TMenuEnKeys, string> = {
  alerts: 'Оповещения',
  analytics: 'Аналитика',
  antifraud: 'Антифрод',
  invoice: 'Выставить счет',
  list: 'Список',
  logout: 'Выйти',
  makecredit: 'Сделать выплату',
  payments: 'Платежи',
  reports: 'Отчеты',
  sbpLink: 'Выплаты',
  sbpPaymentsLink: 'Платежи',
  sbpTitle: 'СБП',
  apmTitle: 'APM',
  apmLink: 'Выплаты',
  apmPaymentsLink: 'Платежи',
  settings: 'Настройки',
  withdrawals: 'Выплаты',
  account: 'Аккаунт',
  payment: 'Оплата',
  notifications: 'Уведомления',
  users: 'Пользователи',
  cryptoPaymentsTitle: 'Crypto платежи',
  cryptoPaymentsLink: 'Crypto платежи',
} as const;
