import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { TApmPaymentsFiltersForm } from './types';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createDateFieldResolver,
  FloatingInputField,
  ThinDivider,
} from '@payler/ui-components';
import { FiltersOptionsArrayAccordion } from '../../components/FiltersOptionsArrayAccordion/FiltersOptionsArrayAccordion';
import { FiltersDateRangeAccordion } from '../../components/FiltersDateRangeAccordion/FiltersDateRangeAccordion';
import { FiltersAmountRangeAccordion } from '../../components/FiltersAmountRangeAccordion/FiltersAmountRangeAccordion';

import createLogger from 'debug';
import isEqual from 'lodash/isEqual';
import {
  APM_PAYMENTS_INDICATOR,
  APM_PAYMENTS_STATUS_OPTIONS,
} from '../../const';
import { isNil, omitBy } from 'lodash';
import { useResetFormAndUrlFilters } from '../../hooks/use-reset-form-and-url-filters';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';
import {
  useApmPaymentsFiltersFromUrl,
  useSetApmPaymentsUrlSearchParams,
} from '../../hooks/apm-payments/url';
import { useCloseApmPaymentsFiltersModal } from '../../modals/ApmPaymentsFiltersModal';
import { useURLApmContractCurrencies } from '../../hooks/use-url-contract-currencies';
import { useAvailableApmPaymentsMethods } from '../../hooks/apm-payments/payment-methods';

const log = createLogger('ApmPaymentsFilters');

const defaultValues: TApmPaymentsFiltersForm = {
  StartDate: null,
  EndDate: null,
  SessionStatus: null,
  PaymentType: null,
  PaymentMethod: null,
  Currency: null,
  StartAmount: null,
  EndAmount: null,
};

const MAX_LIMIT_AMOUNT = 1_000_000_000;

const useCreateResolver = () => {
  const { t } = useTranslation(['common']);
  const dateFormat = useUIDateFormat();
  return useCallback(() => {
    return yupResolver(
      yup.object({
        StartDate: createDateFieldResolver(t, {
          toFieldName: 'StartDate',
          maxDate: dayjs(),
          dateFormat,
        }),
        EndDate: createDateFieldResolver(t, {
          fromFieldName: 'EndDate',
          maxDate: dayjs(),
          dateFormat,
        }),
        StartAmount: yup
          .number()
          .typeError(t('common:validate.amount'))
          .nullable()
          .min(0, t('common:validate.amount'))
          .max(999999999, t('common:validate.amount')),
        EndAmount: yup
          .number()
          .typeError(t('common:validate.amount'))
          .nullable()
          .min(0, t('common:validate.amount'))
          .max(999999999, t('common:validate.amount')),
      }),
    );
  }, [dateFormat, t]);
};

//region form
export const ApmPaymentsFiltersForm = () => {
  const filters = useApmPaymentsFiltersFromUrl();
  const closeModal = useCloseApmPaymentsFiltersModal();
  const createResolver = useCreateResolver();
  const methods = useForm<TApmPaymentsFiltersForm>({
    defaultValues,
    mode: 'onChange',
    resolver: createResolver(),
  });
  const { t } = useTranslation(['fields']);
  const setFilters = useSetApmPaymentsUrlSearchParams();

  const handleSubmit = methods.handleSubmit((values) => {
    log('handleSubmit', values);
    setFilters(values);
    closeModal();
  });

  useEffect(() => {
    methods.reset(filters);
    log('resetting form, filters: %O', filters);
  }, [methods, filters]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={0} divider={<ThinDivider />}>
          <Header />
          <VStack spacing={1} p={2}>
            <FloatingInputField
              name="SessionId"
              label={t('fields:sessionId')}
            />
            <FloatingInputField name="OrderId" label={t('fields:orderIdApm')} />
          </VStack>
          <Dates />
          <Status />
          <PaymentType />
          <PaymentMethods />
          <Currency />
          <Amount />
          <Buttons />
        </VStack>
      </form>
    </FormProvider>
  );
};
//endregion

//region fields
const Amount = () => (
  <FiltersAmountRangeAccordion
    formFieldFrom="StartAmount"
    formFieldTo="EndAmount"
    fromKey="fields:from"
    toKey="fields:to"
    titleKey="fields:amount"
    isAllowed={({ floatValue }) =>
      floatValue === undefined || floatValue < MAX_LIMIT_AMOUNT
    }
  />
);

const Dates = () => {
  const dateFormat = useUIDateFormat();
  return (
    <FiltersDateRangeAccordion
      formFieldFrom="StartDate"
      formFieldTo="EndDate"
      fromKey="fields:from"
      toKey="fields:to"
      titleKey="fields:period"
      dateFormat={dateFormat}
    />
  );
};

const Status = () => (
  <FiltersOptionsArrayAccordion
    formFieldName="SessionStatus"
    fieldNameKey="fields:paymentStatus"
    options={APM_PAYMENTS_STATUS_OPTIONS}
    optionsPrefix=""
  />
);

const Currency = () => {
  const { watch, setValue } = useFormContext<TApmPaymentsFiltersForm>();
  const [amountFrom, amountTo, currency] = watch([
    'StartAmount',
    'EndAmount',
    'Currency',
  ]);
  const [hideClearIcon, setHideClearIcon] = useState<boolean>(true);
  const currencies = useURLApmContractCurrencies();
  const isAmount = (amountFrom ?? 0) > 0 || (amountTo ?? 0) > 0;
  useEffect(() => {
    if (isAmount && !currency && currencies[0]) {
      setValue('Currency', currencies[0]);
    }
  }, [isAmount, currency, currencies]);
  useEffect(() => {
    setHideClearIcon(!(!!currency && !isAmount));
  }, [isAmount, setHideClearIcon, currency]);

  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:currency"
      formFieldName="Currency"
      options={currencies}
      optionsPrefix="currency:"
      hideClearIcon={hideClearIcon}
    />
  );
};

const PaymentMethods = () => {
  const availableApmFilters = useAvailableApmPaymentsMethods();

  if (!availableApmFilters?.length) {
    return null;
  }

  return (
    <FiltersOptionsArrayAccordion
      fieldNameKey="fields:paymentMethod"
      formFieldName="PaymentMethod"
      options={availableApmFilters}
      optionsPrefix="paymentMethods:"
    />
  );
};

const PaymentType = () => (
  <FiltersOptionsArrayAccordion
    fieldNameKey="fields:paymentIndicator"
    formFieldName="PaymentType"
    options={APM_PAYMENTS_INDICATOR}
    optionsPrefix="paymentIndication:"
  />
);
//endregion

//region count selected
const useFiltersCount = () => {
  const { watch } = useFormContext<TApmPaymentsFiltersForm>();
  const data = watch();
  return useMemo(() => {
    let count = 0;
    count += data.StartAmount || data.EndAmount ? 1 : 0;
    count += data.StartDate || data.EndDate ? 1 : 0;
    count += data.SessionStatus ? 1 : 0;
    count += data.SessionId ? 1 : 0;
    count += data.OrderId ? 1 : 0;
    count += data.PaymentMethod ? 1 : 0;
    count += data.PaymentType ? 1 : 0;
    count += data.Currency ? 1 : 0;
    return count;
  }, [data]);
};
//endregion

//region header
const Header = () => {
  const { t } = useTranslation(['filters']);
  const reset = useResetFormAndUrlFilters();
  const count = useFiltersCount();

  return (
    <HStack justifyContent="space-between">
      <Box pl={3} pr={2} py={2} alignSelf="flex-start">
        <Text as="h4" textStyle={TextStyles.h4} color="primary.500">
          {t('filters:title')}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
          {count} {t('filters:selected')}
        </Text>
      </Box>
      <Box pr={3}>
        <Button variant="secondary" onClick={reset}>
          {t('filters:reset')}
        </Button>
      </Box>
    </HStack>
  );
};
//endregion

//region buttons
const isEmpty = (val: unknown) =>
  isNil(val) || (Array.isArray(val) && val.length === 0) || !val;

const Buttons = () => {
  const filters = useApmPaymentsFiltersFromUrl();
  const { t } = useTranslation(['filters']);
  const { watch } = useFormContext<TApmPaymentsFiltersForm>();

  const applyDisabled = isEqual(
    omitBy(filters, isEmpty),
    omitBy(watch(), isEmpty),
  );
  return (
    <VStack p={2} alignItems="center" spacing="10px">
      <Button type="submit" w="100%" disabled={applyDisabled}>
        {t('filters:submit')}
      </Button>
    </VStack>
  );
};
//endregion
