import { MayBe, TCurrency } from '../../../../../api/src';
import React from 'react';
import { chakra, Icon, VStack } from '@chakra-ui/react';
import { Reply12Icon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { CurrencyDisplay } from '../../components/CurrencyDisplay/CurrencyDisplay';

export const AmountWithCurrencyCell = ({
  amount,
  currencyCode,
  amountOfRefunds,
}: {
  amount: number;
  currencyCode?: TCurrency;
  amountOfRefunds?: MayBe<number>;
}) => {
  return (
    <VStack spacing={0} alignItems="flex-end">
      <CurrencyDisplay value={amount} currency={currencyCode} />

      {amountOfRefunds && (
        <chakra.span
          color="primary.350"
          textStyle={TextStyles.Caption12Regular}
        >
          <CurrencyDisplay value={amountOfRefunds} currency={currencyCode} />
          <Icon as={Reply12Icon} ml={0.5} />
        </chakra.span>
      )}
    </VStack>
  );
};
