import React, { FC } from 'react';
import { TBulkPayoutStatus } from '../../../../../api/src';
import { useTranslation } from 'react-i18next';
import { Badge } from '@chakra-ui/react';
import { getBulkPayoutBudgeColor } from '../../helpers/utils';

export const Status: FC<{ status: TBulkPayoutStatus }> = ({ status }) => {
  const { t } = useTranslation('common');
  const statusContent: Record<TBulkPayoutStatus, string> = {
    Expired: t('transactionStatus.rejected'),
    Loaded: t('transactionStatus.loaded'),
    Processed: t('transactionStatus.processed'),
    Processing: t('transactionStatus.processing'),
    Rejected: t('transactionStatus.rejected'),
  };

  return (
    <Badge variant={getBulkPayoutBudgeColor(status)}>
      {statusContent[status]}
    </Badge>
  );
};
