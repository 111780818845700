import React, { Suspense } from 'react';
import { PaymentSettingsList } from '../../components/PaymentSettingsList/PaymentSettingsList';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../layouts';
import { Helmet } from 'react-helmet-async';

export const PaymentSettingsPage: React.FC = () => (
  <Box w="100%" alignItems="center" display="flex" flexDirection="column">
    <Suspense fallback={null}>
      <PaymentPageHeader />
    </Suspense>
    <PaymentSettingsList />
  </Box>
);

const PaymentPageHeader = () => {
  const desktop = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation(['settings', 'titles', 'common']);
  if (!desktop) return null;
  return (
    <HStack
      alignItems="baseline"
      justifyContent="space-between"
      mb={5}
      w="100%"
      maxW="1200px"
    >
      <div>
        <Helmet
          title={`${t('titles:settings')} :: ${t('settings:payment.title')}`}
        />
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/settings">
              {t('settings:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/settings/payment">
              {t('settings:payment.title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('settings:payment.title')}</PageTitle>
      </div>
    </HStack>
  );
};
