import React, { Suspense, useCallback, useState } from 'react';
import { FloatingInputField } from '@payler/ui-components';
import { Button, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { useUpdateUserPasswordMutation } from '../../hooks/user/users';
import { useToasts } from '../../hooks/use-toasts';
import { PASSWORD_REGEXP } from '../../const';

function createResolver(t: TFunction) {
  return yupResolver(
    yup.object().shape({
      currentPassword: yup.string().required(t('validate.required')),
      password: yup
        .string()
        .required(t('validate.required'))
        .min(8, t('validate.minPasswordLength'))
        .matches(PASSWORD_REGEXP, t('validate.notMatchPasswordPattern')),
      confirmPassword: yup
        .string()
        .required(t('validate.required'))
        .test((value, ctx) => {
          if (!value)
            return ctx.createError({ message: t('validate.required') });
          if (value !== ctx.parent.password)
            return ctx.createError({
              message: t('validate.doNotMatchPassword'),
            });
          if (!PASSWORD_REGEXP.test(value)) {
            return ctx.createError({
              message: t('validate.notMatchPasswordPattern'),
            });
          }
          return true;
        }),
    }),
  );
}

type FormData = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

const PasswordSettingsFormComp: React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  const { t: tWithoutNs } = useTranslation();
  const methods = useForm<FormData>({ resolver: createResolver(tWithoutNs) });
  const { mutateAsync, isPending: isLoading } = useUpdateUserPasswordMutation();
  const [apiError, setApiError] = useState('');
  const getError = useGetAxiosError();
  const toasts = useToasts();
  const resetPasswordHandle = useCallback(() => {
    methods.reset({
      currentPassword: '',
      password: '',
      confirmPassword: '',
    });
  }, [methods]);
  const onSubmit = methods.handleSubmit(
    async ({ currentPassword, password }: FormData) => {
      setApiError('');
      await mutateAsync(
        { currentPassword, newPassword: password },
        { onError: (e) => setApiError(getError(e)) },
      );
      resetPasswordHandle();
      toasts.success(t('settings:account.toast.password.success'));
    },
  );
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <FloatingInputField
            type="password"
            name="currentPassword"
            label={t('settings:account.form.currentPassword')}
          />
          <FloatingInputField
            type="password"
            name="password"
            label={t('settings:account.form.password')}
            helpText={t('settings:account.passwordHelp')}
          />
          <FloatingInputField
            type="password"
            name="confirmPassword"
            label={t('settings:account.form.confirmPassword')}
          />
          {apiError && <ApiErrorText>{apiError}</ApiErrorText>}
          <HStack spacing={2}>
            <Button
              variant="primary"
              type="submit"
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              {t('common:save')}
            </Button>
            <Button
              variant="secondary"
              onClick={resetPasswordHandle}
              isDisabled={isLoading}
            >
              {t('common:cancel')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

/**
 * Смена пароля
 */
export const PasswordSettingsForm = () => {
  return (
    <Suspense fallback={null}>
      <PasswordSettingsFormComp />
    </Suspense>
  );
};
