export const currencyEn = {
  AED: 'United Arab Emirates dirham',
  AFN: 'Afghani',
  ALL: 'Albanian lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani manat',
  BAM: 'Convertible stamp',
  BBD: 'Barbados Dollar',
  BDT: 'Bangladeshi taka',
  BGN: 'Bulgarian Lion',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermuda Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Boliviano',
  BRL: 'Brazilian real',
  BSD: 'Bahamian Dollar',
  BTN: 'Bhutanese ngultrum',
  BWP: 'Botswana Pool',
  BYN: 'Belarusian Ruble',
  BZD: 'Belizean Dollar',
  CAD: 'Canadian dollar',
  CDF: 'Congolese Franc',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Yuan',
  COP: 'Colombian Peso',
  COU: 'Unit of real value',
  CRC: 'Costa Rican Colon',
  CUC: 'Convertible Peso',
  CUP: 'Cuban Peso',
  CVE: 'Escudo Cape Verde',
  CZK: 'Czech Crown',
  DJF: 'Djibouti Franc',
  DKK: 'Danish Krona',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Eritrean nakfa',
  ETB: 'Ethiopian birr',
  EUR: 'Euro',
  FJD: 'Fiji Dollar',
  FKP: 'Falkland Islands Pound',
  GBP: 'Pound',
  GEL: 'Georgian Lari',
  GHS: 'Ghanaian sedi',
  GIP: 'Gibraltar Pound',
  GMD: 'Dalasi',
  GNF: 'Guinea Franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanese Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran lempira',
  HRK: 'Croatian kuna',
  HTG: 'Haitian Gourd',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'The New Israeli Shekel',
  INR: 'Indian rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Icelandic Krona',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Som',
  KHR: 'Riel',
  KMF: 'Frank Comore',
  KPW: 'North Korean Won',
  KRW: 'Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Dollar of the Cayman Islands',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Lao Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LRD: 'The Liberian Dollar',
  LSL: 'Loti Lesotho',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirhams',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariari',
  MKD: 'Macedonian Denar',
  MMK: 'Kyat',
  MNT: 'Tugrik',
  MOP: 'Pataka Macau',
  MRU: 'Ugia',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufia',
  MWK: 'Malawian kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Mozambique Metical',
  NAD: 'The Namibian Dollar',
  NGN: 'Naira',
  NIO: 'Golden Cordoba',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian New Salt',
  PGK: 'Kina',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Zloty',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Ruble',
  RUR: 'Ruble',
  RWF: 'The Rwandan Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychelles Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SHP: 'St. Helena Pound.',
  SLL: 'Leone',
  SOS: 'Somali Shilling',
  SRD: 'Surinamese Dollar',
  SSP: 'South Sudanese Pound',
  STN: 'Dobra Sao Tome and Principe',
  SVC: 'Salvadoran Colon',
  SYP: 'Syrian Pound',
  SZL: 'Lilangeni',
  THB: 'Bat',
  TJS: 'Somoni',
  TMT: 'New Turkmen Manat',
  TND: 'Tunisian Dinar',
  TOP: 'Paanga',
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Hryvnia',
  UGX: 'Ugandan Shilling',
  USD: 'Dollar',
  UYI: 'Uruguayan Peso in indexed units',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbek Sum',
  VEF: 'Bolivar',
  VES: 'Bolivar Soberano',
  VND: 'Vietnamese dong',
  VUV: 'Cotton wool',
  WST: 'Tala',
  XAF: 'Frank CFA VEAS',
  XCD: 'Eastern Caribbean Dollar',
  XOF: 'Franc CFA ALLAO',
  XPF: 'CFP Franc',
  YER: 'Yemeni Rial',
  ZAR: 'Rand',
  ZMW: 'Zambian kwacha',
  ZWL: 'Zimbabwe Dollar',
} as const;

export type TCurrencyEnKeys = keyof typeof currencyEn;

export const currencyRu: Record<TCurrencyEnKeys, string> = {
  AED: 'Дирхам ОАЭ',
  AFN: 'Афгани',
  ALL: 'Албанский лек',
  AMD: 'Армянский драм',
  ANG: 'Нидерландский антильский гульден',
  AOA: 'Ангольская кванза',
  ARS: 'Аргентинское песо',
  AUD: 'Австралийский доллар',
  AWG: 'Арубанский флорин',
  AZN: 'Азербайджанский манат',
  BAM: 'Конвертируемая марка',
  BBD: 'Барбадосский доллар',
  BDT: 'Бангладешская така',
  BGN: 'Болгарский лев',
  BHD: 'Бахрейнский динар',
  BIF: 'Бурундийский франк',
  BMD: 'Бермудский доллар',
  BND: 'Брунейский доллар',
  BOB: 'Боливиано',
  BRL: 'Бразильский реал',
  BSD: 'Багамский доллар',
  BTN: 'Бутанский нгултрум',
  BWP: 'Ботсванская пула',
  BYN: 'Белорусский рубль',
  BZD: 'Белизский доллар',
  CAD: 'Канадский доллар',
  CDF: 'Конголезский франк',
  CHF: 'Швейцарский франк',
  CLP: 'Чилийское песо',
  CNY: 'Юань',
  COP: 'Колумбийское песо',
  COU: 'Единица реальной стоимости',
  CRC: 'Костариканский колон',
  CUC: 'Конвертируемое песо',
  CUP: 'Кубинское песо',
  CVE: 'Эскудо Кабо-Верде',
  CZK: 'Чешская крона',
  DJF: 'Франк Джибути',
  DKK: 'Датская крона',
  DOP: 'Доминиканское песо',
  DZD: 'Алжирский динар',
  EGP: 'Египетский фунт',
  ERN: 'Эритрейская накфа',
  ETB: 'Эфиопский быр',
  EUR: 'Евро',
  FJD: 'Доллар Фиджи',
  FKP: 'Фунт Фолклендских островов',
  GBP: 'Фунт',
  GEL: 'Грузинский лари',
  GHS: 'Ганский седи',
  GIP: 'Гибралтарский фунт',
  GMD: 'Даласи',
  GNF: 'Гвинейский франк',
  GTQ: 'Гватемальский кетсаль',
  GYD: 'Гайанский доллар',
  HKD: 'Гонконгский доллар',
  HNL: 'Гондурасская лемпира',
  HRK: 'Хорватская куна',
  HTG: 'Гаитянский гурд',
  HUF: 'Венгерский форинт',
  IDR: 'Индонезийская рупия',
  ILS: 'Новый израильский шекель',
  INR: 'Индийская рупия',
  IQD: 'Иракский динар',
  IRR: 'Иранский риал',
  ISK: 'Исландская крона',
  JMD: 'Ямайский доллар',
  JOD: 'Иорданский динар',
  JPY: 'Иена',
  KES: 'Кенийский шиллинг',
  KGS: 'Сом',
  KHR: 'Риель',
  KMF: 'Франк Комор',
  KPW: 'Северокорейская вона',
  KRW: 'Вона',
  KWD: 'Кувейтский динар',
  KYD: 'Доллар Островов Кайман',
  KZT: 'Казахстанский тенге',
  LAK: 'Лаосский кип',
  LBP: 'Ливанский фунт',
  LKR: 'Шри-Ланкийская рупия',
  LRD: 'Либерийский доллар',
  LSL: 'Лоти Лесото',
  LYD: 'Ливийский динар',
  MAD: 'Марокканский дирхам',
  MDL: 'Молдавский лей',
  MGA: 'Малагасийский ариари',
  MKD: 'Македонский денар',
  MMK: 'Кьят',
  MNT: 'Тугрик',
  MOP: 'Патака Макао',
  MRU: 'Угия',
  MUR: 'Маврикийская рупия',
  MVR: 'Мальдивская руфия',
  MWK: 'Малавийская квача',
  MXN: 'Мексиканское песо',
  MYR: 'Малайзийский ринггит',
  MZN: 'Мозамбикский метикал',
  NAD: 'Доллар Намибии',
  NGN: 'Найра',
  NIO: 'Золотая кордоба',
  NOK: 'Норвежская крона',
  NPR: 'Непальская рупия',
  NZD: 'Новозеландский доллар',
  OMR: 'Оманский риал',
  PAB: 'Панамский бальбоа',
  PEN: 'Перуанский новый соль',
  PGK: 'Кина',
  PHP: 'Филиппинское песо',
  PKR: 'Пакистанская рупия',
  PLN: 'Злотый',
  PYG: 'Парагвайский гуарани',
  QAR: 'Катарский риал',
  RON: 'Румынский лей',
  RSD: 'Сербский динар',
  RUB: 'Рубль',
  RUR: 'Рубль',
  RWF: 'Франк Руанды',
  SAR: 'Саудовский риял',
  SBD: 'Доллар Соломоновых Островов',
  SCR: 'Сейшельская рупия',
  SDG: 'Суданский фунт',
  SEK: 'Шведская крона',
  SGD: 'Сингапурский доллар',
  SHP: 'Фунт Святой Елены.',
  SLL: 'Леоне',
  SOS: 'Сомалийский шиллинг',
  SRD: 'Суринамский доллар',
  SSP: 'Южносуданский фунт',
  STN: 'Добра Сан-Томе и Принсипи',
  SVC: 'Сальвадорский колон',
  SYP: 'Сирийский фунт',
  SZL: 'Лилангени',
  THB: 'Бат',
  TJS: 'Сомони',
  TMT: 'Новый туркменский манат',
  TND: 'Тунисский динар',
  TOP: 'Паанга',
  TRY: 'Турецкая лира',
  TTD: 'Доллар Тринидада и Тобаго',
  TWD: 'Новый тайваньский доллар',
  TZS: 'Танзанийский шиллинг',
  UAH: 'Гривна',
  UGX: 'Угандийский шиллинг',
  USD: 'Доллар',
  UYI: 'Уругвайское песо в индексированных единицах',
  UYU: 'Уругвайское песо',
  UZS: 'Узбекский сум',
  VEF: 'Боливар',
  VES: 'Боливар Соберано',
  VND: 'Вьетнамский донг',
  VUV: 'Вату',
  WST: 'Тала',
  XAF: 'Франк КФА ВЕАС',
  XCD: 'Восточно-карибский доллар',
  XOF: 'Франк КФА ВСЕАО',
  XPF: 'Франк КФП',
  YER: 'Йеменский риал',
  ZAR: 'Рэнд',
  ZMW: 'Замбийская квача',
  ZWL: 'Доллар Зимбабве',
} as const;
