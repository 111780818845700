import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { TPaymentFiltersForm } from '../../forms/PaymentsFilters/types';
import { TPaymentsURLParams } from './types';
import { isNil, omitBy } from 'lodash';
import {
  TCurrency,
  TPaymentIndicators,
  TPaymentMethods,
  TTransactionStatus,
} from '../../../../../api/src';
import {
  getArrayOrUndefined,
  getFloatOrUndefined,
} from '../../helpers/url-params';
import {
  ALL_PAYMENT_INDICATORS,
  PAYMENT_FILTERS_DEBIT_STATUSES,
  PAYMENT_FILTERS_PAYMENT_METHODS,
} from '../../const';
import { useURLContractCurrencies } from '../use-url-contract-currencies';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';

const isEmpty = (val: string | string[] | null | undefined) =>
  isNil(val) || (Array.isArray(val) && val.length === 0) || val === '';

const formValuesToParams = (
  values: TPaymentFiltersForm,
): TPaymentsURLParams => {
  const tmp: TPaymentsURLParams = {
    PaymentMethods: values.PaymentMethods ?? undefined,
    PaymentIndicators: values.PaymentIndicators ?? undefined,
    CardHolder: values.CardHolder ?? undefined,
    CurrencyCodes: values.CurrencyCodes ?? undefined,
    StartDate: values.StartDate ? values.StartDate : undefined,
    EndDate: values.EndDate ? values.EndDate : undefined,
    StartAmount: values.StartAmount?.toString() ?? undefined,
    EndAmount: values.EndAmount?.toString() ?? undefined,
    Countries: values.Countries ?? undefined,
    TransactionStatuses: values.TransactionStatuses ?? undefined,
    IsAntifraudDeclined: undefined,
    SearchQuery: values.SearchQuery ?? undefined,
  };
  return omitBy(tmp, isEmpty);
};

export const useSetPaymentFiltersUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TPaymentFiltersForm) => {
      const params = { ...formValuesToParams(values) };
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams],
  );
};

const queryToFilters = (params: URLSearchParams, currencies: TCurrency[]) => {
  return {
    PaymentMethods: getArrayOrUndefined<TPaymentMethods>(
      params.getAll('PaymentMethods'),
      PAYMENT_FILTERS_PAYMENT_METHODS,
    ),
    OperationStatuses: undefined,
    PaymentIndicators: getArrayOrUndefined<TPaymentIndicators>(
      params.getAll('PaymentIndicators'),
      ALL_PAYMENT_INDICATORS,
    ),
    CardHolder: params.get('CardHolder') ?? '',
    CurrencyCodes: getArrayOrUndefined<TCurrency>(
      params.getAll('CurrencyCodes'),
      currencies,
    ),
    StartDate: params.get('StartDate') ?? '',
    EndDate: params.get('EndDate') ?? '',
    StartAmount: getFloatOrUndefined(params.get('StartAmount')),
    EndAmount: getFloatOrUndefined(params.get('EndAmount')),
    Countries: undefined,
    TransactionStatuses: getArrayOrUndefined<TTransactionStatus>(
      params.getAll('TransactionStatuses'),
      PAYMENT_FILTERS_DEBIT_STATUSES,
    ),
    SearchQuery: params.get('SearchQuery'),
  } as TPaymentFiltersForm;
};

export const usePaymentsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  const currencies = useURLContractCurrencies();
  return useMemo(
    () => queryToFilters(params, currencies),
    [currencies, params],
  );
};
