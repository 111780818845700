import { useMemo } from 'react';
import { usePaymentAnalyticsQuery } from '../../../hooks/analytics';
import { TStep, useChartStepContext } from '../../../context/ChartStepContext';
import { TAnalyticsDataResponse } from '../../../../../../api/src';
import dayjs from 'dayjs';
import { useChartQueryParamsByStep } from '../useChartQueryParamsByStep';

/**
 * Верхний график (суммы по статусу)
 */
export const useChartBySumData = () => {
  const { step } = useChartStepContext();
  const params = useChartQueryParamsByStep();

  const { data: response, isLoading } = usePaymentAnalyticsQuery(params);

  return useMemo(() => {
    const visibility = responseToVisibility(response);
    const data = responseToData(response, step);
    const stats = responseToStats(response);
    return {
      visibility,
      data,
      currency: params.CurrencyCode,
      isLoading,
      stats,
    };
  }, [isLoading, params.CurrencyCode, response, step]);
};

function responseToVisibility(response: TAnalyticsDataResponse | undefined) {
  const data = response?.dailyPaymentsAnalytics;
  return {
    authorized: !!data?.find((x) => x.authorized.totalSum > 0),
    charged: !!data?.find((x) => x.charged.totalSum > 0),
    refunded: !!data?.find((x) => x.refunded.totalSum > 0),
    rejectedOnlyDebit: !!data?.find((x) => x.rejectedOnlyDebit.totalSum > 0),
    rejectedOnlyCredit: !!data?.find((x) => x.rejectedOnlyCredit.totalSum > 0),
    credited: !!data?.find((x) => x.credited.totalSum > 0),
  };
}

type T = Record<
  string,
  {
    charged: number;
    rejectedOnlyCredit: number;
    rejectedOnlyDebit: number;
    refunded: number;
    credited: number;
    authorized: number;
  }
>;

function responseToData(
  response: TAnalyticsDataResponse | undefined,
  step: TStep,
) {
  switch (step) {
    case 'weeks': {
      const reduced = response?.dailyPaymentsAnalytics?.reduce<T>((acc, x) => {
        const week = dayjs(x.date).endOf('week').format('YYYY-MM-DD');
        acc[week] = {
          charged: (acc[week]?.charged ?? 0) + x.charged.totalSum,
          rejectedOnlyCredit:
            (acc[week]?.rejectedOnlyCredit ?? 0) +
            x.rejectedOnlyCredit.totalSum,
          rejectedOnlyDebit:
            (acc[week]?.rejectedOnlyDebit ?? 0) + x.rejectedOnlyDebit.totalSum,
          refunded: (acc[week]?.refunded ?? 0) + x.refunded.totalSum,
          credited: (acc[week]?.credited ?? 0) + x.credited.totalSum,
          authorized: (acc[week]?.authorized ?? 0) + x.authorized.totalSum,
        };
        return acc;
      }, {});
      return reduced
        ? Object.entries(reduced).map(([week, v]) => {
            return {
              date: week,
              ...v,
            };
          })
        : undefined;
    }
    case 'months': {
      const reduced = response?.dailyPaymentsAnalytics?.reduce<T>((acc, x) => {
        const month = dayjs(x.date).endOf('month').format('YYYY-MM-DD');
        acc[month] = {
          charged: (acc[month]?.charged ?? 0) + x.charged.totalSum,
          rejectedOnlyCredit:
            (acc[month]?.rejectedOnlyCredit ?? 0) +
            x.rejectedOnlyCredit.totalSum,
          rejectedOnlyDebit:
            (acc[month]?.rejectedOnlyDebit ?? 0) + x.rejectedOnlyDebit.totalSum,
          refunded: (acc[month]?.refunded ?? 0) + x.refunded.totalSum,
          credited: (acc[month]?.credited ?? 0) + x.credited.totalSum,
          authorized: (acc[month]?.authorized ?? 0) + x.authorized.totalSum,
        };
        return acc;
      }, {});
      return reduced
        ? Object.entries(reduced).map(([month, v]) => {
            return {
              date: month,
              ...v,
            };
          })
        : undefined;
    }
    default: {
      return response?.dailyPaymentsAnalytics?.map((x) => ({
        charged: x.charged.totalSum,
        rejectedOnlyCredit: x.rejectedOnlyCredit.totalSum,
        rejectedOnlyDebit: x.rejectedOnlyDebit.totalSum,
        refunded: x.refunded.totalSum,
        credited: x.credited.totalSum,
        authorized: x.authorized.totalSum,
        date: x.date,
      }));
    }
  }
}

function responseToStats(response: TAnalyticsDataResponse | undefined) {
  const tmp = response?.dailyPaymentsAnalytics?.reduce(
    (acc, current) => {
      acc.charged += current.charged.totalSum;
      acc.credited += current.credited.totalSum;
      acc.authorized += current.authorized.totalSum;
      acc.refunded += current.refunded.totalSum;
      acc.rejectedOnlyDebit += current.rejectedOnlyDebit.totalSum;
      acc.rejectedOnlyCredit += current.rejectedOnlyCredit.totalSum;
      acc.total = 0;
      return acc;
    },
    {
      authorized: 0,
      charged: 0,
      refunded: 0,
      rejectedOnlyDebit: 0,
      rejectedOnlyCredit: 0,
      credited: 0,
      total: 0,
    },
  );

  if (tmp) {
    tmp.total =
      tmp.charged +
      tmp.credited +
      tmp.authorized +
      tmp.rejectedOnlyDebit +
      tmp.refunded;
  }

  return tmp;
}
