import {
  TCurrency,
  TPaymentsFilters,
  TPaymentTemplate,
} from '../../../../../api/src';
import { TPaymentsURLParams } from './types';
import dayjs from 'dayjs';
import { isNil, omitBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useURLContractCurrencies } from '../use-url-contract-currencies';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';

const templateToPaymentFilters = (
  template: TPaymentTemplate,
  currencies: TCurrency[],
  dateFormat: string,
): TPaymentsURLParams => {
  const tmp = {
    PaymentIndicators: template.paymentIndicators ?? undefined,
    PaymentMethods: template.paymentMethods ?? undefined,
    CurrencyCodes: currencies.filter((x) => template.currencyCodes.includes(x)),
    StartAmount: template.startAmount
      ? Number(template.startAmount).toString()
      : undefined,
    EndAmount: template.endAmount
      ? Number(template.endAmount).toString()
      : undefined,
    TransactionStatuses: template.transactionStatuses ?? undefined,
    StartDate: template.startDate
      ? dayjs(template.startDate).format(dateFormat)
      : undefined,
    EndDate: template.endDate
      ? dayjs(template.endDate).format(dateFormat)
      : undefined,
  };
  return omitBy(tmp, isNil);
};

export const useApplyAntifraudTemplate = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();
  const currencies = useURLContractCurrencies();
  const dateFormat = useUIDateFormat();
  return useCallback(
    (template: TPaymentTemplate) => {
      const params = templateToPaymentFilters(template, currencies, dateFormat);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, currencies, dateFormat, setParams],
  );
};

export const useDeleteAntifraudTemplate = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const contractId = useURLContractId();
  return useMutation({
    mutationFn: (id: number) => {
      return api.deleteTemplate(id);
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData(['antifraud', 'templates', contractId], (prev) =>
        (prev as TPaymentTemplate[]).filter((x) => x.id !== id),
      );
    },
  });
};

export const useSaveAntifraudTemplate = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const contractId = useURLContractId();
  const dateFormat = useUIDateFormat();
  if (!contractId) throw new Error('no contractId');
  return useMutation({
    mutationFn: (filters: TPaymentsFilters) => {
      return api.savePaymentsTemplate(filters, contractId, dateFormat);
    },

    onSuccess: (data) => {
      queryClient.setQueryData<TPaymentTemplate[]>(
        ['antifraud', 'templates', contractId],
        (prev) => (prev ? [...prev, data] : [data]),
      );
    },
  });
};
