import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import {
  TPaymentFiltersForm,
  TPayoutsFiltersForm,
} from '../../forms/PaymentsFilters/types';
import { isNil, omitBy } from 'lodash';
import { TCurrency, TTransactionStatus } from '../../../../../api/src';
import {
  getArrayOrUndefined,
  getFloatOrUndefined,
} from '../../helpers/url-params';
import { PAYMENT_FILTERS_CREDIT_STATUSES } from '../../const';
import { useURLContractCurrencies } from '../use-url-contract-currencies';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { TPayoutsURLParams } from './types';

const formValuesToParams = (values: TPayoutsFiltersForm): TPayoutsURLParams => {
  const tmp: TPayoutsURLParams = {
    CurrencyCodes: values.CurrencyCodes ?? undefined,
    StartDate: values.StartDate ? values.StartDate : undefined,
    EndDate: values.EndDate ? values.EndDate : undefined,
    StartAmount: values.StartAmount?.toString() ?? undefined,
    EndAmount: values.EndAmount?.toString() ?? undefined,
    TransactionStatuses: values.TransactionStatuses ?? undefined,
    SearchQuery: values.SearchQuery ?? undefined,
    IncludeBulkPayouts: values.IncludeBulkPayouts?.toString() || undefined,
  };
  return omitBy(tmp, isNil);
};

const queryToFilters = (params: URLSearchParams, currencies: TCurrency[]) => {
  return {
    CurrencyCodes: getArrayOrUndefined<TCurrency>(
      params.getAll('CurrencyCodes'),
      currencies,
    ),
    StartDate: params.get('StartDate') ?? '',
    EndDate: params.get('EndDate') ?? '',
    StartAmount: getFloatOrUndefined(params.get('StartAmount')),
    EndAmount: getFloatOrUndefined(params.get('EndAmount')),
    TransactionStatuses: getArrayOrUndefined<TTransactionStatus>(
      params.getAll('TransactionStatuses'),
      PAYMENT_FILTERS_CREDIT_STATUSES,
    ),
    SearchQuery: params.get('SearchQuery'),
    IncludeBulkPayouts: params.get('IncludeBulkPayouts'),
  } as TPayoutsFiltersForm;
};

export const useSetPayoutsUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TPaymentFiltersForm) => {
      const params = formValuesToParams(values);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams],
  );
};

export const usePayoutsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  const currencies = useURLContractCurrencies();
  return useMemo(
    () => queryToFilters(params, currencies),
    [currencies, params],
  );
};
