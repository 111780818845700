import { useToast } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useSuccessToast = () => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 5000,
    status: 'success',
  });
  return useCallback(
    (description?: React.ReactNode, title?: React.ReactNode) => {
      toast({ title, description });
    },
    [toast],
  );
};

const useErrorToast = () => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 5000,
    status: 'error',
  });

  const { t } = useTranslation(['common']);

  return useCallback(
    (
      description: React.ReactNode = t('common:unexpectedError'),
      title?: React.ReactNode,
    ) => {
      toast({ title, description });
    },
    [toast],
  );
};

export const useToasts = () => {
  const success = useSuccessToast();
  const error = useErrorToast();
  return useMemo(() => ({ success, error }), [success, error]);
};
