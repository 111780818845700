import { TPaymentsAnalyticsQueryParams } from '../../../../api/src';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../context/ApiContextProvider';

dayjs.extend(weekOfYear);

const ANALYTICS_STALE_TIME = 3 * 36e5;
export const usePaymentAnalyticsQuery = (
  params: TPaymentsAnalyticsQueryParams,
) => {
  const api = useApi();
  return useQuery({
    queryKey: ['analytics', params],
    queryFn: () => api.getPaymentsAnalytics(params),

    staleTime: ANALYTICS_STALE_TIME,
  });
};
