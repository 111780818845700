import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';

export const useNotificationResolver = () => {
  const { t } = useTranslation(['notifications']);
  return useMemo(() => {
    return yupResolver(
      yup.object({
        emails: yup.array().when('notificationType', {
          is: (value: string) => value === 'Email',
          then: yup
            .array()
            .of(
              yup
                .string()
                .email(t('notifications:invalidEmail'))
                .required(t('notifications:invalidEmail')),
            ),
        }),
        url: yup.string().when('notificationType', {
          is: (value: string) => value === 'Post',
          then: yup
            .string()
            .url(t('notifications:invalidUrl'))
            .required(t('notifications:invalidUrl')),
        }),
      }),
    );
  }, [t]);
};
