import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, VStack } from '@chakra-ui/react';
import { DropzoneField, FloatingInputField } from '@payler/ui-components';
import React, { useEffect, useState } from 'react';
import {
  useBulkPayoutEncryptionKeyQuery,
  useBulkPayoutDetailsQuery,
  useMakeBulkPayoutMutation,
} from '../../hooks/payouts/queries';
import { encryptDataAESWithCBC } from '../../helpers/crypto';
import createLogger from 'debug';
import { byteArrayToBase64 } from '../../helpers/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  BulkPayoutConfirmationModal,
  useCloseBulkPayoutConfirmationModal,
  useShowBulkPayoutConfirmationModal,
} from '../../modals/BulkPayoutConfirmationModal';
import { useBulkPayoutErrorContext } from '../../context/BulkPayoutErrorContext';
import { useSelectedContract } from '../../hooks/contracts';

const log = createLogger('BulkPayoutForm');

type TBulkPayoutFormFields = {
  files: [File];
  password: string;
};

const useResolver = () => {
  const { t } = useTranslation(['withdrawals']);
  return yupResolver(
    yup.object().shape({
      files: yup.array().required().length(1),
      password: yup.string().required(t('withdrawals:form.invalidPassword')),
    }),
  );
};

export const BulkPayoutForm = () => {
  const { t } = useTranslation(['withdrawals']);
  const { isBulkPayoutAllowed } = useSelectedContract() || {};
  const [isStartEncrypting, setIsStartEncrypting] = useState(false);
  const [bulkPayoutId, setBulkPayoutId] = useState<string | undefined>();
  const { clearBulkPayoutError, setBulkPayoutError } =
    useBulkPayoutErrorContext();
  const showBulkPayoutConfirmationModal = useShowBulkPayoutConfirmationModal();
  const closeBulkPayoutConfirmationModal =
    useCloseBulkPayoutConfirmationModal();
  const resolver = useResolver();
  const methods = useForm<TBulkPayoutFormFields>({
    resolver,
    mode: 'onChange',
  });
  const {
    data: encryptKey,
    isSuccess: isLoadedEncryptKey,
    isLoading: isLoadingEncryptKey,
    isError: isRequestEncryptionKeyError,
    error: requestEncryptionKeyError,
  } = useBulkPayoutEncryptionKeyQuery();
  const { data: bulkPayoutFileInfoData, clearBulkPayoutFileInfo } =
    useBulkPayoutDetailsQuery(bulkPayoutId);

  const { mutate, status } = useMakeBulkPayoutMutation();
  const isButtonLoading = status === 'pending' || isStartEncrypting;
  const isButtonDisabled =
    isLoadingEncryptKey || !isLoadedEncryptKey || !isBulkPayoutAllowed;

  useEffect(() => {
    if (isRequestEncryptionKeyError) {
      setBulkPayoutError(requestEncryptionKeyError);
    }
  }, [isRequestEncryptionKeyError, requestEncryptionKeyError]);

  useEffect(() => {
    if (bulkPayoutFileInfoData && bulkPayoutId) {
      const password = methods.getValues('password');
      const fileName = methods.getValues('files')[0].name;

      showBulkPayoutConfirmationModal({
        ...bulkPayoutFileInfoData,
        bulkPayoutId,
        password,
        fileName,
      });
    }
  }, [bulkPayoutFileInfoData, bulkPayoutId]);

  const handleCloseConfirmationModal = () => {
    setBulkPayoutId(undefined);
    clearBulkPayoutFileInfo().then(closeBulkPayoutConfirmationModal);
  };

  const handleConfirmBulkPayout = () => {
    methods.reset();
  };

  const handleSubmit = methods.handleSubmit(async (data) => {
    clearBulkPayoutError();
    const reader = new FileReader();
    reader.readAsArrayBuffer(data.files[0]);
    reader.onload = async (e) => {
      const file = e.target?.result;
      if (file instanceof ArrayBuffer && encryptKey) {
        try {
          setIsStartEncrypting(true);
          const { encryptedData, encryptedIV, encryptedKey } =
            await encryptDataAESWithCBC(file, encryptKey);

          const encryptedDataByteArray = new Uint8Array(
            encryptedData,
            0,
            encryptedData.byteLength,
          );
          const blob = new Blob([encryptedDataByteArray.buffer]);
          const encryptedFile = new File([blob], data.files[0].name);
          setIsStartEncrypting(false);
          mutate(
            {
              aesIv: byteArrayToBase64(encryptedIV),
              fileAesKey: byteArrayToBase64(encryptedKey),
              file: encryptedFile,
            },
            {
              onSuccess: ({ bulkPayoutId }) => {
                setBulkPayoutId(bulkPayoutId);
                log('Bulk payout ID: %s', bulkPayoutId);
              },
              onError: (err) => {
                setBulkPayoutError(err);
                log('Bulk payout mutation error: %s', err?.toString());
              },
            },
          );
        } catch (e) {
          setBulkPayoutError(e);
          setIsStartEncrypting(false);
          log('Encryption error: %s', e?.toString());
          throw e;
        }
      }
    };
  });

  return (
    <FormProvider {...methods}>
      <VStack as="form" w="full" onSubmit={handleSubmit}>
        <Box w="full">
          <DropzoneField
            accept={{ 'text/*': ['.csv'] }}
            title={t('withdrawals:form.selectCsvFile')}
            description={t('withdrawals:form.dragAndDropFileOrClickOnField')}
            help=""
            name="files"
            maxFiles={1}
          />
        </Box>
        <FloatingInputField
          name="password"
          label={t('withdrawals:form.password')}
          type="password"
          autoComplete="new-password"
        />
        <Button
          type="submit"
          w="full"
          isDisabled={isButtonDisabled}
          isLoading={isButtonLoading}
        >
          {t('withdrawals:form.send')}
        </Button>
        <BulkPayoutConfirmationModal
          onClose={handleCloseConfirmationModal}
          onConfirm={handleConfirmBulkPayout}
        />
      </VStack>
    </FormProvider>
  );
};
