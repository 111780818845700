import { TFasterPayoutWithHistory } from '../../../../../api/src';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import React from 'react';
import {
  Badge,
  ComponentStyleConfig,
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { PaymentSBP24Icon } from '@payler/ui-icons';
import { getFasterPayoutBadgeVariant } from '../../tables/FasterPayoutsTable/FasterPayoutsTable';
import { BankNameWithLogo } from '../BankNameWithLogo/BankNameWithLogo';
import { LabeledValue } from '../LabeledValue/LabeledValue';

type Props = { data: TFasterPayoutWithHistory };

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: { base: '300px', xl: '800px' },
      maxW: { base: '100%', sm: 'unset' },
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const FasterPayoutData = ({ data }: Props) => {
  const { t } = useTranslation(['fasterPayouts', 'fields']);
  const isMobile = useBreakpointValue({ base: true, xl: false });
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('fasterPayouts:dataTitle')}</CardTitle>
        <Tooltip label={t('fasterPayouts:tooltip')} shouldWrapChildren>
          <Icon as={PaymentSBP24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Tbody>
                <Tr>
                  <Td>{t('fields:status')}</Td>
                  <Td>
                    <Badge variant={getFasterPayoutBadgeVariant(data.status)}>
                      {data.statusDescription}
                    </Badge>
                  </Td>
                </Tr>
                {!isMobile && <DesktopRequisites data={data} />}
                {isMobile && <MobileRequisites data={data} />}
                <Tr>
                  <Td>{t('fasterPayouts:processingName')}</Td>
                  <Td>{data.processingName}</Td>
                </Tr>
                <Tr>
                  <Td>{t('fasterPayouts:orderId')}</Td>
                  <Td>{data.merchantOrderId}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

const DesktopRequisites = ({ data }: Props) => {
  const { t } = useTranslation(['fasterPayouts']);
  return (
    <Tr>
      <Td>{t('fasterPayouts:requisites')}</Td>
      <Td>
        <HStack spacing={5}>
          <div>
            <LabeledValue label={t('fasterPayouts:bankName')}>
              <BankNameWithLogo {...data} />
            </LabeledValue>
          </div>
          <div>
            <LabeledValue label={t('fasterPayouts:phone')}>
              <HStack>
                <Text textStyle={TextStyles.tables}>
                  {data.phone?.replace(/\*/g, '·')}{' '}
                </Text>
              </HStack>
            </LabeledValue>
          </div>
          <div>
            <LabeledValue label={t('fasterPayouts:name')}>
              {data.pam}
            </LabeledValue>
          </div>
        </HStack>
      </Td>
    </Tr>
  );
};

const MobileRequisites = ({ data }: Props) => {
  const { t } = useTranslation(['fasterPayouts']);
  return (
    <>
      <Tr>
        <Td>{t('fasterPayouts:bankName')}</Td>
        <Td>
          <BankNameWithLogo {...data} />
        </Td>
      </Tr>
      <Tr>
        <Td>{t('fasterPayouts:phone')}</Td>
        <Td>
          <HStack>
            <Text textStyle={TextStyles.tables}>
              {data.phone?.replace(/\*/g, '·')}{' '}
            </Text>
          </HStack>
        </Td>
      </Tr>
      <Tr>
        <Td>{t('fasterPayouts:name')}</Td>
        <Td>{data.pam}</Td>
      </Tr>
    </>
  );
};

export default FasterPayoutData;
