type TNumericRangeKey =
  | 'common:sumRange.fromTo'
  | 'common:sumRange.to'
  | 'common:sumRange.from';

export const getNumericRangeKey = (
  from: unknown,
  to: unknown,
): TNumericRangeKey => {
  let key: TNumericRangeKey = 'common:sumRange.fromTo';
  if (!to) {
    key = 'common:sumRange.from';
  }
  if (!from) {
    key = 'common:sumRange.to';
  }
  return key;
};

type TRangeKey =
  | 'common:range.fromTo'
  | 'common:range.to'
  | 'common:range.from'
  | 'common:range.empty';

export const getRangeKey = (from: unknown, to: unknown): TRangeKey => {
  let key: TRangeKey = 'common:range.fromTo';
  if (!from) {
    key = 'common:range.to';
  }
  if (!to) {
    key = 'common:range.from';
  }
  if (!from && !to) {
    key = 'common:range.empty';
  }
  return key;
};
