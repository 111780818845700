import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { DropdownField } from '@payler/ui-components';

export const NotificationType = () => {
  const { t } = useTranslation(['notifications', 'fields']);

  const typeOptions = useMemo(() => {
    return [
      {
        label: t('notifications:actions.Post'),
        value: 'Post',
      },
      {
        label: t('notifications:actions.Email'),
        value: 'Email',
      },
    ];
  }, [t]);
  return (
    <DropdownField
      options={typeOptions}
      fieldName="notificationType"
      floating
      hideClearButton
      label={t('fields:notificationMethod')}
    />
  );
};
