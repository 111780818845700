import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';
import InvoicesTable from '../tables/InvoicesTable/InvoicesTable';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../layouts';
import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '@payler/ui-theme';
import CreateInvoiceForm from '../forms/CreateInvoiceForm/CreateInvoiceForm';
import { RecreateInvoiceModal } from '../modals/RecreateInvoiceModal';
import { Helmet } from 'react-helmet-async';
import { useSelectedContract } from '../hooks/contracts';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'form' 'table';
  grid-gap: 16px;
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-areas: 'form table';
    align-items: flex-start;
  }
`;

export const InvoicesPage = () => {
  const { t } = useTranslation(['titles']);
  return (
    <>
      <Helmet title={t('titles:invoices')} />
      <Suspense fallback={null}>
        <Header />
      </Suspense>

      <Wrap>
        <TableCard />
        <FormCard />
      </Wrap>
      <Suspense fallback={null}>
        <RecreateInvoiceModal />
      </Suspense>
    </>
  );
};

const TableCard = () => {
  const { t } = useTranslation(['invoices']);
  return (
    <FullWidthBlock gridArea="table">
      <Card>
        <CardHead>
          <CardTitle>{t('invoices:listTitle')}</CardTitle>
        </CardHead>
        <CardContent p={0}>
          <InvoicesTable />
        </CardContent>
      </Card>
    </FullWidthBlock>
  );
};

const FormCard = () => {
  const { t } = useTranslation(['invoices']);
  return (
    <FullWidthBlock gridArea="form">
      <Card>
        <CardHead>
          <CardTitle>{t('invoices:formTitle')}</CardTitle>
        </CardHead>
        <CardContent>
          <CreateInvoiceForm />
        </CardContent>
      </Card>
    </FullWidthBlock>
  );
};

const Header = () => {
  const { t } = useTranslation(['invoices', 'common']);
  const contract = useSelectedContract();
  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>{t('invoices:title')}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('invoices:title')}</PageTitle>
      </div>
    </HStack>
  );
};

export default InvoicesPage;
