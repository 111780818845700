import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { MayBe } from '../../../../api/src';
import { useContracts } from './contracts';
import { uniq } from 'lodash';
import { useAllCurrencies } from './currencies-info';
import { TCurrencyEnKeys } from '../i18n/namespaces/currency';

type TUseCurrencyOptionsParams = {
  contractIds?: MayBe<number[]>;
  isApm?: boolean;
};

/**
 * Опции для выпадающего списка валют.
 * Возвращает или все валюты, или валюты для переданных ID контрактов
 * @param params
 */
export const useCurrencyOptions = (params?: TUseCurrencyOptionsParams) => {
  const allContracts = useContracts();
  const allCurrencies = useAllCurrencies();
  const { contractIds, isApm } = params ?? {
    contractIds: undefined,
    isApm: false,
  };

  const { t } = useTranslation(['fields', 'currency']);
  return useMemo(() => {
    const fieldName = t('fields:currency');

    let currenciesToDisplay = allCurrencies;
    const targetKey = isApm ? 'apmPaymentsCurrencyCodes' : 'currencyCodes';
    if (Array.isArray(contractIds) && contractIds.length > 0) {
      const contracts =
        allContracts?.filter((c) => contractIds.includes(c.id)) ?? [];
      currenciesToDisplay = uniq(contracts.flatMap((x) => x[targetKey]));
    }
    const options = currenciesToDisplay.map((x) => ({
      value: x,
      label: t(`currency:${x as TCurrencyEnKeys}`),
    }));
    return { options, fieldName };
  }, [t, allCurrencies, contractIds, allContracts, isApm]);
};
