import { TApplicationUser, TUserClaimType } from '../../../../../api/src';
import { FC, memo, Suspense, useCallback, useState } from 'react';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ThinDivider,
} from '@payler/ui-components';
import { Grid, HStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useContracts } from '../../hooks/contracts';
import { CrossIcon, Pencil24Icon } from '@payler/ui-icons';
import { LabeledValue } from '../LabeledValue/LabeledValue';
import { useShowDeleteUserModal } from '../../modals/DeleteUserModal';
import EditUserForm from '../../forms/users/EditUserForm';

type Props = {
  user: TApplicationUser;
};

type CompProps = Props & { toggleView: VoidFunction };

const gridTemplateColumns = {
  base: '1fr',
  sm: 'repeat(2, 1fr)',
  xl: 'repeat(3, 1fr)',
};

const CompView: FC<CompProps> = ({ user, toggleView }) => {
  const { t } = useTranslation(['users', 'roles', 'fields']);
  const allContracts = useContracts();

  const roles = user.roles.map((x) => t(`roles:${x}`)).join(', ');
  const contracts = allContracts
    ?.filter((x) => user.contractIds.includes(x.id))
    .map((x) => x.name)
    .join(', ');

  const getClaimValueText = useCallback(
    (type: TUserClaimType) => {
      const v = user.claims.find((x) => x.type === type)?.value;
      if (!v) return '';
      const k: 'roles:claimValues.ALL' | 'roles:claimValues.READ' =
        `roles:claimValues.${v}`;
      return t(k);
    },
    [t, user.claims],
  );
  return (
    <Card>
      <CardHead>
        <CardTitle>{user.name}</CardTitle>
        <Buttons id={user.id} toggleView={toggleView} mode="view" />
      </CardHead>
      <CardContent px={3} py={2}>
        <Grid
          gap={{ base: 2, md: 4 }}
          gridTemplateColumns={gridTemplateColumns}
        >
          <LabeledValue gridColumn="1" label={t('email')}>
            {user.email}
          </LabeledValue>
          <LabeledValue
            gridColumn={{ base: 1, sm: 2, md: '2 /span 2' }}
            label={t('fields:role')}
          >
            {roles}
          </LabeledValue>
          <LabeledValue
            gridColumn={{ base: 1, sm: '1/span 2', md: '1 /span 3' }}
            label={t('contracts')}
          >
            {contracts}
          </LabeledValue>
        </Grid>
        <ThinDivider
          my={{ base: 1, sm: 2 }}
          mx={-3}
          opacity={{ base: 0, sm: 1 }}
        />
        <CardTitle mb={{ base: 2, sm: 3 }}>{t('options')}</CardTitle>
        <Grid
          gap={{ base: 2, md: 4 }}
          gridTemplateColumns={gridTemplateColumns}
        >
          <LabeledValue label={t(`roles:claims.NEW_CREDIT`)}>
            {getClaimValueText('NEW_CREDIT')}
          </LabeledValue>
          <LabeledValue label={t(`roles:claims.NEW_PAYMENT`)}>
            {getClaimValueText('NEW_PAYMENT')}
          </LabeledValue>
          <LabeledValue label={t(`roles:claims.REPORTS`)}>
            {getClaimValueText('REPORTS')}
          </LabeledValue>
          <LabeledValue label={t(`roles:claims.PAYMENTS`)}>
            {getClaimValueText('PAYMENTS')}
          </LabeledValue>
          <LabeledValue label={t(`roles:claims.SETTING_NOTIFICATION`)}>
            {getClaimValueText('SETTING_NOTIFICATION')}
          </LabeledValue>
          <LabeledValue label={t(`roles:claims.SETTING_PAYMENT`)}>
            {getClaimValueText('SETTING_PAYMENT')}
          </LabeledValue>
        </Grid>
      </CardContent>
    </Card>
  );
};

const CompEdit = ({ toggleView, user }: CompProps) => {
  return (
    <Card>
      <CardHead>
        <CardTitle>{user.name}</CardTitle>
        <Buttons id={user.id} toggleView={toggleView} mode="edit" />
      </CardHead>
      <CardContent px={3} py={2}>
        <EditUserForm user={user} onCancel={toggleView} onSubmit={toggleView} />
      </CardContent>
    </Card>
  );
};

type TMode = 'view' | 'edit';

export const UserCard = (props: Props) => {
  const [mode, setMode] = useState<TMode>('view');
  const toggle = useCallback(
    () => setMode((v) => (v === 'view' ? 'edit' : 'view')),
    [],
  );
  const Comp = mode === 'view' ? CompView : CompEdit;
  return (
    <Suspense fallback={null}>
      <Comp {...props} toggleView={toggle} />
    </Suspense>
  );
};

export default UserCard;

const Buttons = memo(
  ({
    id,
    toggleView,
    mode,
  }: {
    id: string;
    toggleView: VoidFunction;
    mode: TMode;
  }) => {
    const showDeleteModal = useShowDeleteUserModal();
    const cursor = mode === 'view' ? 'pointer' : 'not-allowed';
    const colorEdit = mode === 'view' ? 'primary.300' : 'brands.500';
    const colorDeleteHover = mode === 'view' ? 'red.500' : 'primary.300';

    return (
      <HStack spacing={2}>
        <Icon
          role="button"
          as={Pencil24Icon}
          w={3}
          h={3}
          color={colorEdit}
          cursor={cursor}
          _hover={{ color: 'brands.500' }}
          onClick={mode === 'view' ? toggleView : undefined}
        />
        <Icon
          role="button"
          as={CrossIcon}
          w={3}
          h={3}
          cursor={cursor}
          color="primary.300"
          _hover={{ color: colorDeleteHover }}
          onClick={mode === 'view' ? () => showDeleteModal(id) : undefined}
        />
      </HStack>
    );
  },
);
