import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, chakra, Tooltip, VStack } from '@chakra-ui/react';
import { Lifetime } from './Lifetime';
import {
  CheckboxField,
  DropdownField,
  FloatingInputField,
} from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import {
  usePaymentPageTypesOptions,
  useSelectedContract,
} from '../../hooks/contracts';
import { TExtendedContract } from '../../../../../api/src';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import createLogger from 'debug';
import { Templates } from './Templates';
import { InvoiceFormData } from './InvoiceFormData';
import { useUserPermissions } from '../../hooks/user/users';
import { useShowPermissionsWarning } from '../../hooks/use-show-permissions-warning';
import { SelectCurrency } from '../../components/SelectContractCurrency/SelectCurrency';
import {
  useCreateInvoiceMutation,
  useDefaultInvoiceCurrency,
  useDefaultInvoicePaymentPageType,
  useInvoicesQuery,
  useRefreshInvoices,
} from '../../hooks/invoices/queries';
import { useSaveInvoiceTemplateMutation } from '../../hooks/invoices/templates';
import { useTranslation } from 'react-i18next';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import { useToasts } from '../../hooks/use-toasts';
import { useFormatDaysHoursMinutes } from '../../hooks/use-format-days-hours-minutes';
import { FieldSkeleton } from '../../components/FieldSkeleton/FieldSkeleton';
import { DEFAULT_PAYMENT_PAGE } from '../../const';

const log = createLogger('CreateInvoiceForm');

export function minutesToDaysAndHours(minutes: number) {
  let rest = minutes;
  const days = Math.floor(rest / 24 / 60);
  rest -= days * 24 * 60;
  const hours = Math.floor(rest / 60);
  rest -= hours * 60;
  return { days, hours, minutes: rest };
}

const createResolver = (
  t: TFunction,
  formatUnits: ReturnType<typeof useFormatDaysHoursMinutes>,
  contract?: TExtendedContract,
  productRegexp?: string,
) => {
  const maxLifetime = contract?.expirationTimeout ?? 10 * 24 * 60;
  const units = minutesToDaysAndHours(maxLifetime);
  const maxLifetimeFormatted = formatUnits(units);

  const schema = yup.object({
    lifetime: yup
      .number()
      .min(3, t('form.minLifetime', { ns: 'invoices' }))
      .max(
        maxLifetime,
        t('form.maxLifetime', {
          ns: 'invoices',
          maxLifetime: maxLifetimeFormatted,
        }),
      ),
    email: yup
      .string()
      .email(t('form.invalidEmail', { ns: 'invoices' }))
      .required(t('form.invalidEmail', { ns: 'invoices' })),
    amount: yup
      .number()
      .typeError(t('form.invalidAmount', { ns: 'invoices' }))
      .min(0.01, t('form.invalidAmount', { ns: 'invoices' })),
    product: yup.string().test((value, ctx) => {
      if (!productRegexp || !value) return true;
      const re = new RegExp(productRegexp);
      if (value && !re.test(value)) {
        return ctx.createError({
          message: t('form.invoiceProductField', { ns: 'invoices' }),
        });
      }
      return true;
    }),
    paymentPageType: yup
      .string()
      .required(t('form.paymentPageTypeRequired', { ns: 'invoices' })),
  });
  return yupResolver(schema);
};

type Props = {
  onSubmit?: VoidFunction;
};

const CreateInvoiceFormComp = ({ onSubmit: onOuterSubmit }: Props) => {
  const contract = useSelectedContract();
  const productRegexp = contract?.validationSettings?.find(
    (x) => x.name === 'InvoiceProductRegex',
  )?.value;

  const perms = useUserPermissions();
  const warn = useShowPermissionsWarning();
  const saveTemplate = useSaveInvoiceTemplateMutation();
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const { isLoading } = useInvoicesQuery();

  const toasts = useToasts();
  const { t } = useTranslation(['common', 'invoices']);
  const create = useCreateInvoiceMutation();
  const refresh = useRefreshInvoices();
  const formatUnits = useFormatDaysHoursMinutes();
  const defaultPaymentPageType = useDefaultInvoicePaymentPageType();
  const defaultCurrency = useDefaultInvoiceCurrency();
  const defaultValues: InvoiceFormData = useMemo(
    () => ({
      currency: defaultCurrency ?? '',
      lifetime: contract?.expirationTimeout
        ? contract.expirationTimeout
        : 24 * 60 * 10,
      shouldNotification: true,
      amount: 0,
      product: '',
      email: '',
      remember: false,
      paymentPageType: defaultPaymentPageType,
    }),
    [contract, defaultCurrency, defaultPaymentPageType],
  );

  const methods = useForm<InvoiceFormData>({
    defaultValues,
    resolver: createResolver(t, formatUnits, contract, productRegexp),
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, defaultValues, reset]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (values) => {
    const { remember, ...rest } = values;
    if (!perms.invoices.create) {
      return warn();
    }
    try {
      if (!contract?.id) {
        return;
      }

      // пустая строка соответствует значению default в выпадающем списке
      const paymentPageType = !rest?.paymentPageType
        ? DEFAULT_PAYMENT_PAGE
        : rest?.paymentPageType;

      await create.mutateAsync({
        ...rest,
        contractId: contract.id,
        paymentPageType,
      });
      toasts.success(t('success'));
      if (remember) {
        log('saving template: %O', rest);
        await saveTemplate.mutateAsync({ ...rest, paymentPageType });
      }

      onOuterSubmit?.();
    } catch (e) {
      setError(getError(e));
    } finally {
      await refresh();
    }
  });
  const { invoices } = useUserPermissions();

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={2}>
          <Suspense fallback={null}>
            <Templates />
          </Suspense>
          <PaymentPageTypes />
          {isLoading ? <FieldSkeleton /> : <SelectCurrency name="currency" />}
          <Amount />
          <Lifetime name="lifetime" />
          <FloatingInputField name="email" label={t('invoices:form.email')} />
          <FloatingInputField
            name="product"
            label={t('invoices:form.description')}
          />
          <CheckboxField name="shouldNotification">
            <Span>{t('invoices:form.notify')}</Span>
          </CheckboxField>
          <CheckboxField name="remember">
            <Span>{t('invoices:form.remember')}</Span>
          </CheckboxField>
          <Info>{t('invoices:form.notifyInfo')}</Info>
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <Tooltip
            label={!invoices.create ? t('common:noAccess') : ''}
            shouldWrapChildren
          >
            <Button
              disabled={!invoices.create}
              type="submit"
              isLoading={create.isPending}
            >
              {t('invoices:form.send')}
            </Button>
          </Tooltip>
        </VStack>
      </form>
    </FormProvider>
  );
};

export const CreateInvoiceForm = (props: Props) => {
  return (
    <Suspense fallback={null}>
      <CreateInvoiceFormComp {...props} />
    </Suspense>
  );
};
export default CreateInvoiceForm;

const Amount = () => {
  const { watch } = useFormContext();
  const { getCurrencyDecimalsCount } = useCurrencyCallbacks();
  const currencyValue = watch('currency');
  const { getCurrencySymbol } = useCurrencyCallbacks();
  const { t } = useTranslation(['invoices']);
  const { isLoading } = useInvoicesQuery();

  if (isLoading) {
    return <FieldSkeleton />;
  }

  return (
    <FloatingInputField
      variant="currency"
      currencySuffix={getCurrencySymbol(currencyValue)}
      currencyDecimals={getCurrencyDecimalsCount(currencyValue)}
      name="amount"
      label={t('invoices:form.amount', {
        currency: getCurrencySymbol(currencyValue),
      })}
      autoFocus
    />
  );
};

const Info = chakra('p', {
  baseStyle: {
    textStyle: TextStyles.Caption12Regular,
    color: 'primary.350',
  },
});

const Span = chakra('span', {
  baseStyle: {
    color: 'primary.500',
    textStyle: TextStyles.Subtitle14Regular,
  },
});

const PaymentPageTypes = () => {
  const options = usePaymentPageTypesOptions();
  const { isLoading } = useInvoicesQuery();

  if (isLoading) {
    return <FieldSkeleton />;
  }

  return !options.length ? (
    <PaymentPageTypeInput />
  ) : (
    <PaymentPageTypesDropdown />
  );
};

const PaymentPageTypesDropdown = () => {
  const { t } = useTranslation(['invoices']);
  const options = usePaymentPageTypesOptions();
  return (
    <DropdownField
      options={options}
      fieldName="paymentPageType"
      label={t('invoices:form.paymentPageTypes')}
      hideClearButton
      floating
    />
  );
};

const PaymentPageTypeInput = () => {
  const { t } = useTranslation(['invoices']);
  return (
    <FloatingInputField
      name="paymentPageType"
      label={t('invoices:form.paymentPageTypes')}
      isReadOnly={true}
    />
  );
};
