import React, { FC, ReactNode } from 'react';
import { chakra, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const TwoLevelTextTableCell: FC<{
  top: ReactNode;
  bottom: ReactNode;
}> = ({ top, bottom }) => {
  return (
    <VStack alignItems="flex-start" spacing={0}>
      <chakra.span textStyle={TextStyles.tables}>{top}</chakra.span>
      <chakra.span textStyle={TextStyles.Caption12Regular} color="primary.350">
        {bottom}
      </chakra.span>
    </VStack>
  );
};
