import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { PaginationProps } from '@payler/ui-components';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useCallback } from 'react';
import { MayBe, TCreateInvoiceRequest } from '../../../../../api/src';
import { DEFAULT_PAGER_PROPS, DEFAULT_PAYMENT_PAGE } from '../../const';
import { useCurrencyCallbacks } from '../currencies-info';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';
import { useURLContractCurrencies } from '../use-url-contract-currencies';
import { useSelectedContract } from '../contracts';

const INVOICES_STALE_TIME = 60 * 1000;
const INVOICES_TEMPLATES_STALE_TIME = 600 * 1000;

export const useInvoicesQuery = () => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  if (!contractId) throw new Error('no contractId');
  return useQuery({
    queryKey: ['invoices', culture, contractId, pagerData],
    queryFn: async () => {
      return api.getInvoices(contractId, pagerData);
    },
    staleTime: INVOICES_STALE_TIME,
  });
};

export const useInvoicesQueryPagerProps = () => {
  const payments = useInvoicesQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const useInvoicesTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contractId');
  return useSuspenseQuery({
    queryKey: ['invoices', 'templates', contractId],
    queryFn: () => api.getInvoicesTemplate(contractId),
    staleTime: INVOICES_TEMPLATES_STALE_TIME,
  });
};

/**
 * Сбросить кэш инвойсов и перейти на первую страницу
 */
export const useRefreshInvoices = () => {
  const queryClient = useQueryClient();
  const { goToPage } = useInvoicesQueryPagerProps();
  return useCallback(async () => {
    queryClient
      .invalidateQueries({ queryKey: ['invoices'] })
      .then(() => goToPage(0));
  }, [goToPage, queryClient]);
};

/**
 * Выставить счет
 */
export const useCreateInvoiceMutation = () => {
  const api = useApi();
  const refresh = useRefreshInvoices();
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  return useMutation({
    mutationFn: (payload: TCreateInvoiceRequest) =>
      api.createInvoice(payload, getCurrencyUnitRate(payload.currency)),

    onSuccess: () => refresh(),
  });
};

export const useInvoicePaymentPageTypeChecker = () => {
  const contract = useSelectedContract();

  return (paymentPageType: MayBe<string>) =>
    contract?.paymentPageTypes?.findIndex((v) => v === paymentPageType) !== -1;
};

/**
 * Дефолтный (актуальный) Шаблон платежной формы из списка инвойсов
 */
export const useDefaultInvoicePaymentPageType = () => {
  const { data } = useInvoicesQuery();
  const contract = useSelectedContract();
  const hasPaymentPageTypes = useInvoicePaymentPageTypeChecker();

  if (!contract?.paymentPageTypes?.length) {
    return DEFAULT_PAYMENT_PAGE;
  }

  const lastPaymentPageType = data?.lastInvoiceParams.paymentPageType;

  /**
   * Если то, что пришло в параметрах `lastPaymentPageType`, существует в
   * текущем списке, то вернем его, иначе вернем `default`
   */
  return lastPaymentPageType && hasPaymentPageTypes(lastPaymentPageType)
    ? lastPaymentPageType
    : DEFAULT_PAYMENT_PAGE;
};

/**
 * Дефолтная (актуальная) валюта из списка инвойсов
 */
export const useDefaultInvoiceCurrency = () => {
  const currencies = useURLContractCurrencies();
  const { data } = useInvoicesQuery();
  return data?.lastInvoiceParams?.currencyCode ?? currencies?.[0];
};
