import { TReport } from '../../../../../api/src';
import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { HStack, Icon, Input, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { Pencil16Icon } from '@payler/ui-icons';
import { useIsMounted } from '@payler/utils';
import { useUpdateReportNameMutation } from '../../hooks/reports/misc';

type State = {
  submitting?: boolean;
  edit?: boolean;
};

export const EditReportNameCell = ({ report }: { report: TReport }) => {
  const isMounted = useIsMounted();
  const [state, setState] = useState<State>({});
  const [name, setName] = useState(report.name);
  const updateNameMutation = useUpdateReportNameMutation();
  const submit = useCallback(async () => {
    if (report.name === name.trim()) {
      return setName((v) => v.trim());
    }
    setState((v) => ({ ...v, submitting: true }));
    try {
      await updateNameMutation.mutateAsync({ id: report.id, name });
    } finally {
      if (isMounted()) {
        setState({});
      }
    }
  }, [isMounted, name, report.id, report.name, updateNameMutation]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (!name.trim()) return;
        submit().then(() => {
          setState({ submitting: false });
        });
      }
      if (e.key === 'Escape') {
        setState({});
      }
    },
    [name, submit],
  );

  if (!state.edit) {
    return (
      <VStack spacing={0} alignItems="center">
        <HStack alignItems="center" justifyContent="center">
          <Text
            textStyle={TextStyles.tables}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            minW={0}
            flex={1}
          >
            {report.name}
          </Text>
          <Icon
            as={Pencil16Icon}
            color="primary.300"
            _hover={{ color: 'primary.500' }}
            cursor="pointer"
            onClick={() => setState({ edit: true })}
          />
        </HStack>

        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {report.userName}
        </Text>
      </VStack>
    );
  }
  return (
    <Input
      isDisabled={state.submitting}
      value={name}
      onKeyDown={handleKeyDown}
      onChange={(e) => setName(e.target.value)}
      onBlur={() => setState({})}
      autoFocus
    />
  );
};
