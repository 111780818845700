import React, { FC } from 'react';
import {
  Box,
  Breadcrumb,
  Button,
  ButtonProps,
  HStack,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { FullWidthBlock, PageTitle } from '../layouts';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoints } from '@payler/ui-theme';
import { Card, CardContent, NoData, Skeleton } from '@payler/ui-components';
import { TBulkPayout } from '../../../../api/src';
import TableSkeleton from '../components/TableSkeleton/TableSkeleton';
import { ChargePaymentModal } from '../modals/ChargePaymentModal';
import { RefundPaymentModal } from '../modals/RefundPaymentModal';
import { RetrievePaymentModal } from '../modals/RetrievePaymentModal';
import NotFoundPage from './NotFoundPage';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbBackButton } from '../components/BreadcrumbBackButton/BreadcrumbBackButton';
import {
  useBulkPayoutDetailsQuery,
  useDownloadBulkPayoutReport,
} from '../hooks/payouts/queries';
import { BulkPaymentDetails } from '../components/BulkPaymentDetails/BulkPaymentDetails';
import { useBackUrl } from '../hooks/use-back-url';
import WithdrawalsTable from '../tables/PaymentsTable/WithdrawalsTable';

const Wrap = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-template-areas: 'bulk-details' 'withdrawals';
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'bulk-details' 'withdrawals';
  }
`;

const PageSkeleton = () => {
  return (
    <>
      <Skeleton h={4} w="200px" m={3} />
      <Wrap>
        <Box gridArea="bulk-details">
          <TableSkeleton />
        </Box>
        <Box gridArea="withdrawals">
          <TableSkeleton />
        </Box>
      </Wrap>
    </>
  );
};

const PaymentPageWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation(['titles']);
  return (
    <>
      <Helmet title={t('titles:payment')} />
      {children}
      <ChargePaymentModal />
      <RefundPaymentModal />
      <RetrievePaymentModal />
    </>
  );
};

export const BulkPayoutDetailsPage: React.FC = () => {
  const { id: routerId } = useParams<{ id?: string }>();
  const { status, data } = useBulkPayoutDetailsQuery(routerId);

  if (
    routerId === undefined ||
    data === undefined ||
    data === null ||
    status === undefined
  ) {
    return (
      <PaymentPageWrapper>
        <NoData />
      </PaymentPageWrapper>
    );
  }

  return (
    <PaymentPageWrapper>
      <Header status={status} data={data} />
      <Contents status={status} data={data} />
    </PaymentPageWrapper>
  );
};

const Contents = ({ status, data }: { status: string; data: TBulkPayout }) => {
  const { id } = useParams<{ id?: string }>();

  switch (status) {
    case 'error':
      return <NotFoundPage />;
    case 'loading':
      return <PageSkeleton />;
    case 'success':
      return (
        <Wrap>
          <FullWidthBlock gridArea="bulk-details">
            <MobileButtons id={data.id} />
            <BulkPaymentDetails data={data} />
          </FullWidthBlock>

          <FullWidthBlock gridArea="withdrawals">
            <Card>
              <CardContent p={0}>
                <WithdrawalsTable bulkPayoutId={id} />
              </CardContent>
            </Card>
          </FullWidthBlock>
        </Wrap>
      );
    default:
      return null;
  }
};

const Header = ({ status, data }: { status: string; data: TBulkPayout }) => {
  const backUrl = useBackUrl('makebulkpayout');

  switch (status) {
    case 'success': {
      return (
        <HStack
          alignItems="flex-end"
          justifyContent="space-between"
          mb={{ base: 2, sm: 5 }}
        >
          <div>
            <Breadcrumb separator={<>&mdash;</>}>
              <BreadcrumbBackButton to={backUrl} />
            </Breadcrumb>

            <VStack alignItems="flexStart" spacing={0}>
              <PageTitle mb={0}>{data.bulkPayoutName}</PageTitle>
            </VStack>
          </div>
          <HeaderButtons id={data.id} />
        </HStack>
      );
    }
    case 'loading':
    case 'error':
    default:
      return null;
  }
};

const MobileButtons = ({ id }: { id: string }) => {
  const visible = useBreakpointValue({ base: true, md: false });
  if (!visible) return null;

  return (
    <HStack justifyContent="center" bg="brands.500" p={2}>
      <DownloadPDFButton id={id} variant="link" color="secondary.500" />
    </HStack>
  );
};

const HeaderButtons = ({ id }: { id: string }) => {
  const visible = useBreakpointValue({ base: false, md: true });
  if (!visible) return null;

  return (
    <HStack spacing={2}>
      <DownloadPDFButton id={id} />
    </HStack>
  );
};

const DownloadPDFButton: FC<{ id: string } & ButtonProps> = ({
  id,
  ...rest
}) => {
  const { t } = useTranslation(['payments']);
  const download = useDownloadBulkPayoutReport(id);

  return (
    <Button type="button" onClick={download} {...rest}>
      {t('payments:downloadPDF')}
    </Button>
  );
};
