import { MayBe } from '../../../../../api/src';
import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { chakra, HStack, Icon, Img } from '@chakra-ui/react';
import { PaymentSBP24Icon } from '@payler/ui-icons';
import React from 'react';

/**
 * Логотип банка и название
 * @param bankName - название банка
 * @param bankLogo - путь к логотипу
 * @constructor
 */
export const BankNameWithLogo = ({
  bankName,
  bankLogo,
}: {
  bankName?: MayBe<string>;
  bankLogo?: MayBe<string>;
}) => {
  const { apiServer } = useMerchantCabinetConfig();
  return (
    <HStack spacing={1} minW={0}>
      {bankLogo ? (
        <Img src={`${apiServer}${bankLogo}`} w={3} h={3} />
      ) : (
        <Icon as={PaymentSBP24Icon} w={3} h={3} color="brands.500" />
      )}
      <chakra.div
        textOverflow="ellipsis"
        overflowX="hidden"
        whiteSpace="nowrap"
      >
        {bankName}
      </chakra.div>
    </HStack>
  );
};
