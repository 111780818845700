import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Fade,
  HStack,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../../layouts';
import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { useUserPermissions } from '../../hooks/user/users';
import { LoadingBox } from '@payler/ui-components';
import { useNotificationSettingsByContract } from '../../hooks/settings/notifications';
import { DeleteNotificationSettingModal } from '../../modals/DeleteNotificationSettingModal';
import NotificationSettingCard from '../../components/NotificationSettingCard/NotificationSettingCard';
import {
  CreateNotificationSettingModal,
  useShowCreateNotificationSettingModal,
} from '../../modals/CreateNotificationSettingModal';
import { Helmet } from 'react-helmet-async';

export const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const NotificationsPage = () => {
  return (
    <Wrap>
      <Suspense fallback={null}>
        <NotificationsPageHeader />
      </Suspense>
      <FullWidthBlock>
        <Suspense fallback={null}>
          <MobileButtons />
        </Suspense>
        <Suspense fallback={<LoadingBox />}>
          <NotificationsList />
        </Suspense>
      </FullWidthBlock>
      <CreateNotificationSettingModal />
      <DeleteNotificationSettingModal />
    </Wrap>
  );
};

const NotificationsList = () => {
  const data = useNotificationSettingsByContract();
  return (
    <Stack direction="column" spacing={2}>
      {data.map(({ contract, notifications }) =>
        notifications?.map((notification) => (
          <NotificationSettingCard
            key={notification.id}
            notification={notification}
            contract={contract}
          />
        )),
      )}
    </Stack>
  );
};

const NotificationsPageHeader = () => {
  const { canCreateNotifications } = useUserPermissions();
  const showModal = useShowCreateNotificationSettingModal();
  const desktop = useBreakpointValue({ base: false, md: true });
  const buttonKey = useBreakpointValue({
    base: 'notifications:create',
    md: 'notifications:createMobile',
    lg: 'notifications:create',
  });
  const { t } = useTranslation([
    'titles',
    'notifications',
    'settings',
    'common',
    'settings',
  ]);
  if (!desktop) return null;
  return (
    <HStack
      alignItems="flex-start"
      justifyContent="space-between"
      mb={5}
      w="100%"
      maxW="1200px"
    >
      <Helmet
        title={`${t('titles:settings')} :: ${t('notifications:title')}`}
      />
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/settings">
              {t('settings:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/settings/notifications">
              {t('notifications:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('notifications:title')}</PageTitle>
      </div>
      {canCreateNotifications && (
        <Button onClick={showModal}>
          {t(
            (buttonKey as
              | 'notifications:create'
              | 'notifications:createMobile') ?? 'notifications:create',
          )}
        </Button>
      )}
    </HStack>
  );
};

const MobileButtons = () => {
  const { canCreateNotifications } = useUserPermissions();
  const showModal = useShowCreateNotificationSettingModal();
  const visible = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation(['notifications']);
  if (!canCreateNotifications) return null;
  if (!visible) return null;
  return (
    <Fade in={visible}>
      <Box justifyContent="stretch" bg="brands.500" p={2}>
        <Button
          variant="link"
          color="secondary.500"
          onClick={showModal}
          w="100%"
        >
          {t('notifications:create')}
        </Button>
      </Box>
    </Fade>
  );
};

export default NotificationsPage;
