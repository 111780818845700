import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { TMerchantCabinetLanguage } from '../config/ConfigProvider';

type TParams = {
  days?: number;
  hours?: number;
  minutes?: number;
};

export function useFormatDaysHoursMinutes(lang?: TMerchantCabinetLanguage) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['time']);
  const lng = lang ?? language;
  return useCallback(
    ({ days, hours, minutes }: TParams) => {
      const parts = [];
      if (days) {
        parts.push(t('time:days', { count: days, lng }));
      }
      if (hours) {
        parts.push(t('hours', { count: hours, lng }));
      }
      if (minutes) {
        parts.push(t('minutes', { count: minutes, lng }));
      }
      return parts.join(' ');
    },
    [t, lng],
  );
}
