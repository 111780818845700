import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { AxiosError } from 'axios';

export const useGetAxiosError = () => {
  const { t } = useTranslation(['common']);
  return useCallback(
    (e: unknown) => {
      if (e instanceof AxiosError && e.response) {
        if (e.response?.status === 400) {
          if (e.response?.data.errors) {
            return e.response?.data.errors[0];
          } else {
            const keys = Object.keys(e.response?.data);
            return e.response?.data[keys[0] ?? ''];
          }
        }
        if (e.response?.status >= 500) {
          return t('common:serverError', { status: e.response.status });
        }
      }
      return t('common:unknownError', { message: (e as Error).message });
    },
    [t],
  );
};
