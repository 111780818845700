import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, HStack, Switch, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

type TFiltersBulkPayoutsProps = {
  /** название поля для переключателя */
  formFieldSwitch: string;
  /** ключ локализации заголовка */
  titleKey: string;
};

export const FiltersBulkPayouts: FC<TFiltersBulkPayoutsProps> = ({
  formFieldSwitch,
  titleKey,
}) => {
  const { t } = useTranslation([]);
  const { watch, setValue } = useFormContext();

  const value = watch(formFieldSwitch) as string | null;

  return (
    <HStack alignItems="center" p={2}>
      <Box flex={1}>
        <Text textStyle={TextStyles.tables} color="primary.500">
          {t(titleKey)}
        </Text>
      </Box>
      <Switch
        isChecked={!!value}
        size="small"
        onChange={() => {
          setValue(formFieldSwitch, value ? null : 'True');
        }}
      />
    </HStack>
  );
};
