import { useTranslation } from 'react-i18next';
import { HStack, Text, Tooltip, useClipboard, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { MouseEventHandler, useCallback } from 'react';
import { MayBe } from '../../../../../api/src';

export const PanAndIdCell = ({
  id,
  pan,
}: {
  id: MayBe<string>;
  pan: MayBe<string>;
}) => {
  const { t } = useTranslation(['common']);
  const { hasCopied, onCopy } = useClipboard(id ?? '');
  const handleCopy: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onCopy();
    },
    [onCopy],
  );
  return (
    <VStack alignItems="flex-end" spacing={0}>
      <HStack>
        <Text textStyle={TextStyles.tables}>
          {(pan ?? '').replace(/\*/g, '·')}{' '}
        </Text>
      </HStack>
      <Tooltip
        isOpen={hasCopied || undefined}
        label={hasCopied ? t('common:copied') : t('common:clickToCopy')}
        hasArrow
        placement="top"
      >
        <Text
          cursor="pointer"
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          maxW={{ base: '140px', lg: '350px' }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          onClick={handleCopy}
        >
          ID:&nbsp;{id}
        </Text>
      </Tooltip>
    </VStack>
  );
};
