import { useTranslation } from 'react-i18next';
import { Suspense, useEffect } from 'react';
import { useUserInfo } from '../hooks/user/users';
import { getLanguage } from '../config/ConfigProvider';
import { TCurrentUserInfo } from '../../../../api/src';
import createLogger from 'debug';
import { ErrorBoundary } from '@payler/ui-components';

const log = createLogger('UserCultureWatcher');

const Wrap = () => {
  const userInfo = useUserInfo();
  if (!userInfo) return null;
  return <UserCultureWatcherComp userInfo={userInfo} />;
};

const UserCultureWatcherComp = ({
  userInfo: { user },
}: {
  userInfo: TCurrentUserInfo;
}) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    if (user) {
      const l = getLanguage(user.interfaceLanguage);
      if (l !== language) {
        changeLanguage(l).then(() => log('language changed to "%s"', l));
      }
    }
  }, [user, language, changeLanguage]);
  return null;
};

export const UserCultureWatcher = () => (
  <Suspense fallback={null}>
    <ErrorBoundary>
      <Wrap />
    </ErrorBoundary>
  </Suspense>
);
