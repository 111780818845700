import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@payler/ui-components';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { TPaymentTemplate } from '../../../../../api/src';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getNumericRangeKey, getRangeKey } from '../../helpers/ranges';
import { TemplateLabeledValue } from '../../components/TemplateLabeledValue/TemplateLabeledValue';
import { TemplateWrap } from '../../components/TemplateWrap/TemplateWrap';
import { usePaymentsTemplatesQuery } from '../../hooks/payments/queries';
import {
  useApplyPaymentsTemplate,
  useDeletePaymentsTemplate,
} from '../../hooks/payments/templates';
import {
  useApplyPayoutsTemplate,
  useDeletePayoutTemplate,
} from '../../hooks/payouts/templates';
import { usePayoutsTemplatesQuery } from '../../hooks/payouts/queries';
import { useAntifraudTemplatesQuery } from '../../hooks/antifraud/queries';
import {
  useApplyAntifraudTemplate,
  useDeleteAntifraudTemplate,
} from '../../hooks/antifraud/templates';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';
import { TCurrencyEnKeys } from '../../i18n/namespaces/currency';

type WithTemplate = {
  template: TPaymentTemplate;
};

type TWithTemplateHandlers = {
  applyTemplate: (template: TPaymentTemplate) => void;
  deleteTemplate: (id: number) => Promise<void | boolean>;
};

export const Templates = () => {
  const { data: templates } = usePaymentsTemplatesQuery();
  const applyTemplate = useApplyPaymentsTemplate();
  const deleteTemplate = useDeletePaymentsTemplate();
  if (!templates?.length) return null;
  return (
    <Accordion alignSelf="stretch">
      <Header />
      <AccordionBody p={2}>
        <VStack spacing={1} alignItems="stretch">
          {templates.map((template) => (
            <Template
              template={template}
              key={template.id}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate.mutateAsync}
            />
          ))}
        </VStack>
      </AccordionBody>
    </Accordion>
  );
};

export const AntifraudTemplates = () => {
  const { data: templates } = useAntifraudTemplatesQuery();
  const applyTemplate = useApplyAntifraudTemplate();
  const deleteTemplate = useDeleteAntifraudTemplate();
  if (!templates?.length) return null;
  return (
    <Accordion alignSelf="stretch">
      <Header />
      <AccordionBody p={2}>
        <VStack spacing={1} alignItems="stretch">
          {templates.map((template) => (
            <Template
              template={template}
              key={template.id}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate.mutateAsync}
            />
          ))}
        </VStack>
      </AccordionBody>
    </Accordion>
  );
};

export const PayoutsTemplates = () => {
  const { data: templates } = usePayoutsTemplatesQuery();
  const applyTemplate = useApplyPayoutsTemplate();
  const deleteTemplate = useDeletePayoutTemplate();
  if (!templates?.length) return null;
  return (
    <Accordion alignSelf="stretch">
      <Header />
      <AccordionBody p={2}>
        <VStack spacing={1} alignItems="stretch">
          {templates.map((template) => (
            <Template
              template={template}
              key={template.id}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate.mutateAsync}
            />
          ))}
        </VStack>
      </AccordionBody>
    </Accordion>
  );
};

export const Template = ({
  template,
  applyTemplate,
  deleteTemplate,
}: WithTemplate & TWithTemplateHandlers) => {
  return (
    <TemplateWrap>
      <Dates template={template} />
      <PaymentStatus template={template} />
      <PaymentType template={template} />
      <PaymentMethod template={template} />
      <Currency template={template} />
      <Amount template={template} />
      <Box h="0.5px" bg="primary.100" />
      <Buttons
        template={template}
        applyTemplate={applyTemplate}
        deleteTemplate={deleteTemplate}
      />
    </TemplateWrap>
  );
};

const Buttons = ({
  template,
  applyTemplate,
  deleteTemplate,
}: WithTemplate & TWithTemplateHandlers) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation(['filters']);
  const handleApply = () => {
    applyTemplate(template);
  };
  const handleDelete = () => {
    setDisabled(true);
    deleteTemplate(template.id).catch(() => setDisabled(false));
  };
  return (
    <HStack spacing={2}>
      <Button
        variant="link"
        color="primary.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={handleApply}
        disabled={disabled}
      >
        {t('filters:apply')}
      </Button>
      <Button
        variant="link"
        color="red.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={handleDelete}
        disabled={disabled}
      >
        {t('filters:delete')}
      </Button>
    </HStack>
  );
};

//region template rows
const Dates = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['fields', 'common']);
  const dateFormat = useUIDateFormat();
  if (!template.startDate && !template.endDate) return null;
  const from = dayjs(template.startDate).format(dateFormat);
  const to = dayjs(template.endDate).format(dateFormat);
  const key = getRangeKey(template.startDate, template.endDate);
  return (
    <TemplateLabeledValue
      label={t('fields:period')}
      value={t(key, { start: from, end: to })}
    />
  );
};

const Currency = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['fields', 'currency']);
  const v = template.currencyCodes
    ?.map((x) => t(`currency:${x as TCurrencyEnKeys}`))
    .join(', ');
  if (!v) return null;
  return <TemplateLabeledValue label={t('fields:currency')} value={v} />;
};

const PaymentMethod = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['fields', 'paymentMethod']);
  const v = template.paymentMethods
    ?.map((x) => t(`paymentMethod:${x}`))
    .join(', ');
  if (!v) return null;
  return <TemplateLabeledValue label={t('fields:paymentMethod')} value={v} />;
};

const PaymentType = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['fields', 'paymentIndicator']);
  const v = template.paymentIndicators
    ?.map((x) => t(`paymentIndicator:${x}`))
    .join(', ');
  if (!v) return null;
  return (
    <TemplateLabeledValue label={t('fields:paymentIndicator')} value={v} />
  );
};

const PaymentStatus = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['transactionStatus', 'fields']);
  const v = template.transactionStatuses
    ?.map((x) => t(`transactionStatus:${x}`))
    .join(', ');
  if (!v) return null;
  return <TemplateLabeledValue label={t('fields:paymentStatus')} value={v} />;
};

const Amount = ({ template }: WithTemplate) => {
  const { t } = useTranslation(['fields', 'common']);
  const key = getNumericRangeKey(template.startAmount, template.endAmount);
  if (!key) return null;
  return (
    <TemplateLabeledValue
      label={t('fields:amount')}
      value={t(key, {
        start: Number(template.startAmount),
        end: Number(template.endAmount),
      })}
    />
  );
};
//endregion

const Header = () => {
  const { t } = useTranslation(['filters']);
  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {t('filters:templates')}
          </Text>
        </Box>
      </HStack>
    </AccordionHeader>
  );
};
