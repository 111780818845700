import { useMerchantContractsQuery } from '../contracts';
import {
  TNotificationSetting,
  TUpdateNotificationSetting,
} from '../../../../../api/src';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../context/ApiContextProvider';

const useNotificationsQuery = () => {
  const api = useApi();
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => {
      return api.getNotificationSettings();
    },
  });
};

const useNotifications = () => {
  const { data } = useNotificationsQuery();
  return data ?? [];
};

/**
 * Список настроек уведомлений (с разбивкой по контрактам)
 */
export const useNotificationSettingsByContract = () => {
  const { data: contracts = [] } = useMerchantContractsQuery();
  const notifications = useNotifications();
  return contracts
    .map((contract) => ({
      contract,
      notifications: notifications.filter((x) => x?.contractId === contract.id),
    }))
    .filter((x) => !!x.notifications && !!x.notifications.length);
};

/**
 * Удалить настройку уведомлений
 */
export const useDeleteNotificationSettingMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => api.deleteNotificationSetting(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData<TNotificationSetting[]>(
        ['notifications'],
        (prev) => prev?.filter((x) => x.id !== id),
      );
    },
  });
};

/**
 * Обновить данные настройки уведомления
 */
export const useUpdateNotificationSettingMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: TUpdateNotificationSetting;
    }) => {
      return api.updateNotificationSetting(id, data);
    },
    onSuccess: (newData) => {
      queryClient.setQueryData<TNotificationSetting[]>(
        ['notifications'],
        (prev) => (prev ?? []).map((x) => (x.id !== newData.id ? x : newData)),
      );
    },
  });
};

/**
 * создать настройку уведомления
 */
export const useCreateNotificationSetting = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Omit<TNotificationSetting, 'id'>) =>
      api.createNotificationSetting(data),
    onSuccess: (data) => {
      queryClient.setQueryData<TNotificationSetting[]>(
        ['notifications'],
        (prev) => [data, ...(prev ?? [])],
      );
    },
  });
};
