import { TPayout } from '../../../../../api/src';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@chakra-ui/react';
import { Alert16Icon } from '@payler/ui-icons';
import React from 'react';

export const Alert = ({ data }: { data: TPayout }) => {
  const { t } = useTranslation(['payments']);
  if (!data.isAntifraudDeclined) return null;
  return (
    <Tooltip label={t('payments:rejectedByAntifraud')} shouldWrapChildren>
      <Icon as={Alert16Icon} w={2} h={2} color="red" />
    </Tooltip>
  );
};
