import React from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FullWidthBlock, PageTitle } from '../layouts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  SavePaymentsToReportModal,
  useShowSavePaymentsToReportModal,
} from '../modals/SavePaymentsToReportModal';
import { breakpoints } from '@payler/ui-theme';
import { Filter2Icon } from '@payler/ui-icons';
import {
  PayoutsFiltersModal,
  useShowPayoutsFiltersModal,
} from '../modals/PayoutsFiltersModal';
import PayoutsTable from '../tables/PaymentsTable/PayoutsTable';
import PayoutsFiltersForm from '../forms/PaymentsFilters/PayoutsFiltersForm';
import { useSavePayoutsFiltersToReport } from '../hooks/payouts/queries';
import { useUserPermissions } from '../hooks/user/users';
import { Helmet } from 'react-helmet-async';
import { useSelectedContract } from '../hooks/contracts';

const Wrap = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'table filters';
    grid-column-gap: 16px;
    align-items: flex-start;
  }
`;

export const PayoutsPage: React.FC = () => {
  const { t } = useTranslation(['titles']);
  const saveToReport = useSavePayoutsFiltersToReport();
  return (
    <>
      <Helmet title={t('titles:withdrawals')} />
      <React.Suspense fallback={null}>
        <Header />
      </React.Suspense>
      <MobileFiltersButton />
      <Wrap>
        <FullWidthBlock
          bg="secondary.500"
          borderRadius={{ base: 0, sm: 1.5 }}
          border="0.5px solid"
          borderColor="primary.100"
          gridArea="table"
        >
          <PayoutsTable />
        </FullWidthBlock>
        <DesktopFilters />
      </Wrap>
      <SavePaymentsToReportModal onSave={saveToReport} />
      <PayoutsFiltersModal />
    </>
  );
};

const MobileFiltersButton = () => {
  const showModal = useShowPayoutsFiltersModal();
  const { t } = useTranslation(['filters']);
  const visible = useBreakpointValue({ base: true, lg: false });
  if (!visible) return null;
  return (
    <Button
      onClick={showModal}
      mb={2}
      w="100%"
      variant="secondary"
      leftIcon={<Icon as={Filter2Icon} color="primary.350" />}
    >
      {t('filters:title')}
    </Button>
  );
};

const DesktopFilters = () => {
  const visible = useBreakpointValue({ base: false, lg: true });
  if (!visible) return null;
  return (
    <Box
      gridArea="filters"
      bg="secondary.500"
      border="0.5px solid"
      borderColor="primary.100"
      borderRadius={1.5}
    >
      <PayoutsFiltersForm />
    </Box>
  );
};

const Header = () => {
  const show = useShowSavePaymentsToReportModal();
  const { canCreateReports } = useUserPermissions();
  const contract = useSelectedContract();
  const buttonVisible = useBreakpointValue({ base: false, sm: true });
  const { t } = useTranslation(['payments', 'common']);

  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>
              {t('payments:payoutsTitle')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('payments:payoutsTitle')}</PageTitle>
      </div>
      {buttonVisible && (
        <Tooltip
          label={!canCreateReports ? t('common:noAccess') : ''}
          shouldWrapChildren
        >
          <Button disabled={!canCreateReports} onClick={show}>
            {t('payments:saveToReport')}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

export default PayoutsPage;
