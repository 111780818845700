import React from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FullWidthBlock, PageTitle } from '../layouts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaymentsTable } from '../tables/PaymentsTable/PaymentsTable';
import PaymentsFiltersForm from '../forms/PaymentsFilters/PaymentsFiltersForm';
import styled from '@emotion/styled';
import {
  SavePaymentsToReportModal,
  useShowSavePaymentsToReportModal,
} from '../modals/SavePaymentsToReportModal';
import { breakpoints } from '@payler/ui-theme';
import { Filter2Icon } from '@payler/ui-icons';
import {
  PaymentsFiltersModal,
  useShowPaymentsFiltersModal,
} from '../modals/PaymentsFiltersModal';
import { useSavePaymentsFiltersToReport } from '../hooks/payments/queries';
import { useUserPermissions } from '../hooks/user/users';
import { Helmet } from 'react-helmet-async';
import { useSelectedContract } from '../hooks/contracts';

const Wrap = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'table filters';
    grid-column-gap: 16px;
    align-items: flex-start;
  }
`;

export const PaymentsPage: React.FC = () => {
  const saveToReport = useSavePaymentsFiltersToReport();
  const { t } = useTranslation(['titles']);
  return (
    <>
      <Helmet title={t('titles:payments')} />
      <React.Suspense fallback={null}>
        <Header />
      </React.Suspense>
      <MobileFiltersButton />
      <Wrap>
        <FullWidthBlock
          bg="secondary.500"
          borderRadius={{ base: 0, sm: 1.5 }}
          border="0.5px solid"
          borderColor="primary.100"
          gridArea="table"
        >
          <PaymentsTable />
        </FullWidthBlock>
        <DesktopFilters />
      </Wrap>
      <SavePaymentsToReportModal onSave={saveToReport} />
      <PaymentsFiltersModal />
    </>
  );
};

const MobileFiltersButton = () => {
  const showModal = useShowPaymentsFiltersModal();
  const { t } = useTranslation(['filters']);
  const visible = useBreakpointValue({ base: true, lg: false });
  if (!visible) return null;
  return (
    <Button
      onClick={showModal}
      mb={2}
      w="100%"
      variant="secondary"
      leftIcon={<Icon as={Filter2Icon} color="primary.350" />}
    >
      {t('filters:title')}
    </Button>
  );
};

const DesktopFilters = () => {
  const visible = useBreakpointValue({ base: false, lg: true });
  if (!visible) return null;
  return (
    <Box
      gridArea="filters"
      bg="secondary.500"
      border="0.5px solid"
      borderColor="primary.100"
      borderRadius={1.5}
    >
      <PaymentsFiltersForm />
    </Box>
  );
};

const Header = () => {
  const show = useShowSavePaymentsToReportModal();
  const { canCreateReports } = useUserPermissions();
  const buttonVisible = useBreakpointValue({ base: false, sm: true });
  const contract = useSelectedContract();
  const { t } = useTranslation(['payments', 'common']);
  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>{t('payments:title')}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('payments:title')}</PageTitle>
      </div>
      {buttonVisible && (
        <Tooltip
          label={!canCreateReports ? t('common:noAccess') : ''}
          shouldWrapChildren
        >
          <Button disabled={!canCreateReports} onClick={show}>
            {t('payments:saveToReport')}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

export default PaymentsPage;
