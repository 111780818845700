import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TExtendedContract } from '../../../../../api/src';
import { chakra, Text, useBreakpointValue } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@payler/ui-components';
import { MenuLink } from './MenuLink';
import {
  Card20Icon,
  Flash20Icon,
  ListIcon,
  PlusCircleIcon,
  WalletIcon,
} from '@payler/ui-icons';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import ScrollBar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useContracts, useMerchantContractsQuery } from '../../hooks/contracts';
import { usePreviousDate } from '../../hooks/use-previous-date';
import { useParams } from 'react-router-dom';
import { Loader } from '../Loader';
import { ReactComponent as FPSPaymentsIcon } from './fps-payments.svg';
import { ReactComponent as CryptoPaymentsIcon } from './crypto-payments.svg';

type ID = TExtendedContract['id'];
type TMenuContext = {
  openedId: ID | undefined;
  setOpenedId: Dispatch<SetStateAction<ID | undefined>>;
};
const MenuContext = createContext<TMenuContext>({
  openedId: undefined,
  setOpenedId: (id) => console.log('TMenuContext.setOpenedId', id),
});
const MenuContextProvider: FCC = ({ children }) => {
  // тут берём из роутера, а не из контекста ContractBasedPagesContext
  // потому что menu живёт отдельно от этих страниц by design
  const { contractId: urlContractId } = useParams();
  const { data: contracts } = useMerchantContractsQuery();
  const [openedId, setOpenedId] = useState<ID | undefined>(
    urlContractId ? Number(urlContractId) : undefined,
  );

  useEffect(() => {
    if (contracts) {
      setOpenedId((v) => v ?? contracts[0]?.id);
    }
  }, [contracts]);

  return (
    <MenuContext.Provider value={{ openedId, setOpenedId }}>
      {children}
    </MenuContext.Provider>
  );
};
const useMenuContext = () => useContext(MenuContext);

export const MenuContracts = () => {
  const isMobile = useBreakpointValue({ base: true, sm: false });
  return (
    <MenuContextProvider>
      {isMobile ? <MenuContractsMobile /> : <MenuContractsDesktop />}
    </MenuContextProvider>
  );
};

const MenuContractsDesktop: React.FC = () => {
  const { data: contracts, isLoading } = useMerchantContractsQuery();
  if (isLoading) {
    return <Loader />;
  }
  if (!contracts) return null;
  return (
    <chakra.div flex="1 1 0" minH={0} userSelect="none">
      <ScrollBar>
        {contracts.map((c) => (
          <ContractAccordion key={c.id} contract={c} />
        ))}
      </ScrollBar>
    </chakra.div>
  );
};

const MenuContractsMobile: React.FC = () => {
  const contracts = useContracts();
  if (!contracts) return null;
  return (
    <chakra.div flex="1 1 0" minH={0} overflowY="scroll" userSelect="none">
      {contracts.map((c) => (
        <ContractAccordion key={c.id} contract={c} />
      ))}
    </chakra.div>
  );
};

const ContractAccordion: React.FC<{ contract: TExtendedContract }> = ({
  contract,
}) => {
  const { openedId, setOpenedId } = useMenuContext();
  const onChange = useCallback(
    (expanded: boolean) => {
      setOpenedId((v) =>
        expanded ? contract.id : v === contract.id ? v : undefined,
      );
    },
    [contract.id, setOpenedId],
  );
  const expanded = contract.id === openedId;
  return (
    <Accordion
      alignSelf="stretch"
      expanded={expanded}
      bg={expanded ? 'primary.25' : 'secondary.500'}
      onChangeState={onChange}
      variant="chevron"
    >
      <AccordionHeader pl={4} py={2}>
        <Text
          textStyle={TextStyles.Subtitle14Semibold}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {contract.name}
        </Text>
      </AccordionHeader>

      <AccordionBody>
        <Debit contract={contract} />
        <Credit contract={contract} />
        <Antifraud contract={contract} />
        <FPS contract={contract} />
        <APM contract={contract} />
        <CryptoPayments contract={contract} />
      </AccordionBody>
    </Accordion>
  );
};

const SectionTitle = chakra('h4', {
  baseStyle: {
    pl: 4,
    textStyle: TextStyles.Tagline10Bold,
    fontWeight: 'bold',
    color: 'primary.300',
    textTransform: 'uppercase',
  },
});

type WithContract = { contract: TExtendedContract };

const Debit = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.debitEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:payments')}</SectionTitle>
      <MenuLink
        icon={ListIcon}
        route={`/${contract.id}/pay?StartDate=${prevDate}`}
        title={t('menu:list')}
      />
      <MenuLink
        icon={PlusCircleIcon}
        route={`/${contract.id}/bill`}
        title={t('menu:invoice')}
      />
    </>
  );
};

const Credit = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.creditEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:withdrawals')}</SectionTitle>
      <MenuLink
        icon={ListIcon}
        route={`/${contract.id}/credit?StartDate=${prevDate}`}
        title={t('menu:list')}
      />
      <MenuLink
        icon={Card20Icon}
        route={`/${contract.id}/makecredit`}
        title={t('menu:makecredit')}
      />
    </>
  );
};

const FPS = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.fasterPaymentsEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:sbpTitle')}</SectionTitle>
      <MenuLink
        icon={Flash20Icon}
        route={`/${contract.id}/faster-payouts?DateFrom=${prevDate}`}
        title={t('menu:sbpLink')}
      />
      <MenuLink
        icon={FPSPaymentsIcon}
        route={`/${contract.id}/faster-payments?CreatedAtFrom=${prevDate}`}
        title={t('menu:sbpPaymentsLink')}
      />
    </>
  );
};

const APM = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.apmPaymentsEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:apmTitle')}</SectionTitle>
      <MenuLink
        icon={FPSPaymentsIcon}
        route={`/${contract.id}/apm-payments?StartDate=${prevDate}`}
        title={t('menu:sbpPaymentsLink')}
      />
    </>
  );
};

const CryptoPayments = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.cryptoPaymentsEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:cryptoPaymentsTitle')}</SectionTitle>
      <MenuLink
        icon={CryptoPaymentsIcon}
        route={`/${contract.id}/crypto-payments?StartDate=${prevDate}`}
        title={t('menu:cryptoPaymentsLink')}
      />
    </>
  );
};

const Antifraud = ({ contract }: WithContract) => {
  const { t } = useTranslation(['menu']);
  const prevDate = usePreviousDate();
  if (!contract.antifraudEnabled) return null;
  return (
    <>
      <SectionTitle>{t('menu:antifraud')}</SectionTitle>
      <MenuLink
        icon={WalletIcon}
        route={`/${contract.id}/alerts?StartDate=${prevDate}`}
        title={t('menu:alerts')}
      />
    </>
  );
};
