import { Helmet } from 'react-helmet-async';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import {
  Breadcrumb,
  BreadcrumbItem,
  HStack,
  VStack,
  Text,
  Box,
  Badge,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BreadcrumbBackButton } from '../components/BreadcrumbBackButton/BreadcrumbBackButton';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import { useApmPaymentSessionQuery } from '../hooks/apm-payments/queries';
import { PageTitle } from '../layouts';
import ReactNumberFormat from 'react-number-format';
import { Card, CardContent, ClipboardText } from '@payler/ui-components';
import { FullWidthBlock } from '../layouts';
import { PaymentSessionTransitionTable } from '../tables/PaymentSessionTransitionTable/PaymentSessionTransitionTable';
import dayjs from 'dayjs';
import { TApmPaymentStatus } from '../../../../api/src';

export const ApmPaymentSessionDetail = () => {
  const { t } = useTranslation(['titles']);

  return (
    <>
      <Helmet title={t('titles:paymentSessionDetails')} />
      <Head />
      <Content />
    </>
  );
};

const Head = () => {
  const contractId = useURLContractId();
  const { status, data } = useApmPaymentSessionQuery();
  const { getCurrencyUnitRate, getCurrencyDecimalsCount, getCurrencySymbol } =
    useCurrencyCallbacks();

  if (status !== 'success' || !data) {
    return null;
  }

  return (
    <HStack
      w="full"
      spacing={2}
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <VStack w="full" spacing={1} alignItems="flex-start">
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbBackButton to={`/${contractId}/apm-payments`} />
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle mb={0}>
          <ReactNumberFormat
            suffix={`\u00a0${getCurrencySymbol(data.currency)}`}
            value={data.amount / getCurrencyUnitRate(data.currency)}
            displayType="text"
            isNumericString={true}
            decimalSeparator={'.'}
            decimalScale={getCurrencyDecimalsCount(data.currency)}
            allowLeadingZeros={true}
            thousandSeparator={' '}
            fixedDecimalScale={true}
          />
        </PageTitle>
      </VStack>
    </HStack>
  );
};

const Content = () => {
  const { data } = useApmPaymentSessionQuery();

  if (!data) {
    return null;
  }

  return (
    <FullWidthBlock>
      <VStack w="full" spacing={2}>
        <SessionInfo />
        <PaymentSessionTransitionTable
          data={data.transitions}
          currency={data.currency}
        />
      </VStack>
    </FullWidthBlock>
  );
};

const SessionInfo = () => {
  const { data } = useApmPaymentSessionQuery();
  const { t } = useTranslation(['apmPayments']);
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const { getCurrencyUnitRate } = useCurrencyCallbacks();

  const getShortUuid = useCallback(
    (uuid: string) => {
      const blocks = uuid.split('-');

      if (!blocks.length || blocks.length <= 2 || !isMobile) {
        return uuid;
      }

      return `${blocks[0]}-...-${blocks[blocks.length - 1]}`;
    },
    [isMobile],
  );

  const displayedData = useMemo(() => {
    const {
      id,
      status,
      date,
      amount,
      currency,
      orderId,
      direction,
      paymentMethod,
      settledAmount,
    } = data || {};

    const statusBadgeVariant: Record<TApmPaymentStatus, string | undefined> = {
      Authorized: 'green',
      Rejected: 'red',
      Refunded: 'green',
      ProviderTechError: 'red',
      Created: 'grey',
      Canceled: 'grey',
      PreAuthenticatedRedirect: 'grey',
      Pending: 'grey',
      PreAuthenticatedThreeDSMethod: 'grey',
      PreAuthorized: 'grey',
    };

    return {
      [t('apmPayments:sessionId')]: id && (
        <ClipboardText text={id} shortText={getShortUuid(id)} />
      ),
      [t('apmPayments:orderId')]: orderId && (
        <ClipboardText text={orderId} shortText={getShortUuid(orderId)} />
      ),
      [t('apmPayments:direction')]: direction,
      [t('apmPayments:paymentMethod')]: paymentMethod,
      [t('apmPayments:statusDescription')]: status && (
        <Badge variant={statusBadgeVariant[status]}>{status}</Badge>
      ),
      [t('apmPayments:amount')]:
        typeof amount === 'number'
          ? amount / getCurrencyUnitRate(currency)
          : '',
      [t('apmPayments:paidAmount')]:
        (data.settledAmount ?? data.amount) / getCurrencyUnitRate(currency),
      [t('apmPayments:currencyCode')]: currency,
      [t('apmPayments:date')]: dayjs(date).format('DD.MM.YYYY HH:mm:ss'),
    };
  }, [data, t, getShortUuid]);

  return (
    <Card w="full">
      <CardContent>
        <VStack spacing={2}>
          {Object.entries(displayedData).map(
            ([k, v]) => k && v && <InfoItem key={k} label={k} value={v} />,
          )}
        </VStack>
      </CardContent>
    </Card>
  );
};

const InfoItem: FC<{ label: string; value: ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <HStack h={3} justifyContent="space-between" width="full">
      <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
        {label}
      </Text>
      <Box
        style={{
          borderBottomStyle: 'dotted',
          borderBottomWidth: 2.5,
          borderBottomColor: 'primary.300',
        }}
        h={1.5}
        w="full"
      />
      <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
        {value}
      </Text>
    </HStack>
  );
};
