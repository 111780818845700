import {
  TContractPayload,
  TExtendedContract,
} from '../../../../api/src';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { ContractBasedPagesContext } from '../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../context/ApiContextProvider';
import {
  ALL_PAYMENT_INDICATORS,
  GLOBAL_PAYMENT_INDICATORS,
  DEFAULT_PAYMENT_PAGE,
} from '../const';

const CONTRACTS_STALE_TILE = 60 * 60000;
/**
 * Запрос доступных контрактов
 */
export const useMerchantContractsQuery = () => {
  const api = useApi();
  return useQuery({
    queryKey: ['contracts'],
    queryFn: () => {
      return api.getContracts();
    },
    staleTime: CONTRACTS_STALE_TILE,
  });
};

/**
 * Опции для выпадающих списков (доступные контракты)
 */
export const useMerchantContractsOptions = () => {
  const { data } = useMerchantContractsQuery();
  return useMemo(
    () =>
      data?.map((x) => ({
        label: x.name ?? 'no name 😱',
        value: x.id,
      })) ?? [],
    [data],
  );
};

/**
 * id-шники доступных контрактов
 */
export const useMerchantContractsIds = () => {
  const { data } = useMerchantContractsQuery();
  return useMemo(() => data?.map((x) => x.id) ?? [], [data]);
};

/**
 * Балансы контракта
 * @param contractId
 */
export const useBalancesQuery = (
  contractId: TExtendedContract['id'] | undefined,
) => {
  const api = useApi();
  return useQuery({
    queryKey: ['balances', contractId],
    queryFn: () => {
      if (!contractId) return undefined;
      return api.getBalances(contractId);
    },
  });
};

/**
 * Текущий контракт
 */
export const useSelectedContract = () =>
  useContext(ContractBasedPagesContext).selectedContract;

export const usePaymentIndicatorsByContract = () => {
  const contract = useSelectedContract();

  return contract?.apmPaymentsEnabled
    ? ALL_PAYMENT_INDICATORS
    : GLOBAL_PAYMENT_INDICATORS;
};

/**
 * Доступные контракты
 */
export const useContracts = () => useMerchantContractsQuery().data;

/**
 * Обновление настроек мерчанта
 */
export const useUpdateContract = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      contractId,
      payload,
    }: {
      contractId: number;
      payload: TContractPayload;
    }) => api.updateContract(contractId, payload),
    onSuccess: (response, { contractId }) => {
      queryClient.setQueryData<TExtendedContract[]>(['contracts'], (v) => {
        return v?.map((d) => {
          if (d.id === contractId) {
            return {
              ...d,
              ...response,
            };
          }
          return d;
        });
      });
    },
  });
};

/**
 * Опции для выпадающих списков шаблонов платежных форм контракта
 */
export const usePaymentPageTypesOptions = () => {
  const contract = useSelectedContract();
  return contract?.paymentPageTypes?.length
    ? contract?.paymentPageTypes.reduce(
        (acc, v) => {
          acc.push({ label: v, value: v });
          return acc;
        },
        [{ label: DEFAULT_PAYMENT_PAGE, value: DEFAULT_PAYMENT_PAGE }],
      )
    : [];
};

/**
 * Проверяем, что все контракты у мерчанта в Global сегменте
 * Если в будущем появится отдельный флаг для этого, то хук будет проще переделать
 */
export const useOnlyGlobalSegmentFlag = () => {
  const contracts = useContracts();

  return contracts?.every((c) => c.apmPaymentsEnabled) || false;
};
