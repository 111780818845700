import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  LoadingBox,
  NoData,
} from '@payler/ui-components';
import {
  Area,
  AreaChart,
  Customized,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import { useDayjsFormat } from '@payler/utils';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { TooltipAverageCharge } from './TooltipAverageCharge';
import { useMerchantCabinetConfig } from '../../../config/ConfigProvider';
import { StepDropdown } from '../filters/Step';
import dayjs from 'dayjs';
import { useCurrencyCallbacks } from '../../../hooks/currencies-info';
import {
  ChartStepContextProvider,
  useChartStepContext,
} from '../../../context/ChartStepContext';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';
import { useAverageChargeChartData } from '../data/avgChargeData';
import { TickFormatterFunc } from '../types';

const HEIGHT_PX = '352px';
const HEIGHT = 352;

const ChartComp = () => {
  const {
    data: { data },
    isLoading,
  } = useAverageChargeChartData();
  const { step } = useChartStepContext();
  const formatDate = useDayjsFormat();
  const { t } = useTranslation(['analytics']);
  let dateFormatter: TickFormatterFunc = (v) => formatDate(v, 'DD MMMM') ?? v;
  if (step === 'weeks') {
    dateFormatter = (v) => t('analytics:weekNum', { week: dayjs(v).week() });
  }
  if (step === 'months') {
    dateFormatter = (v) => formatDate(v, 'MMM') ?? v;
  }

  if (isLoading) return <LoadingBox h={HEIGHT_PX} />;
  if (!data) return <NoData h={HEIGHT_PX} />;
  if (!data || data.length === 0) return <NoData h={HEIGHT_PX} />;
  return (
    <Box
      sx={{
        '& svg .label': {
          textStyle: TextStyles.Caption12Regular,
          fill: 'primary.350',
        },

        '& svg .sum': {
          textStyle: TextStyles.h2,
          fill: 'primary.500',
          paintOrder: 'stroke',
          strokeWidth: 3,
          strokeLinecap: 'butt',
          stroke: emotionColors.secondary500,
        },
      }}
      mx={{ base: -2, sm: 0 }}
    >
      <ResponsiveContainer width="100%" height={HEIGHT}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="avg" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#22C55E" stopOpacity={0.1} />
              <stop offset="100%" stopColor="#22C55E" stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="date"
            interval="preserveStart"
            tickFormatter={dateFormatter}
            tickLine={false}
            height={32}
            tickMargin={16}
          />
          <Tooltip content={TooltipAverageCharge} animationDuration={250} />
          <Area
            type="monotone"
            dataKey={'avg'}
            stroke="#22C55E"
            strokeWidth={2}
            fillOpacity={1}
            fill={`url(#avg`}
            name={t(`analytics:averageCharge`)}
          />
          <Customized component={<TotalAvg />} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

const CardComp = () => {
  const { t } = useTranslation(['analytics']);
  const stepVisible = useBreakpointValue({ base: false, md: true });
  return (
    <Card>
      <CardHead alignSelf="stretch">
        <CardTitle>{t('analytics:averageChargeTitle')}</CardTitle>
        {stepVisible && <StepDropdown />}
      </CardHead>
      <CardContent
        sx={{
          '.recharts-cartesian-axis-tick': {
            textStyle: TextStyles.Caption12Medium,
            color: 'primary.350',
          },
        }}
      >
        <ChartComp />
      </CardContent>
    </Card>
  );
};

export const ChartAverageCharge = () => (
  <ChartStepContextProvider>
    <Suspense fallback={null}>
      <CardComp />
    </Suspense>
  </ChartStepContextProvider>
);

const TotalAvg: () => JSX.Element = () => {
  const { getCurrencyUnitRate, getCurrencySymbol, getCurrencyDecimalsCount } =
    useCurrencyCallbacks();
  const offset = useBreakpointValue({ base: 16, sm: 0 });
  const { culture } = useMerchantCabinetConfig();
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const {
    data: { totalAvg },
    isLoading,
  } = useAverageChargeChartData();
  if (isLoading || totalAvg === undefined) return <text />;

  const formattedAvg = new Intl.NumberFormat(culture, {
    minimumFractionDigits: getCurrencyDecimalsCount(CurrencyCode),
    maximumFractionDigits: getCurrencyDecimalsCount(CurrencyCode),
  }).format(totalAvg / getCurrencyUnitRate(CurrencyCode));
  const sumText = `${formattedAvg}\u00a0${getCurrencySymbol(CurrencyCode)}`;
  return (
    <text x={offset} textAnchor="left">
      <tspan dy="0.75em" className="sum">
        {sumText}
      </tspan>
    </text>
  );
};

export default ChartAverageCharge;
