import {
  TFasterPaymentOperation,
  TFasterPaymentOperationStatus,
} from '../../../../../api/src';
import {
  Badge,
  chakra,
  ComponentStyleConfig,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import React, { MouseEventHandler, useCallback } from 'react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: '800px',
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const FasterPaymentOperationsTable = ({
  operations,
}: {
  operations: TFasterPaymentOperation[];
}) => {
  const { t } = useTranslation(['payments']);
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('payments:operations')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {operations.map((row, idx) => (
                  <Row data={row} key={idx} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

export const getOperationStatusBadgeVariant = (
  status?: TFasterPaymentOperationStatus,
): string | undefined => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Expired':
    case 'Failed':
      return 'red';
    case 'Pending':
    case 'Created':
      return 'yellow';
  }
  return undefined;
};

const Row = ({ data }: { data: TFasterPaymentOperation }) => {
  return (
    <Tr>
      <Td>
        <chakra.span textStyle={TextStyles.tables}>
          {dayjs(data.createdAt).format('HH:mm:ss DD.MM.YYYY')}
        </chakra.span>
      </Td>
      <Td>{data.typeDescription}</Td>
      <Td>
        <Badge variant={getOperationStatusBadgeVariant(data.status)}>
          {data.statusDescription}
        </Badge>
      </Td>
      <Td>
        <PublicIdCell publicId={data.publicId} />
      </Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell
          amount={data.amount}
          currencyCode={data.currencyCode}
        />
      </Td>
    </Tr>
  );
};

export const PublicIdCell = ({ publicId }: { publicId?: string }) => {
  const { t } = useTranslation(['fields', 'common']);
  const { hasCopied, onCopy } = useClipboard(publicId ?? '');
  const handleCopy: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onCopy();
    },
    [onCopy],
  );
  return (
    <VStack alignItems="flex-end" spacing={0}>
      <HStack>
        <Text textStyle={TextStyles.tables}>{t('fields:publicId')}</Text>
      </HStack>
      <Tooltip
        isOpen={hasCopied || undefined}
        label={hasCopied ? t('common:copied') : t('common:clickToCopy')}
        hasArrow
        placement="top"
      >
        <Text
          cursor="pointer"
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          maxW={{ base: '140px', lg: '350px' }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          onClick={handleCopy}
        >
          {publicId}
        </Text>
      </Tooltip>
    </VStack>
  );
};

const Header = () => {
  const { t } = useTranslation(['tableHeader']);
  return (
    <Thead>
      <Tr>
        <Th>{t('tableHeader:created')}</Th>
        <Th>{t('tableHeader:description')}</Th>
        <Th>{t('tableHeader:status')}</Th>
        <Th textAlign="right">{t('tableHeader:publicId')}</Th>
        <Th textAlign="right">{t('tableHeader:amount')}</Th>
      </Tr>
    </Thead>
  );
};

export default FasterPaymentOperationsTable;
