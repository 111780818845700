import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FloatingInputField } from '@payler/ui-components';
import React from 'react';

export const NotificationPostUrl = () => {
  const { watch } = useFormContext();
  const visible = watch('notificationType') === 'Post';
  const { t } = useTranslation(['notifications']);
  if (!visible) return null;
  return (
    <FloatingInputField name="url" label={t('notifications:recipients')} />
  );
};
