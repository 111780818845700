import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
  SegmentedPicker,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../layouts';
import React, { FC, Suspense, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { breakpoints, TextStyles } from '@payler/ui-theme';
import { RecreateInvoiceModal } from '../modals/RecreateInvoiceModal';
import { WithdrawForm } from '../forms/WithdrawForm/WithdrawForm';
import { useBalancesQuery, useSelectedContract } from '../hooks/contracts';
import NumberFormat from 'react-number-format';
import WithdrawalsTable from '../tables/PaymentsTable/WithdrawalsTable';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import { Helmet } from 'react-helmet-async';
import { Doc14Icon } from '@payler/ui-icons';
import { BulkPayoutForm } from '../forms/BulkPayoutForm/BulkPayoutForm';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import {
  BulkPayoutErrorContextProvider,
  useBulkPayoutErrorContext,
} from '../context/BulkPayoutErrorContext';
import { BulkPayoutsTable } from '../tables/PaymentsTable/BulkPayoutsTable';
import { useApi } from '../context/ApiContextProvider';
import { assert } from '@payler/utils';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'form' 'table';
  grid-row-gap: 16px;
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-areas: 'form table';
    align-items: flex-start;
    grid-column-gap: 16px;
  }
`;
const PAYOUT_MODE = ['single', 'bulkPayout'] as const;
type TPayoutMode = (typeof PAYOUT_MODE)[number];

export const WithdrawalsPage: FC<{ mode: TPayoutMode }> = ({ mode }) => {
  const { t } = useTranslation(['titles']);

  return (
    <BulkPayoutErrorContextProvider>
      <Helmet title={t('titles:withdraw')} />
      <Suspense fallback={null}>
        <InvoicesPageHeader />
      </Suspense>
      <WithdrawalsModeSwitcher mode={mode} />
      <BulkPayoutError />
      <Suspense fallback={null}>
        <Balances />
      </Suspense>

      <Wrap>
        <TableCard mode={mode} />
        <FormCard mode={mode} />
      </Wrap>
      <Suspense fallback={null}>
        <RecreateInvoiceModal />
      </Suspense>
    </BulkPayoutErrorContextProvider>
  );
};

const WithdrawalsModeSwitcher: FC<{
  mode: TPayoutMode;
}> = ({ mode }) => {
  const { t } = useTranslation(['withdrawals']);
  const { setBulkPayoutError, clearBulkPayoutError } =
    useBulkPayoutErrorContext();
  const optionsLoc: Record<TPayoutMode, string> = {
    single: t('withdrawals:single'),
    bulkPayout: t('withdrawals:mass'),
  };
  const { isBulkPayoutAllowed } = useSelectedContract() || {};

  const contractId = useURLContractId();
  const options = [optionsLoc.single, optionsLoc.bulkPayout];
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (mode === 'bulkPayout' && !isBulkPayoutAllowed) {
      setBulkPayoutError({ type: 'bulkPayoutsNotEnabled' });
    } else if (mode !== 'bulkPayout') {
      clearBulkPayoutError();
    }
  }, [mode]);

  /**
   * Сделано на уровне роутинга, так как на странице две разные таблицы с
   * пагинацией
   * @param newIndex
   */
  const handleChangeMode = (newIndex: number) => {
    switch (PAYOUT_MODE[newIndex]) {
      case 'single':
        navigate(`/${contractId}/makecredit`);
        break;
      case 'bulkPayout':
        navigate(`/${contractId}/makebulkpayout`);
        break;
      default:
        throw new Error('Unsupported payout mode');
    }
  };

  return (
    <Box w="full" my={4}>
      <SegmentedPicker
        options={options}
        onSelectionChange={handleChangeMode}
        selection={PAYOUT_MODE.indexOf(mode)}
      />
    </Box>
  );
};

const Balances = () => {
  const contract = useSelectedContract();
  const { data: balances } = useBalancesQuery(contract?.id);
  const { getCurrencyUnitRate, getCurrencyDecimalsCount, getCurrencySymbol } =
    useCurrencyCallbacks();
  if (!balances) return null;
  return (
    <FullWidthBlock gridArea="balances" mb={4}>
      <ScrollBarWithFade>
        <HStack spacing={2} minW="100%" justifyContent="space-between">
          {balances.map((x, i) => (
            <Card key={i} minW="250px" borderRadius={1.5} flex={1}>
              <CardContent>
                <chakra.h5
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                  mb={1}
                >
                  {x.name}
                </chakra.h5>
                <chakra.p textStyle={TextStyles.h4}>
                  <NumberFormat
                    displayType="text"
                    suffix={`\u00a0${getCurrencySymbol(x.currencyCode)}`}
                    decimalScale={getCurrencyDecimalsCount(x.currencyCode)}
                    fixedDecimalScale
                    thousandSeparator={' '}
                    value={x.amount / getCurrencyUnitRate(x.currencyCode)}
                  />
                </chakra.p>
              </CardContent>
            </Card>
          ))}
        </HStack>
      </ScrollBarWithFade>
    </FullWidthBlock>
  );
};

const TableCard: FC<{ mode: TPayoutMode }> = ({ mode }) => {
  const { t } = useTranslation(['withdrawals']);
  return (
    <FullWidthBlock gridArea="table">
      <Card>
        <CardHead>
          <CardTitle>{t('withdrawals:listTitle')}</CardTitle>
        </CardHead>
        <CardContent p={0}>
          {mode === 'single' && <WithdrawalsTable />}
          {mode === 'bulkPayout' && <BulkPayoutsTable />}
        </CardContent>
      </Card>
    </FullWidthBlock>
  );
};

const FormCard: FC<{ mode: TPayoutMode }> = ({ mode }) => {
  const { t } = useTranslation(['withdrawals']);

  return (
    <FullWidthBlock gridArea="form">
      <Card>
        <CardHead>
          <CardTitle>{t('withdrawals:formTitle')}</CardTitle>
        </CardHead>
        {mode === 'single' && (
          <CardContent>
            <WithdrawForm />
          </CardContent>
        )}
        {mode === 'bulkPayout' && (
          <>
            <CardContent>
              <DownloadTemplate />
            </CardContent>
            <Divider />
            <CardContent>
              <React.Suspense fallback={null}>
                <BulkPayoutForm />
              </React.Suspense>
            </CardContent>
          </>
        )}
      </Card>
    </FullWidthBlock>
  );
};

const DownloadTemplate = () => {
  const { t } = useTranslation(['withdrawals']);
  const api = useApi();
  const contractId = useURLContractId();

  const handleDownloadButtonClick = async () => {
    assert(!!contractId, 'Contract id must be number');
    await api.downloadBulkPayoutTemplate(contractId);
  };

  return (
    <HStack w="full" justifyContent="space-between">
      <Flex
        as="button"
        type="button"
        role="button"
        cursor="pointer"
        fontWeight={500}
        color="brands.500"
        onClick={handleDownloadButtonClick}
      >
        {t('withdrawals:form.downloadTemplate')}
      </Flex>
      <Icon as={Doc14Icon} color="brands.500" />
    </HStack>
  );
};

const InvoicesPageHeader = () => {
  const { t } = useTranslation(['withdrawals', 'common']);
  const contract = useSelectedContract();
  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>{t('withdrawals:title')}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('withdrawals:title')}</PageTitle>
      </div>
    </HStack>
  );
};

export const BulkPayoutError = () => {
  const { errorMessage } = useBulkPayoutErrorContext();
  if (!errorMessage) return null;

  return (
    <Flex
      w="full"
      bgColor="red.100"
      border="1px solid"
      borderColor="red.100"
      borderRadius={1.5}
      p={2}
      mb={4}
    >
      {errorMessage}
    </Flex>
  );
};

export default WithdrawalsPage;
