import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { chakra, HStack, VStack } from '@chakra-ui/react';
import { Dropdown, TDropdownOption } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { useSelectedContract } from '../../hooks/contracts';
import { useTranslation } from 'react-i18next';

function generateOptions(from: number, to: number): TDropdownOption[] {
  const result: TDropdownOption[] = [];
  for (let x = from; x <= to; x++) {
    result.push({ label: `${x}`, value: x });
  }
  return result;
}

export const Lifetime = ({ name = 'lifetime' }: { name?: string }) => {
  const contract = useSelectedContract();
  const { t } = useTranslation(['invoices']);
  const maxDays = contract?.expirationTimeout
    ? Math.floor(contract?.expirationTimeout / 24 / 60)
    : 10;
  const { daysOptions, hoursOptions, minutesOptions } = useMemo(() => {
    const daysOptions = generateOptions(0, maxDays);
    const hoursOptions = generateOptions(0, 23);
    const minutesOptions = generateOptions(0, 59);
    return {
      daysOptions,
      hoursOptions,
      minutesOptions,
    };
  }, [maxDays]);

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const lifetime = Number(watch(name) ?? 0);
  const formDays = Math.floor(lifetime / (60 * 24));
  const formHours = Math.floor(lifetime / 60) % 24;
  const formMinutes = Math.floor(lifetime % 60);

  const updateForm = ({ days, minutes, hours }: UpdateFormArgs) => {
    const newValue =
      (days ?? formDays) * 24 * 60 +
      (hours ?? formHours) * 60 +
      (minutes ?? formMinutes);
    setValue(name, newValue, {
      shouldValidate: true,
    });
  };

  return (
    <VStack alignItems="stretch" spacing={1}>
      <HStack sx={{ '& > div': { flex: 1 } }}>
        <Dropdown
          options={daysOptions}
          floatingLabel={t('invoices:form.days')}
          value={formDays}
          onChange={(v) => updateForm({ days: v ? Number(v) : 0 })}
          hideClearButton
          alignOptionsCenter
        />
        <Dropdown
          options={hoursOptions}
          floatingLabel={t('invoices:form.hours')}
          value={formHours}
          onChange={(v) => updateForm({ hours: v ? Number(v) : 0 })}
          hideClearButton
          alignOptionsCenter
        />
        <Dropdown
          options={minutesOptions}
          floatingLabel={t('invoices:form.minutes')}
          value={formMinutes}
          onChange={(v) => updateForm({ minutes: v ? Number(v) : 0 })}
          hideClearButton
          alignOptionsCenter
        />
      </HStack>
      {errors[name] && <Error>{errors[name]?.message as string}</Error>}
    </VStack>
  );
};

const Error = chakra('p', {
  baseStyle: {
    color: 'red.500',
    px: 1,
    textStyle: TextStyles.Caption12Regular,
  },
});

type UpdateFormArgs = {
  days?: number;
  hours?: number;
  minutes?: number;
};
