import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { theme as baseTheme } from '@payler/ui-theme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  ...baseTheme,
  config,
  styles: {
    global: {
      '.grecaptcha-badge': {
        visibility: 'hidden',
      },
    },
  },
});
