import React, { useRef, useState } from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { createRecoilKey } from '../helpers/createKey';
import { TCreateInvoiceRequest, TInvoice } from '../../../../api/src';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelectedContract } from '../hooks/contracts';
import { useGetAxiosError } from '../hooks/use-get-axios-error';
import { NotificationWithIcon, PaylerModal } from '@payler/ui-components';
import { ErrorBigIcon, QuestionBigIcon } from '@payler/ui-icons';
import {
  useInvoicesQueryPagerProps,
  useRefreshInvoices,
} from '../hooks/invoices/queries';
import { useToasts } from '../hooks/use-toasts';
import { useApi } from '../context/ApiContextProvider';

const recreateInvoiceModalAtom = atom<TInvoice | undefined>({
  key: createRecoilKey('recreateInvoiceModalAtom'),
  default: undefined,
});

export const useShowRecreateInvoiceModal = () =>
  useRecoilCallback(
    ({ set }) =>
      (invoice: TInvoice) => {
        set(recreateInvoiceModalAtom, invoice);
      },
    [],
  );

export const useCloseRecreateInvoiceModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(recreateInvoiceModalAtom, undefined);
      },
    [],
  );

export const RecreateInvoiceModal = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const toasts = useToasts();
  const refresh = useRefreshInvoices();
  const { t } = useTranslation(['invoices', 'common']);
  const { goToPage } = useInvoicesQueryPagerProps();
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const contract = useSelectedContract();
  const [submitting, setSubmitting] = useState(false);
  const invoice = useRecoilValue(recreateInvoiceModalAtom);
  const isOpen = !!invoice;
  const onClose = useCloseRecreateInvoiceModal();

  const api = useApi();
  const handleSubmit = async () => {
    try {
      setError('');
      setSubmitting(true);
      if (!invoice) {
        return;
      }
      const { finished, created, amount, currencyCode, email, phone } = invoice;
      const lifetime = dayjs(finished).diff(created, 'm');
      const contractId = contract?.id;
      if (
        !contractId ||
        !finished ||
        !created ||
        !amount ||
        !currencyCode ||
        !email
      )
        return;
      const payload: TCreateInvoiceRequest = {
        email,
        currency: currencyCode,
        contractId,
        lifetime,
        amount,
        phone: phone ?? undefined,
        shouldNotification: true,
      };
      await api.createInvoice(payload, 1);
      toasts.success(t('invoices:reinvoiced'));
      await refresh();
      goToPage(0);
      onClose();
    } catch (e) {
      const err = getError(e);
      setError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div ref={divRef}></div>
      <PaylerModal close={onClose} open={isOpen} finalFocusRef={divRef}>
        {!error && (
          <NotificationWithIcon
            title={t('invoices:recreateTitle')}
            text={t('invoices:recreateText')}
            icon={QuestionBigIcon}
          />
        )}
        {!!error && (
          <NotificationWithIcon
            title={t('common:error')}
            text={error}
            icon={ErrorBigIcon}
          />
        )}
        <HStack spacing={2} justifyContent="stretch" mt={2}>
          <Button
            flex={1}
            variant="secondary"
            onClick={onClose}
            isDisabled={submitting}
          >
            {t('common:cancel')}
          </Button>
          <Button flex={1} onClick={handleSubmit} isLoading={submitting}>
            {t('invoices:recreate')}
          </Button>
        </HStack>
      </PaylerModal>
    </>
  );
};
