import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import { useLocation } from 'react-router-dom';

type PaymentPageMode =
  | 'payment'
  | 'antifraud'
  | 'payout'
  | 'makecredit'
  | 'bill'
  | 'makebulkpayout';

export const useBackUrl = (mode: PaymentPageMode) => {
  const contractId = useURLContractId();
  const location = useLocation();
  switch (mode) {
    case 'antifraud':
      return `/${contractId}/alerts/${location.search}`;
    case 'payout':
      return `/${contractId}/credit/${location.search}`;
    case 'makecredit':
      return `/${contractId}/makecredit/${location.search}`;
    case 'makebulkpayout':
      return `/${contractId}/makebulkpayout/${location.search}`;
    case 'bill':
      return `/${contractId}/bill/${location.search}`;
    case 'payment':
    default:
      return `/${contractId}/pay/${location.search}`;
  }
};
