import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useMerchantPaymentTypeOptions = () => {
  const { t } = useTranslation(['settings']);
  return useMemo(
    () => [
      {
        value: 1,
        label: t('settings:payment.paymentTypeOptions.singleStage'),
      },
      {
        value: 2,
        label: t('settings:payment.paymentTypeOptions.twoStage'),
      },
    ],
    [t],
  );
};
