import React, { FC, memo } from 'react';
import {
  TApmPaymentTransitionResult,
  TCurrency,
  TSessionTransaction,
} from '../../../../../api/src';
import { tableStyleConfig } from '../tableStyleConfig';
import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Card, CardContent, ScrollBarWithFade } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import dayjs from 'dayjs';
import { TwoLevelTextTableCell } from '../TwoLevelTextTableCell';

const tdStyles = {
  w: '25%',
  minW: '200px',
};

type TPaymentSessionTransitionTableProps = {
  currency: TCurrency;
  data: TSessionTransaction[];
};

type TTableBodyProps = TPaymentSessionTransitionTableProps;
type TTableRowProps = {
  currency: TCurrency;
  date: TSessionTransaction['date'];
  type: TSessionTransaction['type'];
  result: TSessionTransaction['result'];
  requestedAmount: TSessionTransaction['requestedAmount'];
};
export const PaymentSessionTransitionTable: FC<TPaymentSessionTransitionTableProps> =
  memo(({ data, currency }) => {
    return (
      <Card
        w="full"
        borderLeftWidth={{ base: 0, sm: 0.5 }}
        borderRightWidth={{ base: 0, sm: 0.5 }}
      >
        <CardContent p={0}>
          <ScrollBarWithFade>
            <TableContainer position="relative">
              <Table styleConfig={tableStyleConfig}>
                <Header />
                <Body data={data} currency={currency} />
              </Table>
            </TableContainer>
          </ScrollBarWithFade>
        </CardContent>
      </Card>
    );
  });

const Header = () => {
  const { t } = useTranslation(['tableHeader']);
  return (
    <Thead>
      <Tr>
        <Th>{t('tableHeader:date')}</Th>
        <Th>{t('tableHeader:amount')}</Th>
        <Th>{t('tableHeader:type')}</Th>
        <Th>{t('tableHeader:result')}</Th>
      </Tr>
    </Thead>
  );
};

const Body: FC<TTableBodyProps> = ({ data, currency }) => {
  const { getCurrencyUnitRate } = useCurrencyCallbacks();

  return (
    <Tbody>
      {data.map(({ result, type, requestedAmount, date }, i) => (
        <Row
          key={i}
          date={date}
          type={type}
          result={result}
          requestedAmount={
            (requestedAmount ?? 0) / getCurrencyUnitRate(currency)
          }
          currency={currency}
        />
      ))}
    </Tbody>
  );
};

const Row: FC<TTableRowProps> = ({
  type,
  date,
  requestedAmount,
  result,
  currency,
}) => {
  const resultBadgeStatus: Record<TApmPaymentTransitionResult, string> = {
    Pending: 'grey',
    Failed: 'red',
    Initiated: 'grey',
    Succeed: 'green',
  };

  return (
    <Tr>
      <Td {...tdStyles}>
        <DateCell date={date} />
      </Td>
      <Td {...tdStyles}>
        <AmountCell amount={requestedAmount} currency={currency} />
      </Td>
      <Td {...tdStyles}>
        <Badge variant="grey">{type}</Badge>
      </Td>
      <Td {...tdStyles}>
        <Badge variant={resultBadgeStatus[result]}>{result}</Badge>
      </Td>
    </Tr>
  );
};

const DateCell: FC<{ date: string }> = ({ date }) => {
  const parsedDate = dayjs(date).format('DD.MM.YYYY');
  const parsedTime = dayjs(date).format('HH:mm:ss');

  return <TwoLevelTextTableCell top={parsedDate} bottom={parsedTime} />;
};

const AmountCell: FC<{ amount: number; currency: string }> = ({
  currency,
  amount,
}) => {
  return <TwoLevelTextTableCell top={amount} bottom={currency} />;
};
