import { TOperation } from '../../../../../api/src';
import {
  Badge,
  ComponentStyleConfig,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { CardAndOrderIdCell } from './CardAndOrderIdCell';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { PaymentDatesCell } from '../PaymentsTable/ReportDateCell';
import { getOperationStatusBadgeVariant } from './getOperationStatusBadgeVariant';

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: '800px',
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
    th: {
      textStyle: TextStyles.Caption12Medium,
      color: 'primary.400',
      textAlign: 'left',
      px: 1,
      py: 2,
      '&:first-of-type': {
        pl: 3,
      },
      '&:last-of-type': {
        pr: 3,
      },
    },
  },
};

export const PaymentOperationsTable = ({
  operations,
}: {
  operations: TOperation[];
}) => {
  const { t } = useTranslation(['payments']);
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('payments:operations')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {operations.map((row, idx) => (
                  <Row data={row} key={idx} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

const Row = ({ data }: { data: TOperation }) => {
  return (
    <Tr>
      <Td>
        <PaymentDatesCell bottomDate={data.created} topDate={data.date} />
      </Td>
      <Td>
        <Badge variant={getOperationStatusBadgeVariant(data.status)}>
          {data.statusDescription}
        </Badge>
      </Td>
      <Td>{data.bankCountryCode}</Td>
      <Td>
        <CardAndOrderIdCell data={data} />
      </Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell
          amount={data.amount}
          currencyCode={data.currencyCode}
        />
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation(['tableHeader']);
  return (
    <Thead>
      <Tr>
        <Th>{t('tableHeader:period')}</Th>
        <Th>{t('tableHeader:status')}</Th>
        <Th>{t('tableHeader:code')}</Th>
        <Th textAlign="right">{t('tableHeader:cardAndOrderId')}</Th>
        <Th textAlign="right">{t('tableHeader:amount')}</Th>
      </Tr>
    </Thead>
  );
};

export default PaymentOperationsTable;
