import { TPaymentMethods } from '../../../../../../api/src';

export const paymentMethodEn: Record<TPaymentMethods | string, string> = {
  field: 'Payment method',
  AlfaClick: 'AlfaClick',
  ApplePay: 'ApplePay',
  Beeline: 'Beeline',
  Card: 'Bank Card',
  Euroset: 'Euroset',
  GooglePay: 'GooglePay',
  Megafon: 'Megafon',
  Mts: 'Mts',
  PsbRetail: 'PsbRetail',
  Qiwi: 'Qiwi',
  RussianPost: 'RussianPost',
  SamsungPay: 'SamsungPay',
  SberOnline: 'SberOnline',
  Svyaznoy: 'Svyaznoy',
  Tele2: 'Tele2',
  Terminals: 'Terminals',
  Unistream: 'Unistream',
  WebMoney: 'WebMoney',
  YandexMoney: 'YandexMoney',
};
type TPaymentMethodTranslation = typeof paymentMethodEn;

export const paymentMethodRu: TPaymentMethodTranslation = {
  field: 'Способ оплаты',
  AlfaClick: 'AlfaClick',
  ApplePay: 'ApplePay',
  Beeline: 'Beeline',
  Card: 'Банковская карта',
  Euroset: 'Euroset',
  GooglePay: 'GooglePay',
  Megafon: 'Megafon',
  Mts: 'Mts',
  PsbRetail: 'PsbRetail',
  Qiwi: 'Qiwi',
  RussianPost: 'RussianPost',
  SamsungPay: 'SamsungPay',
  SberOnline: 'SberOnline',
  Svyaznoy: 'Svyaznoy',
  Tele2: 'Tele2',
  Terminals: 'Terminals',
  Unistream: 'Unistream',
  WebMoney: 'Web Money',
  YandexMoney: 'YandexMoney',
};
