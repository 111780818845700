import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { assert, groupByPredicate } from '@payler/utils';
import dayjs from 'dayjs';
import { PaginationProps } from '@payler/ui-components';
import {
  TCreatePayoutRequest,
  TCreateReportPayloadV2,
  TUploadBulkPayoutFileRequestBody,
} from '../../../../../api/src';
import { useCallback } from 'react';
import createLogger from 'debug';
import { usePayoutsFiltersFromUrl } from './url';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useDropReportsCache } from '../reports/cache';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { useCurrencyCallbacks } from '../currencies-info';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { usePreviousDate } from '../use-previous-date';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';
import { useSelectedContract } from '../contracts';

const log = createLogger('hooks:payments:queries');

const PAYOUTS_STALE_TIME = 60 * 1000;
const PAYOUTS_TEMPLATES_STALE_TIME = 600 * 1000;

export const usePayoutsQuery = (
  context: 'payouts' | 'withdrawals',
  bulkPayoutId?: string,
) => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const urlFilters = usePayoutsFiltersFromUrl();
  const previousDate = usePreviousDate();
  let filters =
    context === 'payouts' ? urlFilters : { StartDate: previousDate };

  // Если нужно получить для детализации массовой выплаты
  if (context === 'withdrawals' && bulkPayoutId) {
    filters = { IncludeBulkPayouts: true, BulkPayoutId: bulkPayoutId };
  }

  if (!contractId) throw new Error('no contractId');
  return useQuery({
    queryKey: [
      'payouts',
      culture,
      contractId,
      pagerData,
      filters,
      bulkPayoutId,
    ],
    queryFn: async () => {
      const data = await api.getPayouts({
        ContractId: contractId,
        ...filters,
        ...pagerData,
      });

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['firstOperationDate'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    staleTime: !bulkPayoutId ? PAYOUTS_STALE_TIME : undefined,
    refetchOnWindowFocus: !!bulkPayoutId ? true : undefined,
    refetchOnMount: !!bulkPayoutId ? true : undefined,
  });
};

export const usePayoutsQueryPagerProps = (
  context: 'payouts' | 'withdrawals',
  bulkPayoutId?: string,
) => {
  const payments = usePayoutsQuery(context, bulkPayoutId);
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const usePayoutsTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contractId');
  return useSuspenseQuery({
    queryKey: ['payouts', 'templates', contractId],
    queryFn: async () => api.getPayoutsTemplates(contractId),
    staleTime: PAYOUTS_TEMPLATES_STALE_TIME,
  });
};

export const useSavePayoutsFiltersToReport = () => {
  const dropReportsCache = useDropReportsCache();
  const filters = usePayoutsFiltersFromUrl();
  const api = useApi();
  const contractId = useURLContractId();
  const dateFormat = useUIDateFormat();
  return useCallback(
    async (name: string) => {
      try {
        if (!contractId) return;
        const data: TCreateReportPayloadV2 = {
          name,
          contractIds: [contractId],
          currencyCodes: filters.CurrencyCodes ?? [],
          transactionStatuses: filters.TransactionStatuses ?? [],
          startDate: filters.StartDate,
          endDate: filters.EndDate,
        };

        await api.createReportV2(data, dateFormat);
        dropReportsCache();
      } catch (e) {
        log('useSavePaymentsFiltersToReport error: %O', e);
      }
    },
    [contractId, api, dropReportsCache, filters, dateFormat],
  );
};

/**
 * рефрешим страницы сделать выплату и выплаты/список и переходим на первую страницу
 */
export const useDropPayoutsQueryCache = (
  context: Parameters<typeof usePayoutsQuery>[0],
) => {
  const client = useQueryClient();
  const { goToPage } = usePayoutsQueryPagerProps(context);
  return useCallback(() => {
    client.invalidateQueries({ queryKey: [context] }).then(() => goToPage(0));
  }, [client, goToPage]);
};

export const useMakeCreditMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  return useMutation({
    mutationFn: (data: TCreatePayoutRequest) =>
      api.createPayout(data, getCurrencyUnitRate(data.currency)),

    onSuccess: (_, { contractId }) => {
      queryClient
        .refetchQueries({ queryKey: ['balances', contractId] })
        .catch(() => log('failed to refetch balance'));
    },
  });
};

export const useBulkPayoutEncryptionKeyQuery = () => {
  const { isBulkPayoutAllowed } = useSelectedContract() || {};
  const api = useApi();
  const contractId = useURLContractId();
  assert(typeof contractId === 'number', 'Contract id must be number');

  return useSuspenseQuery({
    queryKey: ['bulkPayoutEncryptionKey', contractId],
    queryFn: async () =>
      isBulkPayoutAllowed ? api.getBulkPayoutEncryptionKey(contractId) : null,
    staleTime: PAYOUTS_STALE_TIME,
    refetchOnWindowFocus: false,
  });
};

export const useBulkPayoutsQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const previousDate = usePreviousDate();
  const { isBulkPayoutAllowed } = useSelectedContract() || {};

  assert(typeof contractId === 'number', 'Contract id must be number');

  return useQuery({
    queryKey: ['bulkPayouts', contractId, pagerData.Page, pagerData.PageSize],
    queryFn: async () =>
      api.getBulkPayouts({
        ContractId: contractId,
        StartDate: dayjs(previousDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        ...pagerData,
      }),
    staleTime: PAYOUTS_STALE_TIME,
    refetchOnWindowFocus: false,
    enabled: !!isBulkPayoutAllowed,
  });
};

export const useResetBulkPayoutsQuery = () => {
  const contractId = useURLContractId();
  assert(typeof contractId === 'number', 'Contract id must be number');
  const client = useQueryClient();
  return useCallback(async () => {
    if (!client.isFetching({ queryKey: ['bulkPayouts', contractId] })) {
      return await client.resetQueries({
        queryKey: ['bulkPayouts', contractId],
      });
    }
  }, []);
};

export const useBulkPayoutsQueryPagerProps = () => {
  const payoutsQuery = useBulkPayoutsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payoutsQuery.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payoutsQuery.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const useMakeBulkPayoutMutation = () => {
  const api = useApi();
  const contractId = useURLContractId();
  assert(typeof contractId === 'number', 'Contract id must be number');

  return useMutation({
    mutationFn: (data: Omit<TUploadBulkPayoutFileRequestBody, 'contractId'>) =>
      api.uploadBulkPayoutFile({ ...data, contractId }),
  });
};

export const useBulkPayoutDetailsQuery = (bulkPayoutId: string | undefined) => {
  const api = useApi();
  const client = useQueryClient();
  const contractId = useURLContractId();
  assert(typeof contractId === 'number', 'Contract id must be number');

  const data = useQuery({
    queryKey: ['bulkPayoutFileInfo', bulkPayoutId],
    queryFn: async () => {
      assert(!!bulkPayoutId, 'bulkPayoutId can not be empty');
      return await api.getBulkPayoutDetails(bulkPayoutId, contractId);
    },
    enabled: !!bulkPayoutId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const clearBulkPayoutFileInfo = useCallback(async () => {
    if (
      !client.isFetching({ queryKey: ['bulkPayoutFileInfo', bulkPayoutId] })
    ) {
      return await client.invalidateQueries({
        queryKey: ['bulkPayoutFileInfo', bulkPayoutId],
      });
    }
  }, [bulkPayoutId]);

  return { ...data, clearBulkPayoutFileInfo };
};

export const useBulkPayoutActionsMutation = () => {
  const api = useApi();
  const contractId = useURLContractId();
  assert(typeof contractId === 'number', 'Contract id must be number');
  const startMutation = useMutation({
    mutationFn: (params: { bulkPayoutId: string; password: string }) =>
      api.startBulkPayout({ contractId, ...params }),
  });
  const cancelMutation = useMutation({
    mutationFn: (bulkPayoutId: string) =>
      api.cancelBulkPayout(bulkPayoutId, contractId),
  });

  return { startMutation, cancelMutation };
};

export const useDownloadBulkPayoutReport = (id: string) => {
  const api = useApi();
  const contractId = useURLContractId();

  return async () => {
    assert(!!contractId, 'Contract id must be number');
    await api.downloadBulkPayoutReport(id, contractId);
  };
};
