export const transactionStatusEn = {
  allStatuses: 'All statuses',
  Authorized: 'Blocked',
  Charged: 'Successful',
  Created: 'Created',
  Credited: 'Credited',
  Pending: 'Pending',
  PendingOnlyCredit: 'Pending',
  PendingOnlyDebit: 'Pending',
  PreAuthorized3DS: 'Awaits 3DS',
  Refunded: 'Refunded',
  Registered: 'Registered',
  Rejected: 'Rejected',
  RejectedOnlyCredit: 'Rejected',
  RejectedOnlyDebit: 'Rejected',
  Reversed: 'Reversed',
  ProviderTechError: 'Provider Tech Error',
} as const;

export type TTransactionStatusEnKeys = keyof typeof transactionStatusEn;

export const transactionStatusRu: Record<TTransactionStatusEnKeys, string> = {
  allStatuses: 'Все статусы',
  Authorized: 'Заблокировано',
  Charged: 'Успешно',
  Created: 'Создан',
  Credited: 'Выдано',
  Pending: 'В ожидании',
  PendingOnlyCredit: 'В ожидании',
  PendingOnlyDebit: 'В ожидании',
  PreAuthorized3DS: 'Ожидает 3DS',
  Refunded: 'Возвращено',
  Registered: 'Registered',
  Rejected: 'Отклонено',
  RejectedOnlyCredit: 'Отклонено',
  RejectedOnlyDebit: 'Отклонено',
  Reversed: 'Разблокировано',
  ProviderTechError: 'Техническая ошибка на стороне платежного провайдера',
} as const;
