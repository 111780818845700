import { TMerchantCabinetEnvConfig } from '../../../../libs/merchant-cabinet/ui/src';

export const environment: TMerchantCabinetEnvConfig = {
  apiServer: '',
  googleRecaptchaKey: '6LeZUeMoAAAAAEFWoqWgCe18P-JZIMPogS07FArQ',
  googleTagManagerId: 'GTM-KVJ5JBX',
  disableGoogleRecaptchaAtLogin: true,
  flags: {
    'reports.ru_global_splitting': true,
  },
};
