import { Button, VStack } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TCreateReportPayloadV2 } from '../../../../../api/src';

type Props = {
  onSubmit?: VoidFunction;
  onSave: (name: string) => Promise<void>;
};

type FormData = Omit<TCreateReportPayloadV2, 'transactionStatuses'> & {
  name: string;
};

export const SavePaymentsToReportForm = ({
  onSubmit: outerOnSubmit,
  onSave,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation(['reports', 'fields']);
  const methods = useForm({ defaultValues: { name: '' } });

  const onSubmit = methods.handleSubmit(async ({ name }: FormData) => {
    try {
      setSubmitting(true);
      await onSave(name);
      outerOnSubmit?.();
    } finally {
      setSubmitting(false);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={3}>
          <FloatingInputField
            name="name"
            label={t('fields:reportName')}
            isRequired
            autoFocus
            autoComplete="off"
          />
          <Button type="submit" isLoading={submitting}>
            {t('reports:create')}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default SavePaymentsToReportForm;
