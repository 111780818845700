import { useTranslation } from 'react-i18next';
import React from 'react';
import { MultiDropdownField, TDropdownOption } from '@payler/ui-components';

export const Triggers = () => {
  const { t } = useTranslation(['notifications']);
  const options = useTriggerOptions();
  return (
    <MultiDropdownField
      fieldName="triggers"
      label={t('notifications:triggers')}
      options={options}
      selectAllLabel={t('notifications:allTriggers')}
      preset="mc1"
    />
  );
};

const useTriggerOptions = (): TDropdownOption[] => {
  const { t } = useTranslation(['notificationKind']);
  return [
    { value: 'Block', label: t('notificationKind:Block') },
    { value: 'Unblock', label: t('notificationKind:Unblock') },
    { value: 'Refund', label: t('notificationKind:Refund') },
    { value: 'Charge', label: t('notificationKind:Charge') },
    { value: 'Repeatpay', label: t('notificationKind:Repeatpay') },
    { value: 'Credit', label: t('notificationKind:Credit') },
    { value: 'Receipt', label: t('notificationKind:Receipt') },
    { value: 'Check', label: t('notificationKind:Check') },
    { value: 'Declined', label: t('notificationKind:Declined') },
    { value: 'CardSaved', label: t('notificationKind:CardSaved') },
  ];
};
