import { TNotificationKind } from '../../../../../../api/src';

export const notificationKindEn: Record<TNotificationKind, string> = {
  Block: 'Money blocked',
  Unblock: 'Money unlocked',
  Refund: 'Money back',
  Charge: 'Funds debited from the card',
  Repeatpay: 'Repeat payment',
  Credit: 'Money transfer',
  Receipt: 'Check generated',
  Check: 'Payment verification',
  Declined: 'Payment declined',
  CardSaved: 'Card saved',
} as const;

export const notificationsKindRu: Record<TNotificationKind, string> = {
  Block: 'Средства заблокированы',
  Unblock: 'Средства разблокированы',
  Refund: 'Средства возвращены',
  Charge: 'Средства списаны с карты',
  Repeatpay: 'Повторный платеж',
  Credit: 'Перевод денежных средств',
  Receipt: 'Чек сформирован',
  Check: 'Подтверждение платежа',
  Declined: 'Платеж отклонен',
  CardSaved: 'Карта сохранена',
} as const;
