import {
  TFasterPayoutCurrency,
  TFasterPayoutOperation,
  TFasterPayoutOperationStatus,
} from '../../../../../api/src';
import {
  Badge,
  chakra,
  ComponentStyleConfig,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { PanAndIdCell } from '../FasterPayoutsTable/PanAndIdCell';
import dayjs from 'dayjs';

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: '800px',
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const FasterPayoutOperationsTable = ({
  operations,
  currency,
}: {
  operations: TFasterPayoutOperation[];
  currency: TFasterPayoutCurrency;
}) => {
  const { t } = useTranslation(['payments']);
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('payments:operations')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {operations.map((row, idx) => (
                  <Row data={row} key={idx} currency={currency} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

export const getOperationStatusBadgeVariant = (
  status?: TFasterPayoutOperationStatus,
): string | undefined => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Failed':
      return 'red';
    case 'Pending':
    case 'Created':
      return 'yellow';
  }
  return undefined;
};

const Row = ({
  data,
  currency,
}: {
  data: TFasterPayoutOperation;
  currency: TFasterPayoutCurrency;
}) => {
  return (
    <Tr>
      <Td>
        <chakra.span textStyle={TextStyles.tables}>
          {dayjs(data.created).format('HH:mm:ss DD.MM.YYYY')}
        </chakra.span>
      </Td>
      <Td>{data.typeDescription}</Td>
      <Td>
        <Badge variant={getOperationStatusBadgeVariant(data.status)}>
          {data.statusDescription}
        </Badge>
      </Td>
      <Td>
        <PanAndIdCell pan={data.phone} id={data.publicId} />
      </Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell amount={data.amount} currencyCode={currency} />
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation(['tableHeader']);
  return (
    <Thead>
      <Tr>
        <Th>{t('tableHeader:created')}</Th>
        <Th>{t('tableHeader:description')}</Th>
        <Th>{t('tableHeader:status')}</Th>
        <Th textAlign="right">{t('tableHeader:phoneAndOrderId')}</Th>
        <Th textAlign="right">{t('tableHeader:amount')}</Th>
      </Tr>
    </Thead>
  );
};

export default FasterPayoutOperationsTable;
