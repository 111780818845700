import React, { ReactNode } from 'react';
import { Cell, Label, Pie, PieChart, PieProps, Tooltip } from 'recharts';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  LoadingBox,
  NoData,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { TooltipSum } from './TooltipSum';
import { config } from '../config';
import { Box, VStack } from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useMerchantCabinetConfig } from '../../../config/ConfigProvider';
import { PolarViewBox } from 'recharts/types/util/types';
import { Props } from 'recharts/types/component/Label';
import { PieChartLegend } from '../PieChartLegend';
import { useCurrencyCallbacks } from '../../../hooks/currencies-info';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';
import { usePieChartData } from '../data/pieChartData';

const RenderCenter: (props: Props) => ReactNode = ({ viewBox }) => {
  const { getCurrencySymbol } = useCurrencyCallbacks();
  const { getCurrencyUnitRate } = useCurrencyCallbacks();
  const { culture } = useMerchantCabinetConfig();
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const { t } = useTranslation(['analytics']);
  const { data } = usePieChartData();
  if (!viewBox) return null;
  if (!data) return null;
  const firstData = data[0];
  const totalTransactions = firstData?.totalTransactions ?? 0;
  const totalSum = firstData?.totalSum ?? 0;

  const formattedNumber = new Intl.NumberFormat(culture).format(
    Number(totalSum / getCurrencyUnitRate(CurrencyCode)),
  );
  const sumText = `${formattedNumber}\u00a0${getCurrencySymbol(CurrencyCode)}`;
  const { cx, cy } = viewBox as PolarViewBox;
  return (
    <>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="central"
        dy={-24}
      >
        <tspan alignmentBaseline="middle" className="label">
          {t('analytics:totalSum')}
        </tspan>
      </text>
      <text x={cx} y={cy} textAnchor="middle" dominantBaseline="central">
        <tspan className="sum">{sumText}</tspan>
      </text>
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="central"
        dy={24}
      >
        <tspan alignmentBaseline="middle" className="label">
          {t('analytics:transactions', { count: totalTransactions })}
        </tspan>
      </text>
    </>
  );
};

const Comp = ({ data }: { data: PieProps['data'] }) => {
  return (
    <Box
      sx={{
        '& svg .label': {
          textStyle: TextStyles.Caption12Regular,
          fill: 'primary.350',
        },

        '& svg .sum': {
          textStyle: TextStyles.h2,
          fill: 'primary.500',
          paintOrder: 'stroke',
          strokeWidth: 3,
          strokeLinecap: 'butt',
          stroke: emotionColors.secondary500,
        },
      }}
    >
      <PieChart width={264} height={264}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={264 / 2}
          innerRadius={192 / 2}
          stroke={emotionColors.secondary500}
          dataKey="sum"
        >
          <Label content={RenderCenter} />
          {data?.map((entry) => (
            <Cell
              key={entry.name}
              fill={
                Object.values(config).find((x) => x.status === entry.name)
                  ?.color
              }
            />
          ))}
        </Pie>
        <Tooltip content={TooltipSum} />
      </PieChart>
    </Box>
  );
};

//content + title + padding
const SKELETON_HEIGHT = 264 + 56 + 32;

const Content = () => {
  const { data, isLoading } = usePieChartData();

  if (isLoading) {
    return <LoadingBox h={SKELETON_HEIGHT} />;
  }
  if (!data) {
    return <NoData h={SKELETON_HEIGHT} />;
  }

  const hasData = data.reduce((acc, v) => acc + (v?.transactions ?? 0), 0) > 0;

  if (hasData)
    return (
      <>
        <Comp data={data} />
        <PieChartLegend />
      </>
    );

  return <NoData h={SKELETON_HEIGHT} />;
};

export const ChartSum = () => {
  const { t } = useTranslation(['analytics']);
  return (
    <Card>
      <CardHead>
        <CardTitle>{t('analytics:sumPieChartTitle')}</CardTitle>
      </CardHead>
      <CardContent>
        <VStack spacing={2}>
          <Content />
        </VStack>
      </CardContent>
    </Card>
  );
};
