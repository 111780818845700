import { Box, Button, Link, Text } from '@chakra-ui/react';
import { InputField, PasswordInputField } from '@payler/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../hooks/user/auth';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { TextStyles } from '@payler/ui-theme';
import { Link as RouterDomLink } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';

interface FormData {
  email: string;
  password: string;
}

function createResolver(t: TFunction) {
  return yupResolver(
    yup.object().shape({
      email: yup.string().required(t('validate.required')),
      password: yup.string().required(t('validate.required')),
    }),
  );
}

export const SignInForm: React.FC = () => {
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const { t } = useTranslation(['auth']);
  const { t: tWithoutNs } = useTranslation();
  const methods = useForm<FormData>({ resolver: createResolver(tWithoutNs) });
  const { mutate, isPending: isLoading } = useLogin();
  const handleSubmit = methods.handleSubmit(({ email, password }: FormData) => {
    setError('');
    mutate({ email, password }, { onError: (e) => setError(getError(e)) });
  });

  return (
    <Box mt={{ base: 10, sm: 16 }} w="100%">
      <FormProvider {...methods}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          height="100%"
          position="relative"
        >
          <Text as="h1" textStyle={TextStyles.h1} mb={5}>
            {t('auth:signIn.header')}
          </Text>
          {/*<Box mt={1} mb={5}>*/}
          {/*  <Text textStyle={TextStyles.BodyText16Regular} color="primary.400">*/}
          {/*    {t('auth:signIn.description')}{' '}*/}
          {/*    <Link*/}
          {/*      textStyle={TextStyles.BodyText16Medium}*/}
          {/*      href="https://ru.payler.com"*/}
          {/*      colorScheme="primary"*/}
          {/*      isExternal*/}
          {/*    >*/}
          {/*      {t('auth:signIn.registerLink')}*/}
          {/*    </Link>*/}
          {/*  </Text>*/}
          {/*</Box>*/}
          <Box mb={3}>
            <InputField name="email" placeholder={t('auth:emailPlaceholder')} />
          </Box>
          <PasswordInputField
            name="password"
            placeholder={t('auth:passwordPlaceholder')}
          />
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <Box
            display="flex"
            flexDirection={{ base: 'column-reverse', sm: 'column' }}
            mt={5}
            w="100%"
            position={{ base: 'absolute', sm: 'initial' }}
            left="0"
            right="0"
            bottom={1}
          >
            <Button
              isLoading={isLoading}
              isDisabled={isLoading}
              type="submit"
              variant="primary"
              w="100%"
            >
              {t('auth:signIn.submitButton')}
            </Button>
            <Box textAlign={{ base: 'center', sm: 'left' }} mt={2} mb={2}>
              <Link
                as={RouterDomLink}
                to="/auth/resetPassword"
                colorScheme="primary"
              >
                {t('auth:signIn.forgotPassword')}
              </Link>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default SignInForm;
