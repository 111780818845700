import { TFasterPaymentWithHistory } from '../../../../../api/src';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import React from 'react';
import {
  Badge,
  ComponentStyleConfig,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { PaymentSBP24Icon } from '@payler/ui-icons';
import { getFasterPaymentBadgeVariant } from '../../tables/FasterPaymentsTable/FasterPaymentsTable';

type Props = { data: TFasterPaymentWithHistory };

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: { base: '300px', xl: '800px' },
      maxW: { base: '100%', sm: 'unset' },
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

export const FasterPaymentData = ({ data }: Props) => {
  const { t } = useTranslation(['fasterPayments', 'fields']);
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('fasterPayments:dataTitle')}</CardTitle>
        <Tooltip label={t('fasterPayments:tooltip')} shouldWrapChildren>
          <Icon as={PaymentSBP24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Tbody>
                <Tr>
                  <Td>{t('fields:status')}</Td>
                  <Td>
                    <Badge variant={getFasterPaymentBadgeVariant(data.status)}>
                      {data.statusDescription}
                    </Badge>
                  </Td>
                </Tr>
                <Tr>
                  <Td>{t('fields:operationId')}</Td>
                  <Td>{data.operationId ?? '\u2014'}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

export default FasterPaymentData;
