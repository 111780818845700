import { TOperationStatus } from '../../../../../api/src';

export const getOperationStatusBadgeVariant = (
  status?: TOperationStatus,
): string | undefined => {
  switch (status) {
    case 'Success':
      return 'green';
    case 'Reject':
      return 'red';
    case 'Pending':
    case 'Start3ds':
    case 'Finish3ds':
    case 'Start3ds2':
    case 'StartChallenge':
    case 'Finish3ds2':
    case 'ThreeDsMethodRedirect':
    case 'ThreeDsMethodStart':
    case 'Created':
      return 'yellow';
  }
  return undefined;
};
