import { TranslationKeys } from './types/translationStructure';

export const withdrawalsEn = {
  form: {
    amount: 'Amount, {{currency}}',
    cardNumber: 'Card number',
    invalidAmount: 'Enter amount',
    invalidCard: 'Enter card number',
    invalidEmail: 'Enter a valid e-mail',
    invalidPassword: 'Enter payment password',
    password: 'Payment password',
    remember: 'Remember form data',
    send: 'Send',
    success: 'Payment successful.',
    templates: 'Templates',
    noAccess: 'Not enough rights',
    expirationDate: 'Expiration date',
    downloadTemplate: 'Download template',
    selectCsvFile: 'Select csv file',
    dragAndDropFileOrClickOnField:
      'Drag and drop the file or click on the field',
  },
  formTitle: 'Withdrawal to bank card',
  listTitle: 'Previous withdrawals (1 day)',
  title: 'Withdrawals',
  single: 'Single',
  mass: 'Mass',
  modal: {
    modalTitle: 'Confirm bulk payout execution',
    modalDescription:
      'Please confirm execution of payouts from file “<darker>{{fileName}}</darker>”: contains <darker>{{rowsTotal}}</darker> operations for a total of <amount>{{amount}}</amount>',
    modalDescriptionWithValidation:
      ', of which valid <darker>{{rowsValidated}}</darker> operations for a total of <amountValidated>{{amountValidated}}</amountValidated>',
    modalDescriptionEnd:
      '. Please note that after confirmation it will be impossible to stop the execution.',
    okModalButton: 'Accept',
    cancelModalButton: 'Cancel',
    bulkPayoutTransitionStarted: 'Bulk transaction processing started',
  },
  bulkPayoutError: {
    defaultError: 'Technical problem, please try again later',
    contractNotFound: 'Contract not found',
    invalidCredentials: 'The merchant is not authenticated',
    bulkPayoutsNotEnabled: 'Bulk payouts are not enabled for the contract',
    unexpectedError: 'Unexpected error. Please contact our support team.',
    wrongFileFormat: 'The file has wrong format',
    wrongBulkPayoutStatus:
      'The mass payment instance is in an unacceptable status',
    loadedDataExpired: 'The storage of the bulk payment data file has expired',
    fileIsEmpty: 'The file should not be empty',
    moreThenOneCurrency:
      'All records in the file must contain only one currency',
    maxRecordQuantityIsExceeded: 'Too many rows in file',
    encodeKeyNotSet: 'Failed to retrieve the encryption key',
    maxTotalAmountIsExceeded: 'Max total amount is exceeded',
    wrongCharactersInFileName: 'The file name contains forbidden characters',
    noValidRecordsToProcess:
      'The file does not contain any records that can be processed',
    maxSimultaneouslyProcessingFilesNumberIsExceeded:
      'The number of files being processed at the same time has been exceeded, uploading is not possible',
    notFound: 'Requested resource not found',
  },
  initialAmount: 'Total amount',
  actualAmount: 'Success amount',
  status: 'Status',
  success: 'Success',
  bulkPaymentId: 'Bulk payment ID',
  bulkPayout: 'Bulk payout',
} as const;

export const withdrawalsRu: TranslationKeys<typeof withdrawalsEn> = {
  form: {
    amount: 'Сумма, {{currency}}',
    cardNumber: 'Номер карты',
    invalidAmount: 'Введите сумму',
    invalidCard: 'Укажите номер карты',
    invalidEmail: 'Введите корректный email',
    invalidPassword: 'Укажите платёжный пароль',
    password: 'Платёжный пароль',
    remember: 'Запомнить данные формы',
    send: 'Отправить',
    success: 'Выплата проведена успешно.',
    templates: 'Шаблоны',
    noAccess: 'Недостаточно прав',
    expirationDate: 'Срок действия',
    downloadTemplate: 'Скачать шаблон',
    selectCsvFile: 'Выберите csv файл',
    dragAndDropFileOrClickOnField: 'Перетащите файл или нажмите на поле',
  },
  formTitle: 'Выплаты на карту',
  listTitle: 'Предыдущие выплаты (1 день)',
  title: 'Сделать выплату',
  single: 'Единичная',
  mass: 'Массовая',
  modal: {
    modalTitle: 'Подтверждение запуска выплат',
    modalDescription:
      'Подтвердите запуск выплат из файла “<darker>{{fileName}}</darker>”: всего <darker>{{rowsTotal}}</darker> операций на общую сумму <amount>{{amount}}</amount>',
    modalDescriptionWithValidation:
      ', из них валидных <darker>{{rowsValidated}}</darker> операций на общую сумму <amountValidated>{{amountValidated}}</amountValidated>',
    modalDescriptionEnd:
      '. Обратите внимание, что после подтверждения остановка выплат станет невозможной.',
    okModalButton: 'Подтвердить',
    cancelModalButton: 'Отмена',
    bulkPayoutTransitionStarted: 'Начата обработка массовой операции',
  },
  bulkPayoutError: {
    defaultError: 'Техническая проблема, попробуйте позже',
    contractNotFound: 'Контракт не найден',
    invalidCredentials: 'Неверные параметры аутентификации',
    bulkPayoutsNotEnabled:
      'Не подключена возможность проведения массовых выплат',
    unexpectedError:
      'Непредвиденная ошибка. Обратитесь в службу сопровождения.',
    wrongFileFormat: 'Файл имеет неверный формат',
    wrongBulkPayoutStatus: 'Неподходящий статус операции массовой выплаты',
    loadedDataExpired:
      'Истекло время на старт обработки файла массовой выплаты',
    fileIsEmpty: 'Файл не должен быть пустым',
    moreThenOneCurrency: 'Все выплаты должны быть в одной валюте',
    maxRecordQuantityIsExceeded:
      'Превышено предельное количество строк в файле',
    encodeKeyNotSet: 'Ключ шифрования не удалось получить',
    maxTotalAmountIsExceeded: 'Превышена максимальная общая сумма выплат',
    wrongCharactersInFileName: 'Имя файла содержит запрещённые символы',
    noValidRecordsToProcess: 'Файл не содержит ни одной записи, возможной для обработки',
    maxSimultaneouslyProcessingFilesNumberIsExceeded: 'Количество одновременно обрабатываемых файлов превышено, загрузка невозможна',
    notFound: 'Запрошенный объект не найден'
  },
  initialAmount: 'Сумма исходная',
  actualAmount: 'Сумма фактическая',
  status: 'Статус',
  success: 'Успешно',
  bulkPaymentId: 'ID массовой выплаты',
  bulkPayout: 'Массовая выплата',
} as const;
