import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../layouts';
import React from 'react';
import ChartSumByStatus from '../components/charts/ChartSumByStatus/ChartSumByStatus';
import ChartFilters from '../components/charts/filters/ChartFilters';
import { ChartSum } from '../components/charts/ChartSum/ChartSum';
import styled from '@emotion/styled';
import { ChartTransactions } from '../components/charts/ChartTransactions/ChartTransactions';
import ChartAverageCharge from '../components/charts/ChartAverageCharge/ChartAverageCharge';
import { breakpoints } from '@payler/ui-theme';
import { useContracts } from '../hooks/contracts';
import { Helmet } from 'react-helmet-async';
import { AnalyticsPageContextProvider } from '../context/AnalyticsPageContext';

const Layout = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-areas: 'main' 'pie-sum' 'pie-count' 'avg-charge';
  grid-template-columns: minmax(200px, 1fr);
  @media (min-width: ${breakpoints.md}) {
    grid-template-areas: 'main main' 'pie-sum pie-count' 'avg-charge avg-charge';
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
  @media (min-width: ${breakpoints.xl}) {
    grid-template-areas: 'main main main' 'pie-sum pie-count avg-charge';
    grid-template-columns: repeat(3, minmax(285px, 1fr));
  }
`;

export const AnalyticsPage = () => {
  const contracts = useContracts();
  const { t } = useTranslation(['titles']);
  if (contracts === undefined) return null;
  return (
    <AnalyticsPageContextProvider>
      <Helmet title={t('titles:analytics')} />
      <AnalyticsPageHeader />
      <Layout>
        <FullWidthBlock gridArea="main">
          <ChartSumByStatus />
        </FullWidthBlock>
        <FullWidthBlock gridArea="pie-sum">
          <ChartSum />
        </FullWidthBlock>
        <FullWidthBlock gridArea="pie-count">
          <ChartTransactions />
        </FullWidthBlock>
        <FullWidthBlock gridArea="avg-charge">
          <ChartAverageCharge />
        </FullWidthBlock>
      </Layout>
    </AnalyticsPageContextProvider>
  );
};

const AnalyticsPageHeader = () => {
  const filtersVisible = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation(['analytics', 'common']);
  return (
    <Stack
      alignItems={{ base: 'flex-start', lg: 'flex-end' }}
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
      direction={{
        base: 'column',
        lg: 'row',
      }}
      spacing={{ base: 2, md: 3, lg: 2 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/">
              {t('analytics:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle mb={0}>{t('analytics:title')}</PageTitle>
      </div>
      {filtersVisible && <ChartFilters />}
    </Stack>
  );
};

export default AnalyticsPage;
