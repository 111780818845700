import { TranslationKeys } from './types/translationStructure';

export const fasterPaymentsEn = {
  previousPayments: 'Previous payments',
  title: 'Payments FPS',
  status: {
    Created: 'Created',
    Pending: 'Pending',
    Completed: 'Completed',
    Failed: 'Failed',
    Returned: 'Returned',
  },
  dataTitle: 'Payment details',
  name: 'Name',
  phone: 'Phone',
  bankName: 'Bank',
  orderId: 'Payout ID',
  processingName: 'Processing',
  history: 'Operations history',
  requisites: 'Requisites',
  tooltip: 'Faster payment',
  spbOrderId: 'ID платежа в НСПК',
} as const;

export const fasterPaymentsRu: TranslationKeys<typeof fasterPaymentsEn> = {
  previousPayments: 'Предыдущие выплаты',
  title: 'Платежи СБП',
  status: {
    Completed: 'Успешный',
    Failed: 'Неуспешный',
    Returned: 'Возвращен',
    Created: 'Создан',
    Pending: 'В обработке',
  },
  name: 'ФИО',
  dataTitle: 'Данные платежа',
  bankName: 'Банк',
  phone: 'Телефон',
  history: 'История операций',
  orderId: 'ID выплаты',
  processingName: 'Процессинг',
  requisites: 'Реквизиты',
  tooltip: 'Платёж СБП',
  spbOrderId: 'ID платежа в НСПК',
} as const;
