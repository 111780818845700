import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';
import { usePaymentAnalyticsQuery } from '../../../hooks/analytics';
import { useMemo } from 'react';
import { TAnalyticsDataResponse } from '../../../../../../api/src';

/**
 * данные для бубликов (ChartSum и ChartTransactions)
 */
export const usePieChartData = () => {
  const { params } = useAnalyticsPageContext();
  const { data: response, isLoading } = usePaymentAnalyticsQuery(params);

  return useMemo(() => {
    return {
      data: responseToData(response),
      isLoading,
    };
  }, [response, isLoading]);
};

function responseToData(response: TAnalyticsDataResponse | undefined) {
  const tmp = response?.dailyPaymentsAnalytics?.reduce(
    (acc, current) => {
      acc.chargedCount += current.charged.totalCount;
      acc.creditedCount += current.credited.totalCount;
      acc.authorizedCount += current.authorized.totalCount;
      acc.refundedCount += current.refunded.totalCount;
      acc.rejectedOnlyDebitCount += current.rejectedOnlyDebit.totalCount;
      acc.chargedSum += current.charged.totalSum;
      acc.creditedSum += current.credited.totalSum;
      acc.authorizedSum += current.authorized.totalSum;
      acc.refundedSum += current.refunded.totalSum;
      acc.rejectedOnlyDebitSum += current.rejectedOnlyDebit.totalSum;
      return acc;
    },
    {
      totalCount: 0,
      totalSum: 0,
      authorizedCount: 0,
      chargedCount: 0,
      refundedCount: 0,
      rejectedOnlyDebitCount: 0,
      creditedCount: 0,
      authorizedSum: 0,
      chargedSum: 0,
      refundedSum: 0,
      rejectedOnlyDebitSum: 0,
      creditedSum: 0,
    },
  );
  if (tmp) {
    tmp.totalCount =
      tmp.chargedCount +
      tmp.creditedCount +
      tmp.authorizedCount +
      tmp.rejectedOnlyDebitCount +
      tmp.refundedCount;
    tmp.totalSum =
      tmp.chargedSum +
      tmp.creditedSum +
      tmp.authorizedSum +
      tmp.rejectedOnlyDebitSum +
      tmp.refundedSum;
  }

  return [
    {
      name: 'Authorized',
      transactions: tmp?.authorizedCount,
      sum: tmp?.authorizedSum,
      totalTransactions: tmp?.totalCount,
      totalSum: tmp?.totalSum,
    },
    {
      name: 'Charged',
      transactions: tmp?.chargedCount,
      sum: tmp?.chargedSum,
      totalTransactions: tmp?.totalCount,
      totalSum: tmp?.totalSum,
    },
    {
      name: 'Refunded',
      transactions: tmp?.refundedCount,
      sum: tmp?.refundedSum,
      totalTransactions: tmp?.totalCount,
      totalSum: tmp?.totalSum,
    },
    {
      name: 'RejectedOnlyDebit',
      transactions: tmp?.rejectedOnlyDebitCount,
      sum: tmp?.rejectedOnlyDebitSum,
      totalTransactions: tmp?.totalCount,
      totalSum: tmp?.totalSum,
    },
    {
      name: 'Credited',
      transactions: tmp?.creditedCount,
      sum: tmp?.creditedSum,
      totalTransactions: tmp?.totalCount,
      totalSum: tmp?.totalSum,
    },
  ];
}
