import { TTransactionStatus } from '../../../../../api/src';
import { emotionColors } from '@payler/ui-theme';

export const config: {
  [key: string]: { status: TTransactionStatus; color: string };
} = {
  authorized: {
    status: 'Authorized',
    color: emotionColors.yellow500,
  },
  refunded: {
    status: 'Refunded',
    color: '#4AE689',
  },
  credited: {
    status: 'Credited',
    color: '#7BCAAA',
  },
  charged: {
    status: 'Charged',
    color: '#22C55E',
  },
  rejectedOnlyDebit: {
    status: 'RejectedOnlyDebit',
    color: emotionColors.red500,
  },
};
