import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { TextStyles } from '@payler/ui-theme';

type TBreadcrumbBackButtonProps = {
  to: string;
};
export const BreadcrumbBackButton: FC<TBreadcrumbBackButtonProps> = ({
  to,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <VStack>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={to}>
          <Button
            variant="link"
            color="primary.350"
            textStyle={TextStyles.Caption12Regular}
          >
            ←&nbsp;&nbsp;{t('common:back')}
          </Button>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </VStack>
  );
};
