import { MayBe, TCurrency } from '../../../../../api/src';
import NumberFormat from 'react-number-format';
import React from 'react';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';

type Props = {
  /**
   * копейки
   */
  value?: MayBe<number>;
  currency?: MayBe<TCurrency>;
  showCurrency?: boolean;
};
export const CurrencyDisplay = ({
  value,
  currency,
  showCurrency = true,
}: Props) => {
  const { getCurrencyDecimalsCount, getCurrencyUnitRate } =
    useCurrencyCallbacks();
  const { getCurrencySymbol } = useCurrencyCallbacks();

  const currencySuffix = currency ? getCurrencySymbol(currency) : undefined;
  const resValue = (value ?? 0) / getCurrencyUnitRate(currency);
  const decimalsCount = getCurrencyDecimalsCount(currency);
  return (
    <NumberFormat
      displayType="text"
      suffix={currencySuffix && showCurrency ? `\u00a0${currencySuffix}` : ''}
      decimalScale={decimalsCount}
      fixedDecimalScale
      thousandSeparator={'\u00a0'}
      value={resValue}
    />
  );
};
