import { Button, HStack, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { createRecoilKey } from '../helpers/createKey';
import { NotificationWithIcon, PaylerModal } from '@payler/ui-components';
import { DangerBigIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { useDeleteReportMutation } from '../hooks/reports/misc';

const deleteReportModalIdAtom = atom<number | null>({
  key: createRecoilKey('deleteReportModalIdAtom'),
  default: null,
});

const useCloseDeleteReportModal = () =>
  useRecoilCallback(({ set }) => () => {
    set(deleteReportModalIdAtom, null);
  });

export const useOpenDeleteReportModal = () =>
  useSetRecoilState(deleteReportModalIdAtom);

export const DeleteReportModal = () => {
  const { t } = useTranslation(['reports']);
  const id = useRecoilValue(deleteReportModalIdAtom);
  const isOpen = !!id;
  const close = useCloseDeleteReportModal();
  const deleteReportMutation = useDeleteReportMutation();
  const handleDelete = useCallback(() => {
    if (!id) return;
    close();
    deleteReportMutation.mutate(id);
  }, [close, deleteReportMutation, id]);

  return (
    <PaylerModal close={close} open={isOpen}>
      <VStack spacing={4} w="100%">
        <NotificationWithIcon
          icon={DangerBigIcon}
          title={t('reports:deleteReportModal.title')}
          text={t('reports:deleteReportModal.description')}
        />
      </VStack>
      <HStack spacing={2} justifyContent="stretch">
        <Button flex={1} variant="secondary" onClick={close}>
          {t('reports:deleteReportModal.cancel')}
        </Button>
        <Button flex={1} variant="primary" onClick={handleDelete}>
          {t('reports:deleteReportModal.delete')}
        </Button>
      </HStack>
    </PaylerModal>
  );
};
