import { TCryptoPaymentStatus } from '../../../../../api/src';

export function getSessionStatusBadgeVariant(status: TCryptoPaymentStatus) {
  switch (status) {
    case 'Created':
    case 'Pending':
      return 'grey';
    case 'Authorized':
      return 'green';
    case 'Rejected':
    case 'ProviderTechError':
      return 'red';
    default:
      return undefined;
  }
}
