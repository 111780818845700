import React, { FC } from 'react';
import { PaylerModal } from '@payler/ui-components';
import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { createRecoilKey } from '../helpers/createKey';
import { TBulkPayout } from '../../../../api/src';
import {
  Box,
  Button,
  HStack,
  Icon,
  Text,
  useModalContext,
  VStack,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { WarningBigIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import {
  useBulkPayoutActionsMutation,
  useResetBulkPayoutsQuery,
} from '../hooks/payouts/queries';
import { useBulkPayoutErrorContext } from '../context/BulkPayoutErrorContext';
import ReactNumberFormat from 'react-number-format';
import { useToasts } from '../hooks/use-toasts';

type TBulkPayoutConfirmationModalOpenedAtom = TBulkPayout & {
  fileName: string;
  bulkPayoutId: string;
  password: string;
};

const bulkPayoutConfirmationModalOpenedAtom =
  atom<TBulkPayoutConfirmationModalOpenedAtom | null>({
    key: createRecoilKey('bulkPayoutConfirmationModalOpenedAtom'),
    default: null,
  });

export const useShowBulkPayoutConfirmationModal = () =>
  useRecoilCallback(
    ({ set }) =>
      (data: TBulkPayoutConfirmationModalOpenedAtom) => {
        set(bulkPayoutConfirmationModalOpenedAtom, data);
      },
    [],
  );

export const useCloseBulkPayoutConfirmationModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(bulkPayoutConfirmationModalOpenedAtom, null);
      },
    [],
  );

export const BulkPayoutConfirmationModal: FC<{
  onClose: () => void;
  onConfirm: () => void;
}> = ({ onClose, onConfirm }) => {
  const data = useRecoilValue(bulkPayoutConfirmationModalOpenedAtom);
  const close = useCloseBulkPayoutConfirmationModal();

  return (
    <PaylerModal
      close={() => {
        close();
        onClose();
      }}
      open={!!data}
    >
      <ModalContent onConfirm={onConfirm} />
    </PaylerModal>
  );
};

const ModalContent: FC<{
  onConfirm: () => void;
}> = ({ onConfirm }) => {
  const data = useRecoilValue(bulkPayoutConfirmationModalOpenedAtom);
  const { t } = useTranslation([]);
  const { getCurrencySymbol, getCurrencyUnitRate } = useCurrencyCallbacks();
  const { cancelMutation, startMutation } = useBulkPayoutActionsMutation();
  const { setBulkPayoutError } = useBulkPayoutErrorContext();
  const resetBulkPayouts = useResetBulkPayoutsQuery();
  const { onClose: closeModal } = useModalContext();
  const toast = useToasts();
  if (!data) return null;

  const amount = `${data.amountTotal ?? 0 / getCurrencyUnitRate(data.currency)} ${getCurrencySymbol(data.currency)}`;
  const amountValidated = `${data.amountValidated ?? 0 / getCurrencyUnitRate(data.currency)} ${getCurrencySymbol(data.currency)}`;

  const handleCancelButtonClick = () => {
    cancelMutation.mutate(data.bulkPayoutId, {
      onSuccess: () => {
        resetBulkPayouts();
      },
      onError: (err) => {
        setBulkPayoutError(err);
      },
      onSettled: closeModal,
    });
  };
  const handleAcceptButtonClick = () => {
    startMutation.mutate(
      { password: data.password, bulkPayoutId: data.bulkPayoutId },
      {
        onSuccess: () => {
          resetBulkPayouts();
          onConfirm();
          toast.success(t('withdrawals:modal.bulkPayoutTransitionStarted'))
        },
        onError: (err) => {
          setBulkPayoutError(err);
        },
        onSettled: closeModal,
      },
    );
  };

  return (
    <VStack>
      <VStack w="full" justifyContent="center">
        <Icon as={WarningBigIcon} w={8} h={8} />
        <Text as="h1" textStyle={TextStyles.h1} w="full" textAlign="center">
          {t('withdrawals:modal.modalTitle')}
        </Text>
      </VStack>
      <Box textAlign="center">
        <Trans
          i18nKey={'withdrawals:modal.modalDescription'}
          ns={['withdrawals']}
          values={{
            fileName: data.fileName,
            rowsTotal: data.rowsTotal,
            amount,
            currency: data.currency,
          }}
          components={{
            darker: (
              <Text
                as="span"
                color="primary.500"
                textStyle={TextStyles.BodyText16Medium}
              />
            ),
            amount: (
              <Amount
                amount={data.amountTotal ?? 0}
                currency={data.currency ?? ''}
              />
            ),
          }}
        />

        {data.rowsValidated &&
          data.amountValidated &&
          (data.rowsValidated !== data.rowsTotal ||
            data.amountValidated !== data.amountTotal) && (
            <Trans
              i18nKey="withdrawals:modal.modalDescriptionWithValidation"
              ns={['withdrawals']}
              values={{
                rowsValidated: data.rowsValidated,
                amountValidated,
                currency: data.currency,
              }}
              components={{
                darker: (
                  <Text
                    as="span"
                    color="primary.500"
                    textStyle={TextStyles.BodyText16Medium}
                  />
                ),
                amountValidated: (
                  <Amount
                    amount={data.amountValidated}
                    currency={data.currency ?? ''}
                  />
                ),
              }}
            />
          )}

        <span>{t('withdrawals:modal.modalDescriptionEnd')}</span>
      </Box>
      <HStack w="full" justifyContent="space-between" mt={5}>
        <Button
          w="full"
          variant="secondary"
          isLoading={cancelMutation.status === 'pending'}
          onClick={handleCancelButtonClick}
        >
          {t('withdrawals:modal.cancelModalButton')}
        </Button>
        <Button
          w="full"
          isLoading={startMutation.status === 'pending'}
          onClick={handleAcceptButtonClick}
        >
          {t('withdrawals:modal.okModalButton')}
        </Button>
      </HStack>
    </VStack>
  );
};

const Amount: FCC<{ amount: number; currency: string }> = ({
  amount,
  currency,
}) => {
  const { getCurrencySymbol, getCurrencyUnitRate, getCurrencyDecimalsCount } =
    useCurrencyCallbacks();

  return (
    <Text as="span" color="primary.500" textStyle={TextStyles.BodyText16Medium}>
      <ReactNumberFormat
        suffix={`\u00a0${getCurrencySymbol(currency)}`}
        value={amount / getCurrencyUnitRate(currency)}
        displayType="text"
        isNumericString={true}
        decimalSeparator={'.'}
        decimalScale={getCurrencyDecimalsCount(currency)}
        allowLeadingZeros={true}
        thousandSeparator={' '}
        fixedDecimalScale={true}
      />
    </Text>
  );
};
