import { TranslationKeys } from './types/translationStructure';

export const authEn = {
  signIn: {
    header: 'Sign In',
    description: 'Don’t have an account?',
    registerLink: 'Fill in the application',
    submitButton: 'Log in',
    forgotPassword: 'Forgot Password?',
  },
  resetPassword: {
    header: 'Reset password',
    description:
      'Enter your email and we’ll send you a link to reset your password',
    submitButton: 'Continue',
  },
  resetPasswordSuccess: {
    header: 'Confirmation email sent',
    description: 'We have sent the information to your email',
    submitButton: 'Back',
  },
  setNewPassword: {
    header: 'Set new password',
    description:
      'It must contain at least 8 characters, both uppercase and lowercase letters, numbers, and at least one special character, e.g., ! @ # ? ]',
    submitButton: 'Confirm password',
    newPassword: 'New password',
    confirmPassword: 'Confirmation',
  },
  setNewPasswordSuccess: {
    header: 'Password has been set',
    description: 'Log in to continue',
    submitButton: 'Back',
  },
  emailPlaceholder: 'Email or phone number',
  passwordPlaceholder: 'Password',
  imageTitle: 'Hello! It’s great seeing you here!',
  imageSubtitle:
    'Grow your business with the help of our integrated banking and payments platform',
} as const;

export const authRu: TranslationKeys<typeof authEn> = {
  signIn: {
    header: 'Войти',
    description: 'У вас ещё нет аккаунта?',
    registerLink: 'Заполните заявку',
    submitButton: 'Войти',
    forgotPassword: 'Забыли пароль?',
  },
  resetPassword: {
    header: 'Сбросить пароль',
    description:
      'Введите свой email, и мы отправим вам ссылку для сброса пароля',
    submitButton: 'Продолжить',
  },
  resetPasswordSuccess: {
    header: 'Письмо с подтверждением отправлено',
    description: 'Мы отправили информацию на ваш email',
    submitButton: 'Назад',
  },
  setNewPassword: {
    header: 'Создайте новый пароль',
    description:
      'Он должен содержать не менее восьми символов, строчные и прописные буквы, цифры и как минимум один специальный символ, например ! @ # ? ]',
    submitButton: 'Подтвердить пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Подтверждение',
  },
  setNewPasswordSuccess: {
    header: 'Пароль изменен',
    description: 'Авторизируйтесь, чтобы продолжить',
    submitButton: 'Авторизация',
  },
  emailPlaceholder: 'Email или номер телефона',
  passwordPlaceholder: 'Пароль',
  imageTitle: 'Здравствуйте! Добро пожаловать!',
  imageSubtitle:
    'Развивайте свой бизнес с помощью наших передовых  платежных решений для бизнеса',
} as const;
