import {
  useBulkPayoutsQuery,
  useBulkPayoutsQueryPagerProps,
  useDownloadBulkPayoutReport,
} from '../../hooks/payouts/queries';
import {
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import React, { FC } from 'react';
import { TableFooter } from '../components/TableFooter';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { tableStyleConfig } from '../tableStyleConfig';
import TableSkeleton from '../../components/TableSkeleton/TableSkeleton';
import { useTranslation } from 'react-i18next';
import { MayBe, TBulkPayout, TCurrency } from '../../../../../api/src';
import { PaymentDatesCell } from './ReportDateCell';
import { TwoLevelTextTableCell } from '../TwoLevelTextTableCell';
import { DownloadIcon } from '@payler/ui-icons';
import { AmountWithCurrencyCell } from './AmountWithCurrencyCell';
import { Status } from './Status';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from '../../hooks/use-toasts';

export const BulkPayoutsTable = () => {
  const props = useBulkPayoutsQueryPagerProps();
  return (
    <>
      <TableWithData />
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const TableWithData = () => {
  const { status, data } = useBulkPayoutsQuery();
  const toasts = useToasts();

  switch (status) {
    case 'pending':
      return <TableSkeleton />;
    case 'error':
      toasts.error();
      return <NoData />;
    case 'success':
    default: {
      const empty = data.items.length === 0;
      if (empty) {
        return <NoData />;
      }

      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {data.items.map((item) => (
                  <Row key={item.id} {...item} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Row: FC<TBulkPayout> = ({
  amountSucceeded,
  amountTotal,
  createdAt,
  currency,
  id,
  rowsFailed,
  rowsSucceeded,
  status,
}) => {
  const { contractId } = useParams();
  const navigate = useNavigate();
  return (
    <Tr onClick={() => navigate(`/${contractId}/makebulkpayout/${id}`)}>
      <Td>
        <PaymentDatesCell topDate={createdAt} />
      </Td>
      <Td>{id}</Td>
      <Td>
        <Status status={status} />
      </Td>
      <Td>
        <SucceededAndRejectedCell
          succeed={rowsSucceeded}
          rejected={rowsFailed}
        />
      </Td>
      <Td>
        <TwoLevelTextTableCell
          top={<FormattedAmount currency={currency} amount={amountSucceeded} />}
          bottom={<FormattedAmount currency={currency} amount={amountTotal} />}
        />
      </Td>
      <Td>
        <DownloadReportButton id={id} />
      </Td>
    </Tr>
  );
};

const FormattedAmount: FC<{
  amount: MayBe<number>;
  currency: MayBe<TCurrency>;
}> = ({ amount, currency }) => {
  if (!amount || !currency) {
    return '-';
  }

  return <AmountWithCurrencyCell amount={amount} currencyCode={currency} />;
};

const DownloadReportButton: FC<{ id: string }> = ({ id }) => {
  const download = useDownloadBulkPayoutReport(id);

  return (
    <Flex w="full" justifyContent="center">
      <Icon
        as={DownloadIcon}
        w="18px"
        h="18px"
        color="primary.300"
        onClick={download}
      />
    </Flex>
  );
};

const SucceededAndRejectedCell: FC<{
  succeed: MayBe<number>;
  rejected: MayBe<number>;
}> = ({ rejected, succeed }) => {
  return (
    <>
      <Text as="span" color="green.500">
        {succeed ?? '-'}
      </Text>
      <Text as="span" color="primary.300">
        &nbsp;/&nbsp;
      </Text>
      <Text as="span" color="red.500">
        {rejected ?? '-'}
      </Text>
    </>
  );
};

const Header = () => {
  const { t } = useTranslation(['tableHeader']);
  return (
    <Thead>
      <Tr>
        <Th>{t('tableHeader:period')}</Th>
        <Th>{t('tableHeader:withdrawalId')}</Th>
        <Th>{t('tableHeader:status')}</Th>
        <Th>{t('tableHeader:succeededAndRejected')}</Th>
        <Th>{t('tableHeader:successAndTotalAmount')}</Th>
      </Tr>
    </Thead>
  );
};
